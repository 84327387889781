import React from 'react';
import { gql } from 'graphql-tag';
import { useQuery } from 'react-apollo';
import cookies from 'js-cookie';
import client from '../apollo';
import { ID } from '@cinuru/utils/types';

export const PRIVILEGES_QUERY = gql`
	query Privileges {
		user: currentUser {
			id
			name
			email
			privileges {
				accessRightDashboard
				accessRightFilmStatistics
				accessRightBonusProgram
				accessRightCampaigns
				adminForCinemas {
					id
					name
					city
				}
				adminForBonusPrograms {
					id
				}
				adminRole
				rootRole
				belongsToB2BCustomers {
					id
					name
				}
			}
		}
	}
`;

export type Privileges = {
	accessRightDashboard;
	accessRightFilmStatistics?: boolean;
	accessRightBonusProgram?: boolean;
	accessRightCampaigns?: boolean;
	adminForCinemas: {
		id: ID;
		name: string;
		city: string;
	}[];
	adminForBonusPrograms: {
		id: ID;
	};
	adminRole?: boolean;
	rootRole?: boolean;
	belongsToB2BCustomers?: {
		id: ID;
		name: string;
	}[];
};

export const useUserPrivileges = (): undefined | Privileges | null => {
	const { data, loading } = useQuery(PRIVILEGES_QUERY);
	return React.useMemo(() => (loading ? undefined : data?.user?.privileges || null), [
		data?.user?.privileges,
		loading,
	]);
};

export const logout = async () => {
	await client.mutate({
		mutation: gql`
			mutation Logout {
				logout {
					success
				}
			}
		`,
	});
	client.stop();
	client.resetStore();
	localStorage.removeItem('registeredWithBrand');
	cookies.remove('_csrf');
	window.location.href = '/';
};
