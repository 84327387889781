import * as React from 'react';
import { BoxProps } from '@mui/material/Box';
import styled from 'styled-components';
import { FormLabel, Box, Card } from '@mui/material';

const StyledCard = styled(Card)`
	border: 1px solid ${(p) => p.theme.customColors.grey};
`;
interface ExtendedBoxProps extends BoxProps {
	label?: string;
}

const SectionWrapper2 = ({ label, m, children, ...rest }: ExtendedBoxProps): JSX.Element => {
	return (
		<Box m={m}>
			<StyledCard variant="outlined">
				<Box {...rest}>
					{label ? (
						<Box m="0 0 3rem">
							<FormLabel>{label}</FormLabel>
						</Box>
					) : null}
					{children}
				</Box>
			</StyledCard>
		</Box>
	);
};

export default SectionWrapper2;
