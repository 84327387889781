import * as React from 'react';
import { Grid, Box } from '@mui/material';
import { BoxProps as MuiBoxProps } from '@mui/material/Box';
import { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import TimeUnitSelectField from './TimeUnitSelectField';
import type { TimeUnit as _TimeUnit, TimeUnitSelectFieldRef } from './TimeUnitSelectField';
import IntegerSelectField from './IntegerSelectField';
import type { IntegerSelectFieldRef } from './IntegerSelectField';
import { scrollIntoView } from '../utils/dimensions';

export type TimePeriodSelectFormSimpleRef = {
	validate: () => boolean;
	scrollIntoView: () => void;
};

export type TimeUnit = _TimeUnit;

export type TimeUnitFactorWithTimeUnit = `${number | ''}:${TimeUnit | ''}`;
interface TimePeriodSelectFormProps extends Omit<MuiBoxProps, 'onChange'> {
	variant?: MuiTextFieldProps['variant'];
	defaultTimeUnit?: TimeUnit;
	onChangeTimeUnit?: (value?: TimeUnit) => void;
	defaultTimeUnitFactor?: number;
	onChangeTimeUnitFactor?: (value?: number) => void;
	disabled?: boolean;
	enabledTimeUnits?: TimeUnit[];
	defaultValue?: TimeUnitFactorWithTimeUnit;
	onChangeCombined?: (combined: TimeUnitFactorWithTimeUnit) => void;
	minInteger?: number;
	maxInteger?: number;
}

const TimePeriodSelectForm = React.forwardRef<
	TimePeriodSelectFormSimpleRef,
	TimePeriodSelectFormProps
>(
	(
		{
			variant,
			defaultTimeUnit,
			onChangeTimeUnit,
			defaultTimeUnitFactor,
			onChangeTimeUnitFactor,
			disabled,
			enabledTimeUnits,
			defaultValue,
			onChangeCombined,
			minInteger,
			maxInteger,
			...rest
		},
		ref
	): JSX.Element => {
		// this part will only be used in case defaultValue and onChange are set
		const [timeUnitFactor, setTimeUnitFactor] = React.useState<number | undefined>(
			defaultValue && Number(defaultValue.split(':')[0] || 0)
		);
		const [timeUnit, setTimeUnit] = React.useState<TimeUnit | undefined>(
			defaultValue?.split(':')[1] ? (defaultValue?.split(':')[1] as TimeUnit) : undefined
		);

		const handleChangeTimeUnitFactor = React.useCallback(
			(newTimeUnitFactor: number | undefined) => {
				setTimeUnitFactor(newTimeUnitFactor);
				onChangeTimeUnitFactor?.(newTimeUnitFactor);
				const combined: TimeUnitFactorWithTimeUnit = `${newTimeUnitFactor || ''}:${timeUnit || ''}`;
				onChangeCombined?.(combined);
			},
			[onChangeTimeUnitFactor, timeUnit, onChangeCombined]
		);
		const handleChangeTimeUnit = React.useCallback(
			(newTimeUnit: TimeUnit | undefined) => {
				setTimeUnit(newTimeUnit);
				onChangeTimeUnit?.(newTimeUnit);
				const combined: TimeUnitFactorWithTimeUnit = `${timeUnitFactor || ''}:${newTimeUnit || ''}`;
				onChangeCombined?.(combined);
			},
			[onChangeTimeUnit, timeUnitFactor, onChangeCombined]
		);

		// validation
		const wrapperRef = React.useRef<HTMLDivElement>(null);
		const integerSelectFieldRef = React.useRef<IntegerSelectFieldRef>(null);
		const timeUnitSelectFieldRef = React.useRef<TimeUnitSelectFieldRef>(null);

		const handleValidate = React.useCallback(() => {
			const invalid = [integerSelectFieldRef, timeUnitSelectFieldRef]
				.map((r) => r.current?.validate())
				.some(Boolean);
			return invalid;
		}, []);

		React.useImperativeHandle(
			ref,
			() => ({
				validate: handleValidate,
				scrollIntoView: () => scrollIntoView(wrapperRef),
			}),
			[handleValidate]
		);

		return (
			<Box {...rest} ref={wrapperRef}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
						<IntegerSelectField
							label="Anzahl"
							defaultValue={onChangeCombined ? timeUnitFactor : defaultTimeUnitFactor}
							onChange={onChangeCombined ? handleChangeTimeUnitFactor : onChangeTimeUnitFactor}
							variant={variant}
							ref={integerSelectFieldRef}
							disabled={disabled}
							min={minInteger}
							max={maxInteger}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TimeUnitSelectField
							onChange={onChangeCombined ? handleChangeTimeUnit : onChangeTimeUnit}
							defaultValue={onChangeCombined ? timeUnit : defaultTimeUnit}
							variant={variant}
							ref={timeUnitSelectFieldRef}
							disabled={disabled}
							enabledTimeUnits={enabledTimeUnits}
						/>
					</Grid>
				</Grid>
			</Box>
		);
	}
);

export default TimePeriodSelectForm;
