import * as React from 'react';
import styled from 'styled-components';
import { Box, Typography, ButtonGroup } from '@mui/material';

import Button from '../../components/Button';
import Txt from '../../components/Txt';
import IntegerTextField, { IntegerTextFieldRef } from '../../components/IntegerTextField';
import SectionWrapper2 from '../../components/SectionWrapper2';
import TimePeriodSelectForm, {
	TimeUnitFactorWithTimeUnit,
	TimeUnit,
} from '../../components/TimePeriodSelectForm';

import CakeRoundedIcon from '@mui/icons-material/CakeRounded';
import ConfirmationNumberRoundedIcon from '@mui/icons-material/ConfirmationNumberRounded';
import LoyaltyRoundedIcon from '@mui/icons-material/LoyaltyRounded';
import AddAlertRoundedIcon from '@mui/icons-material/AddAlertRounded';
import EmojiPeopleRoundedIcon from '@mui/icons-material/EmojiPeopleRounded';
import ControlPointDuplicateRoundedIcon from '@mui/icons-material/ControlPointDuplicateRounded';
import DateRangeRoundedIcon from '@mui/icons-material/DateRangeRounded';
import FastfoodRoundedIcon from '@mui/icons-material/FastfoodRounded';
import DraftsRoundedIcon from '@mui/icons-material/DraftsRounded';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import PhoneIphoneRoundedIcon from '@mui/icons-material/PhoneIphoneRounded';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import { TimePeriodEventSelectFormRef } from 'dashboard/src/components/TimePeriodEventSelectForm';

const Column = styled(Box)`
	height: 14rem;
	width: 14rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;
const Row = styled(Box)`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
`;

export type Trigger =
	| 'SINGLE_EVENT'
	| 'REGULAR'
	| 'BIRTHDAY'
	| 'VISIT'
	| 'FILM_START'
	| 'BOUGHT_TICKET'
	| 'BOUGHT_CONCESSIONS'
	| 'NEWSLETTER_SUBSCRIBED'
	| 'CREATED_ACCOUNT'
	| 'APP_INSTALL'
	| 'JOIN_BONUSPROGRAM'
	| 'POINTS_REACHED'
	| 'STATUSLEVEL_REACHED'
	| 'NO_VISIT';
type Triggers = {
	trigger: Trigger;
	label: string;
	icon?: JSX.Element;
	disabled?: boolean;
	canSendBeforeTrigger?: boolean;
}[];
export const triggers: Triggers = [
	{
		trigger: 'SINGLE_EVENT',
		label: 'Einmalig',
		icon: undefined,
	},
	{
		trigger: 'REGULAR',
		label: 'Regelmäßig',
		icon: <AllInclusiveIcon />,
	},
	{
		trigger: 'BIRTHDAY',
		label: 'Geburtstag',
		icon: <CakeRoundedIcon />,
		canSendBeforeTrigger: true,
	},
	{
		trigger: 'VISIT',
		label: 'Kinobesuch',
		icon: <DateRangeRoundedIcon />,
	},
	{
		trigger: 'FILM_START',
		label: 'Filmstart Merkliste',
		icon: <AddAlertRoundedIcon />,
		canSendBeforeTrigger: true,
	},
	{
		trigger: 'BOUGHT_TICKET',
		label: 'Ticket erworben',
		icon: <ConfirmationNumberRoundedIcon />,
	},
	{
		trigger: 'BOUGHT_CONCESSIONS',
		label: 'Concessions erworben',
		icon: <FastfoodRoundedIcon />,
	},
	{
		trigger: 'NEWSLETTER_SUBSCRIBED',
		label: 'Newsletter Anmeldung',
		icon: <DraftsRoundedIcon />,
	},
	{
		trigger: 'CREATED_ACCOUNT',
		label: 'Account Erstellt',
		icon: <AccountCircleRoundedIcon />,
	},
	{
		trigger: 'APP_INSTALL',
		label: 'App Installiert',
		icon: <PhoneIphoneRoundedIcon />,
	},
	{
		trigger: 'JOIN_BONUSPROGRAM',
		label: 'Beitritt Bonusprogramm',
		icon: <LoyaltyRoundedIcon />,
	},
	{
		trigger: 'POINTS_REACHED',
		label: 'Punktestand erreicht',
		icon: <ControlPointDuplicateRoundedIcon />,
		// disabled: true,
	},
	// {
	// 	trigger: 'STATUSLEVEL_REACHED',
	// 	label: 'Statuslevel erreicht',
	// 	icon: <AddBoxRoundedIcon />,
	// 	disabled: true,
	// },
	{
		trigger: 'NO_VISIT',
		label: 'Kein Kinobesuch in Zeitraum',
		icon: <EmojiPeopleRoundedIcon />,
		// disabled: true,
	},
];

const enabledTimeUnits = ['WEEK', 'MONTH', 'YEAR'] as TimeUnit[];

const CenterWrapper = styled(Box)`
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 0.5rem;
`;

const TriggerButton = ({
	onClick,
	trigger,
	label,
	active,
	icon,
	disabled,
}: {
	onClick: (trigger: Trigger) => void;
	trigger: Trigger;
	label: string;
	active: boolean;
	icon?: JSX.Element;
	disabled?: boolean;
}): JSX.Element => {
	const handleClick = React.useCallback(() => {
		onClick?.(trigger);
	}, [onClick, trigger]);
	return (
		<Button
			variant={active ? 'selectButtonActive' : 'selectButton'}
			disabled={disabled}
			onClick={handleClick}
			m="0.5rem"
		>
			<Column>
				<CenterWrapper flex="0.8" alignItems="flex-end">
					{icon}
				</CenterWrapper>
				<CenterWrapper flex="1" alignItems="flex-start" textAlign="center">
					<Txt variant="caption">{label}</Txt>
				</CenterWrapper>
			</Column>
		</Button>
	);
};

export type CampaignTriggerSelectorRef = {
	validate: () => boolean;
};

const CampaignTriggerSelector = React.forwardRef<
	CampaignTriggerSelectorRef,
	{
		p?: string;
		m?: string;
		onChange: (
			campaignKey: string,
			value: Trigger | string | TimeUnitFactorWithTimeUnit | undefined
		) => void;
		defaultValue?: Trigger;
		disabled?: boolean;
		defaultThresholdValue?: string | TimeUnitFactorWithTimeUnit;
	}
>(
	({ p, m, onChange, defaultValue, disabled, defaultThresholdValue }, ref): JSX.Element => {
		// pointsReachedThreshold
		const [pointsReachedThreshold, setPointsReachedThreshold] = React.useState<number | undefined>(
			defaultValue === 'POINTS_REACHED' ? Number(defaultThresholdValue as string) : 1000
		);
		const handleChangePointsThreshold = React.useCallback(
			(newPointsReachedThreshold?: number) => {
				onChange('triggerThreshold', String(newPointsReachedThreshold));
				setPointsReachedThreshold(newPointsReachedThreshold);
			},
			[onChange]
		);

		// noVisitThreshold
		const [noVisitThreshold, setNoVisitThreshold] = React.useState<
			TimeUnitFactorWithTimeUnit | undefined
		>(
			defaultValue === 'NO_VISIT'
				? (defaultThresholdValue as TimeUnitFactorWithTimeUnit)
				: undefined
		);
		const handleChangeNoVisitThreshold = React.useCallback(
			(newNoVisitThreshold?: TimeUnitFactorWithTimeUnit) => {
				setNoVisitThreshold(newNoVisitThreshold);
				onChange('triggerThreshold', newNoVisitThreshold);
				onChange('eventType', undefined); // we don't define eventType for NO_VISIT events
			},
			[onChange]
		);

		// trigger
		const handleChangeTrigger = React.useCallback(
			(newTrigger: Trigger) => {
				console.log('handleChangeTrigger', newTrigger);
				setTrigger(newTrigger);
				onChange('trigger', newTrigger);
				if (newTrigger !== 'POINTS_REACHED' && newTrigger !== 'NO_VISIT') {
					onChange('triggerThreshold', undefined);
				}
				if (newTrigger === 'POINTS_REACHED') {
					handleChangePointsThreshold(pointsReachedThreshold);
				} else if (newTrigger === 'NO_VISIT') {
					handleChangeNoVisitThreshold(noVisitThreshold);
				}
			},
			[
				handleChangeNoVisitThreshold,
				handleChangePointsThreshold,
				noVisitThreshold,
				onChange,
				pointsReachedThreshold,
			]
		);

		const [trigger, setTrigger] = React.useState<Trigger | undefined>(
			defaultValue || 'SINGLE_EVENT'
		);
		const [lastAutoTrigger, setLastAutoTrigger] = React.useState(
			!defaultValue || defaultValue === 'SINGLE_EVENT' ? 'REGULAR' : defaultValue
		);
		const isEventDrivenOrRepeatingCampaign = React.useMemo(() => trigger !== 'SINGLE_EVENT', [
			trigger,
		]);

		const toggleCampaignTrigger = React.useCallback(() => {
			if (trigger === 'SINGLE_EVENT') {
				handleChangeTrigger(lastAutoTrigger);
			} else {
				trigger ? setLastAutoTrigger(trigger) : null;
				handleChangeTrigger('SINGLE_EVENT');
			}
		}, [lastAutoTrigger, handleChangeTrigger, trigger]);

		// validation
		const pointsReachedThresholdRef = React.useRef<IntegerTextFieldRef>(null);
		const noVisitsSinceThresholdRef = React.useRef<TimePeriodEventSelectFormRef>(null);
		const handleValidate = React.useCallback(() => {
			const invalid = [
				pointsReachedThresholdRef.current?.validate(),
				noVisitsSinceThresholdRef.current?.validate(),
			].some(Boolean);
			return invalid;
		}, []);

		React.useImperativeHandle(ref, () => ({ validate: handleValidate }), [handleValidate]);

		return (
			<>
				<Box p="0.7rem 0rem">
					<Txt p="0 0 1rem 0" color="textSecondary">
						Kampagnen Typ
					</Txt>
					<ButtonGroup color="primary" disableElevation>
						<Button
							disabled={disabled}
							variant={isEventDrivenOrRepeatingCampaign ? 'outlined' : 'contained'}
							onClick={toggleCampaignTrigger}
						>
							Einmalig
						</Button>
						<Button
							disabled={disabled}
							variant={isEventDrivenOrRepeatingCampaign ? 'contained' : 'outlined'}
							onClick={toggleCampaignTrigger}
						>
							Automatisch
						</Button>
					</ButtonGroup>
				</Box>
				{isEventDrivenOrRepeatingCampaign ? (
					<Box p={p} m={m}>
						<Box pb="1rem">
							<Typography color="textSecondary">Auslöser</Typography>
						</Box>
						<Row>
							{triggers.map(
								({ trigger: itemTrigger, label, icon, disabled: triggerButtonDisabled }) =>
									itemTrigger !== 'SINGLE_EVENT' ? (
										<TriggerButton
											key={itemTrigger}
											trigger={itemTrigger}
											label={label}
											active={trigger === itemTrigger}
											onClick={handleChangeTrigger}
											disabled={triggerButtonDisabled || disabled}
											icon={icon}
										/>
									) : null
							)}
						</Row>
					</Box>
				) : null}
				{trigger === 'NO_VISIT' ? (
					<SectionWrapper2 m="2rem 0 1rem" p="2rem" label="Kein Kinobesuch seit">
						<TimePeriodSelectForm
							width="100%"
							disabled={disabled}
							defaultValue={noVisitThreshold}
							onChangeCombined={handleChangeNoVisitThreshold}
							enabledTimeUnits={enabledTimeUnits}
							minInteger={1}
							maxInteger={12}
							ref={noVisitsSinceThresholdRef}
						/>
					</SectionWrapper2>
				) : null}
				{trigger === 'POINTS_REACHED' ? (
					<IntegerTextField
						label="Mindestpunktzahl"
						defaultValue={pointsReachedThreshold}
						onChange={handleChangePointsThreshold}
						variant="outlined"
						disabled={disabled}
						min={1}
						max={1000 * 1000}
						ref={pointsReachedThresholdRef}
						m="3rem 0 1rem"
					/>
				) : null}
			</>
		);
	}
);

export default CampaignTriggerSelector;
