import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Box } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField';

import { Brand } from '@cinuru/utils';

import ContentWrapper from '../../components/ContentWrapper';
import Txt from '../../components/Txt';
import Button from '../../components/Button';
import DroppedCard from '../EmailEditor/DroppedCard';
import EditorFieldWrapper from '../../components/EditorFieldWrapper';
import type { Campaign } from '../../utils/campaign';
import useTextFieldController from '../../utils/useTextFieldController';

const Wrapper = styled(Box)`
	min-height: 40rem;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 0.5rem;
	background-color: ${(p) => p.theme.palette.grey[200]};
`;
const Row = styled(Box)`
	display: flex;
	width: 100;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

export type EmailEditorSectionRef = {
	validate: () => boolean;
};

const EmailEditorSection = React.forwardRef<
	EmailEditorSectionRef,
	{
		loading?: boolean;
		onChange: (key: string, value: unknown) => void;
		campaign: Campaign;
		onHandleEditEmail: () => void;
		defaultEmailSubject?: string;
		disabled?: boolean;
		variant?: TextFieldProps['variant'];
	}
>(
	(
		{ loading, onChange, campaign, onHandleEditEmail, defaultEmailSubject, disabled, variant },
		ref
	): JSX.Element => {
		const theme = useTheme();
		const languageId = campaign?.language?.id as string;
		const brand = languageId.split!('de-')[1]?.toUpperCase() as Brand;

		const [error, setError] = React.useState<boolean>(false);

		const emailContentElements = campaign?.emailContent?.elements;

		const {
			textInputProps: emailSubjectProps,
			validate: validateemailSubject,
		} = useTextFieldController({
			defaultValue: defaultEmailSubject,
			inputLabel: 'Betreff',
			stateKey: 'emailSubject',
			validationFunction: (value) => (!value ? 'Bitte ausfüllen' : undefined),
			onChange: (newStatValue) => {
				onChange('emailSubject', newStatValue);
			},
		});

		const handleValidate = React.useCallback(() => {
			if (!emailContentElements) {
				setError(true);
			}
			const hasErrors = [validateemailSubject!(), !emailContentElements].some(Boolean);
			return hasErrors;
		}, [emailContentElements, validateemailSubject]);

		React.useImperativeHandle(
			ref,
			() => ({
				validate: handleValidate,
			}),
			[handleValidate]
		);

		return (
			<ContentWrapper>
				<Txt variant="h6">E-Mail</Txt>
				<EditorFieldWrapper
					margin="2rem 0 1rem"
					markdown={false}
					numberOfLines={2}
					variant={variant}
					{...emailSubjectProps}
					disabled={emailSubjectProps.disabled || disabled}
				/>
				<Box p="2rem 0 0 0">
					<Row>
						<Txt variant="h6">E-Mail Vorschau</Txt>
						<Button
							loading={loading}
							onClick={onHandleEditEmail}
							variant="mainButton"
							startIconName="EditRounded"
							disabled={disabled}
						>
							E-Mail Bearbeiten
						</Button>
					</Row>
					<Wrapper
						m="2rem 0 0 0"
						border={error ? `1px solid ${theme.palette.error.main}` : undefined}
					>
						{!emailContentElements ? (
							<Txt variant="h6" color={error ? theme.palette.error.main : undefined}>
								Noch keine E-Mail angelegt.
							</Txt>
						) : (
							<Box display="flex" flexDirection="column" width="100%" boxShadow={2}>
								{emailContentElements.map((element, index) => {
									return (
										<DroppedCard
											key={element.key}
											index={index.toString()}
											brand={brand}
											campaign={campaign}
											element={element}
											elements={emailContentElements}
											isPreview
										/>
									);
								})}
							</Box>
						)}
					</Wrapper>
				</Box>
			</ContentWrapper>
		);
	}
);

export default React.memo(EmailEditorSection);
