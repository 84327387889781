import React, { useCallback } from 'react';
import { useHistory } from 'react-router';

import { ID } from '@cinuru/utils/types';

import Table from '../../components/Table';
import SectionWrapper2 from '../../components/SectionWrapper2';
import TextField from '../../components/TextField';
import Txt from '../../components/Txt';
import { SearchedUser, useSearchUsers } from '../../utils/users';
import { useDebouncedSearchText } from '../../utils/search';
import LoadingBox from '../../components/LoadingBox';
import StickyHeaderWrapper from '../../components/StickyHeaderWrapper';

const columns = ['Vorname', 'Nachname', 'Email', 'Besonderheiten', ''].map((label, id) => ({
	label,
	id,
}));

const EditUsers: React.FC = () => {
	const history = useHistory();
	const { queryText, debouncedQueryText, setQueryText } = useDebouncedSearchText('');
	const { data, error, loading } = useSearchUsers(debouncedQueryText);

	const handleEditUser = useCallback(
		(id: ID) => () => {
			history.push(`/users/details/${id}`);
		},
		[history]
	);

	const rows = React.useMemo(() => {
		if (!data) return [];
		return data.map((user: SearchedUser) => {
			const isCinemaAdmin = user.privileges.adminForCinemas.length > 0;
			const isBlocked = user.blocked;

			return {
				id: user.id,
				rawData: user,
				data: [
					{
						text: user.firstName,
					},
					{
						text: user.lastName,
					},
					{
						text: user.email,
					},
					{
						chips: [...(isBlocked ? ['Gesperrt'] : []), ...(isCinemaAdmin ? ['Kino-Admin'] : [])],
					},
					{
						buttonLabel: 'Bearbeiten',
						onPress: handleEditUser(user.id),
					},
				],
			};
		});
	}, [data, handleEditUser]);

	return (
		<StickyHeaderWrapper label="Benutzer bearbeiten" maxContentWidth="130rem">
			<SectionWrapper2 p="3rem">
				<TextField type="text" label="Nutzer suchen" value={queryText} onChange={setQueryText} />
				{loading ? (
					<LoadingBox />
				) : error ? (
					<Txt color="error">{error.message}</Txt>
				) : (
					<Table
						columns={columns}
						label={'Benutzer'}
						allRows={rows}
						isSelectable={false}
						isSearchable={false}
					/>
				)}
			</SectionWrapper2>
		</StickyHeaderWrapper>
	);
};

export default EditUsers;
