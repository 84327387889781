import { BarDatum, BarSvgProps, ResponsiveBar } from '@nivo/bar';
import React from 'react';
import { useTheme } from 'styled-components';

const defaultConfig = {
	margin: { top: 10, right: 5, bottom: 50, left: 55 },
	padding: 0.3,
	valueScale: { type: 'linear' },
	indexScale: { type: 'band', round: true },
	borderColor: { from: 'color', modifiers: [['darker', 1.6]] },
	axisTop: null,
	axisRight: null,
	axisBottom: {
		tickSize: 0,
		tickPadding: 0,
		tickRotation: 0,
		legendPosition: 'middle',
		legendOffset: 30,
	},
	axisLeft: {
		tickSize: 5,
		tickPadding: 5,
		tickRotation: 0,
		legendPosition: 'middle',
		legendOffset: -45,
		tickValues: 5,
	},
	enableLabel: false,
	animate: true,
} as BarSvgProps<any>;

const ResponsiveBarChart = <T extends BarDatum>({
	data,
	keys,
	indexBy,
	axisBottomLabel,
	axisLeftLabel,
	...responsiveBarProps
}: Omit<BarSvgProps<T>, 'width' | 'height'> & {
	axisBottomLabel?: string;
	axisLeftLabel?: string;
}): React.ReactElement => {
	const theme = useTheme();
	const colorScale = React.useMemo(() => {
		return [theme.customColors.accentLightBlue];
	}, [theme.customColors.accentLightBlue]);
	const legend = React.useMemo(() => {
		return {
			axisLeft: {
				...defaultConfig.axisLeft,
				legend: axisLeftLabel,
			},
			axisBottom: {
				...defaultConfig.axisBottom,
				legend: axisBottomLabel,
			},
		};
	}, [axisBottomLabel, axisLeftLabel]);
	return (
		<ResponsiveBar<T>
			{...defaultConfig}
			{...legend}
			data={data}
			keys={keys}
			indexBy={indexBy}
			colors={colorScale}
			{...responsiveBarProps}
		/>
	);
};

export default ResponsiveBarChart;
