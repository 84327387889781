import React from 'react';
import styled from 'styled-components';
import { Box, Typography } from '@mui/material';
import { ID } from '@cinuru/utils/types';
import { Language } from '../../utils/language';

import { MovieLinkSelectFieldRef } from './MovieLinkSelectField';
import SelectField, { SelectFieldRef } from '../../components/SelectField';
import { Movie } from '../../utils/movie';
import { useAllMovieLists } from '../../utils/movieList';
import { CampaignLink, CampaignLinkType, getCampaignLinkTypeAndValue } from '../../utils/campaign';
import MovieSelectField from '../../components/MovieSelectField';
import Dialog from '../../components/Dialog';
import Button from '../../components/Button';
import { CustomAppViewEditor } from './CustomAppViewEditor';
import Tooltip from '../../components/Tooltip';
import CheckBoxSection from '../../components/CheckBoxSection';
import TextField from '../../components/TextField';
import FormControlWrapper from '../../components/FormControlWrapper';
import useTextFieldContoller from '../../utils/useTextFieldController';
import { linkTypesThatRequireBonusprogramId, useAvailableLinkTypes } from '../../utils/link';

const linkTypeLabelDict: {
	[key in Exclude<CampaignLinkType, 'CUSTOM_APP_VIEW'>]: {
		label: string;
		disabled?: boolean;
	};
} = {
	NONE: {
		label: 'Keine Verlinkung',
	},
	APP_VIEW_MOVIE_DETAIL: {
		label: 'Film-Details',
	},
	APP_VIEW_PROGRAM: {
		label: 'Programm',
	},
	APP_VIEW_WATCHLIST: {
		label: 'Merkliste',
	},
	APP_VIEW_PROFILE: {
		label: 'Profil',
	},
	APP_VIEW_BONUSPROGRAM: {
		label: 'Bonusprogramm',
	},
	WEB_VIEW: {
		label: 'Webansicht',
	},
	DEEPLINK: {
		label: 'App-Deeplink',
	},
	APP_VIEW_PERKS: {
		label: 'Vorteile',
	},
	APP_VIEW_STICKERS: {
		label: 'Stickerübersicht',
	},
	APP_VIEW_VOUCHERS: {
		label: 'Gutscheinübersicht',
	},
};

const Column = styled(Box)`
	display: flex;
	flex-direction: column;
	align-self: stretch;
`;

const Row = styled(Box)`
	display: flex;
	flex-direction: row;
	width: 100%;
`;

const options = [
	{
		label: 'Detailseite mit weiteren Infos',
		value: 'CUSTOM_APP_VIEW',
	},
	{
		label: 'Link auf bestehende App-Ansicht',
		value: 'PREDEFINED_APP_VIEW',
	},
];

const tooltip1 =
	'In diesem Abschnitt wird definiert welche App-Ansicht angezeigt wird, nachdem der Nutzer die Push-Nachricht angeklickt hat. Es besteht die Möglichkeit eine eigene App-Ansicht oder eine der vordefinierten App-Ansichten anzuzeigen.';
const tooltip2 =
	'Hier wird definiert welche App-Ansicht angezeigt wird, nachdem der Nutzer auf den Link in der angezeigten App-Ansicht geklickt hat.';

export type PushLinkEditorRef = {
	validate: () => boolean;
};

const PushLinkEditor = React.forwardRef<
	PushLinkEditorRef,
	{
		defaultLink?: CampaignLink;
		disabled?: boolean;
		onChange: (newLink?: CampaignLink) => void;
		hasNewsItem?: boolean;
		onSaveCampaign?: (campaignLink?: CampaignLink) => void;
		campaignCinemaIds?: ID[];
		bonusProgramIds: ID[];
		language: Language;
	}
>(
	(
		{
			defaultLink,
			disabled,
			onChange,
			onSaveCampaign,
			hasNewsItem,
			campaignCinemaIds,
			bonusProgramIds,
			language,
		},
		ref
	): JSX.Element => {
		const [defaultLinkType, defaultLinkValue] = getCampaignLinkTypeAndValue(defaultLink);

		const allMovieLists = useAllMovieLists(campaignCinemaIds);
		const watchList = React.useMemo(() => allMovieLists?.find(({ type }) => type === 'watchlist'), [
			allMovieLists,
		]);

		const availableLinkTypes = useAvailableLinkTypes(language);
		const linkOptions = React.useMemo(
			() =>
				availableLinkTypes.map((linkType) => ({
					label: linkTypeLabelDict[linkType].label,
					disabled:
						linkTypeLabelDict[linkType].disabled ||
						(linkType === 'APP_VIEW_WATCHLIST' && !watchList),
					value: linkType,
				})),
			[availableLinkTypes, watchList]
		);

		const [linkType, setLinkType] = React.useState<CampaignLinkType | undefined>(defaultLinkType);
		const [linkValue, setLinkValue] = React.useState<string | undefined>(defaultLinkValue);

		const {
			textInputProps: webViewOrDeeplinkInputProps,
			validate: validateWebViewOrDeeplink,
		} = useTextFieldContoller({
			defaultValue: linkType === 'DEEPLINK' || linkType === 'WEB_VIEW' ? linkValue : undefined,
			inputLabel:
				linkType === 'DEEPLINK' ? 'Deeplink' : linkType === 'WEB_VIEW' ? 'Webadresse' : '',
			stateKey: '',
			validationFunction: (value) =>
				(linkType === 'DEEPLINK' || linkType === 'WEB_VIEW') && !value
					? 'Bitte ausfüllen'
					: undefined,
			stateValueFallback: '',
			onChange: (newStatValue) => {
				handleChangeLinkValue(newStatValue || null);
			},
		});

		const handleChangeLinkType = React.useCallback(
			(newLinkType: string | null) => {
				const sanitzedNewLinkType = newLinkType ? (newLinkType as CampaignLinkType) : undefined;
				// we need the specific bonusprogramId for cinemas that belong to multicinema languages
				if (sanitzedNewLinkType === 'APP_VIEW_WATCHLIST') {
					setLinkType(sanitzedNewLinkType);
					setLinkValue(watchList!.id as string);
					onChange(`${sanitzedNewLinkType}:${watchList!.id as string}` as CampaignLink);
				} else if (
					sanitzedNewLinkType &&
					linkTypesThatRequireBonusprogramId.includes(sanitzedNewLinkType)
				) {
					setLinkType(sanitzedNewLinkType);
					setLinkValue(bonusProgramIds[0] as string);
					onChange(`${sanitzedNewLinkType}:${bonusProgramIds[0]}` as CampaignLink);
				} else {
					setLinkType(sanitzedNewLinkType);
					setLinkValue(undefined);
					onChange(sanitzedNewLinkType ? (`${sanitzedNewLinkType}:` as CampaignLink) : undefined);
				}
			},
			[bonusProgramIds, onChange, watchList]
		);

		const handleChangeLinkValue = React.useCallback(
			(newLinkValue: string | null) => {
				const sanitizedNewLinkValue = newLinkValue || undefined;
				const newLink: CampaignLink = `${linkType}:${sanitizedNewLinkValue}`;
				setLinkValue(sanitizedNewLinkValue);
				onChange(newLink);
			},
			[linkType, onChange]
		);

		const handleSelectFieldChange = React.useCallback(
			(newSelectedItems: (Movie | any)[]) => {
				// TODO: any
				const newSelectedItem = newSelectedItems[0];
				handleChangeLinkValue(newSelectedItem ? (newSelectedItem?.id as string) : null);
			},
			[handleChangeLinkValue]
		);

		const lastSavedNewsItemIdRef = React.useRef(null);
		const handleSaveNewsItem = React.useCallback(
			(newsItemId) => {
				lastSavedNewsItemIdRef.current = newsItemId;
				handleChangeLinkValue(newsItemId);
				onSaveCampaign?.(`CUSTOM_APP_VIEW:${newsItemId}`);
				Dialog.unmount('CustomAppView');
			},
			[handleChangeLinkValue, onSaveCampaign]
		);

		const renderContent = React.useCallback(
			({ dismissPortal }: { dismissPortal: () => void }) => (
				<CustomAppViewEditor
					newsItemId={lastSavedNewsItemIdRef.current || linkValue}
					disabled={disabled}
					onSave={handleSaveNewsItem}
					dismissPortal={dismissPortal}
					campaignCinemaIds={campaignCinemaIds}
					bonusProgramIds={bonusProgramIds}
					language={language}
				/>
			),
			[bonusProgramIds, campaignCinemaIds, disabled, handleSaveNewsItem, language, linkValue]
		);

		const handleOpenNewsEditor = React.useCallback(() => {
			setCustomAppViewError('');
			Dialog.render(
				{
					renderContent,
					buttons: [],
					isLocked: true,
				},
				'CustomAppView'
			);
		}, [renderContent]);

		const linkTypeSelectFieldRef = React.useRef<SelectFieldRef>(null);
		const movieLinkSelectFieldRef = React.useRef<MovieLinkSelectFieldRef>(null);
		// const filmSeriesSelectFieldRef = React.useRef<FilmSeriesSelectFieldRef>(null);

		const handleChangeHasCustomAppView = React.useCallback(
			(value) => {
				const val = value as 'CUSTOM_APP_VIEW' | 'PREDEFINED_APP_VIEW';
				if (val === 'CUSTOM_APP_VIEW') {
					handleChangeLinkType('CUSTOM_APP_VIEW');
				} else {
					handleChangeLinkType(null);
				}
			},
			[handleChangeLinkType]
		);

		// validate if we have a custom app view
		const [customAppViewError, setCustomAppViewError] = React.useState('');
		const validateCustomAppView = React.useCallback(() => {
			const invalid = linkType === 'CUSTOM_APP_VIEW' && !linkValue;
			if (invalid) {
				setCustomAppViewError('Bitte legen sie eine App-Ansicht an');
				return true;
			} else {
				return false;
			}
		}, [linkType, linkValue]);

		// validation
		const handleValidation = React.useCallback(() => {
			const invalid = [
				linkTypeSelectFieldRef?.current?.validate(),
				movieLinkSelectFieldRef?.current?.validate(),
				validateWebViewOrDeeplink!(),
				validateCustomAppView(),
			].some(Boolean);
			return invalid;
		}, [validateCustomAppView, validateWebViewOrDeeplink]);

		React.useImperativeHandle(
			ref,
			() => ({
				validate: handleValidation,
			}),
			[handleValidation]
		);

		return (
			<Column>
				<Row alignItems="center" m="2rem 0 2rem 0">
					<Typography variant="h6">{hasNewsItem ? 'Push-Verlinkgung' : 'Link'}</Typography>
					<Tooltip text={hasNewsItem ? tooltip1 : tooltip2} />
				</Row>
				{hasNewsItem ? (
					<CheckBoxSection
						options={options}
						onChange={handleChangeHasCustomAppView}
						defaultValue={
							linkType
								? linkType === 'CUSTOM_APP_VIEW'
									? 'CUSTOM_APP_VIEW'
									: 'PREDEFINED_APP_VIEW'
								: undefined
						}
						margin="0 0 2rem"
						disabled={disabled}
					/>
				) : null}
				{linkType === 'CUSTOM_APP_VIEW' ? (
					<FormControlWrapper errorMessage={customAppViewError}>
						<Button m="2rem 0 1rem" variant="mainButton" onClick={handleOpenNewsEditor} fullWidth>
							{disabled ? 'Detail-Seite ansehen' : 'Detail-Seite editieren'}
						</Button>
					</FormControlWrapper>
				) : (
					<SelectField
						margin="0 0 1rem"
						label="Link Ziel"
						items={linkOptions}
						onChange={handleChangeLinkType}
						defaultValue={linkType}
						ref={linkTypeSelectFieldRef}
						disabled={disabled}
						hideEmptySelectOption
					/>
				)}
				{linkType === 'APP_VIEW_MOVIE_DETAIL' ? (
					<MovieSelectField
						label="Film"
						ref={movieLinkSelectFieldRef}
						onChange={handleSelectFieldChange}
						defaultMovieId={
							defaultLinkType === 'APP_VIEW_MOVIE_DETAIL' ? defaultLinkValue : undefined
						}
						disabled={disabled}
						m="2rem 0 1rem"
					/>
				) : null}
				{linkType === 'WEB_VIEW' || linkType === 'DEEPLINK' ? (
					<TextField {...webViewOrDeeplinkInputProps} disabled={disabled} m="2rem 0 1rem" />
				) : null}
				{/* {linkType === 'APP_VIEW_FILM_SERIES_DETAIL' ? (
					<FilmSeriesSelectField
						label="Filmreihe"
						ref={movieLinkSelectFieldRef}
						onChange={handleSelectFieldChange}
						defaultFilmSeriesId={
							defaultLinkType === 'APP_VIEW_FILM_SERIES_DETAIL' ? defaultLinkValue : undefined
						}
						disabled={disabled}
						m="2rem 0 1rem"
						cinemaIds={campaignCinemaIds}
					/>
				) : null} */}
			</Column>
		);
	}
);

export default PushLinkEditor;
