import React from 'react';
import ContentWrapper from '../../components/ContentWrapper';
import PushMessageEditor, { PushMessageEditorRef } from './PushMessageEditor';
import PushLinkEditor, { PushLinkEditorRef } from './PushLinkEditor';
import { CampaignLink } from '../../utils/campaign';
import { Language } from '../../utils/language';

import { ID } from '@cinuru/utils/types';

export type PushEditorRef = {
	validate: () => boolean;
};

const PushEditorSection = React.forwardRef<
	PushEditorRef,
	{
		variables?: { name: string; label: string; inputLength: number }[];
		defaultMessageTitle?: string;
		defaultMessage?: string;
		defaultMessageImage?: string;
		defaultLink?: CampaignLink;
		onChange: (key: string, value: unknown) => void;
		variant?: 'outlined' | 'filled' | 'standard';
		onSaveCampaign?: (campaignLink?: CampaignLink) => void;
		bonusProgramIds: ID[];
		disabled?: boolean;
		language: Language;
		campaignCinemaIds: ID[];
	}
>(
	(
		{
			variables,
			defaultMessageTitle,
			defaultMessage,
			defaultMessageImage,
			defaultLink,
			onChange,
			variant = 'outlined',
			onSaveCampaign,
			bonusProgramIds,
			disabled,
			language,
			campaignCinemaIds,
		},
		ref
	): JSX.Element => {
		const [link, setLink] = React.useState<CampaignLink | undefined>(defaultLink);
		const handleChangeLink = React.useCallback(
			(newLink?: string | null) => {
				setLink(newLink ? (newLink as CampaignLink) : undefined);
				onChange('link', newLink);
			},
			[onChange]
		);

		const pushMessageEditorRef = React.useRef<PushMessageEditorRef>(null);
		const pushLinkEditorRef = React.useRef<PushLinkEditorRef>(null);

		const handleValidate = React.useCallback(() => {
			const hasValidationErrors = [
				pushMessageEditorRef.current?.validate(),
				pushLinkEditorRef.current?.validate(),
			].some(Boolean);
			if (hasValidationErrors) {
				return true;
			} else {
				return false;
			}
		}, []);

		React.useImperativeHandle(
			ref,
			() => ({
				validate: handleValidate,
			}),
			[handleValidate]
		);

		return (
			<ContentWrapper>
				<PushMessageEditor
					defaultMessageTitle={defaultMessageTitle}
					defaultMessage={defaultMessage}
					defaultMessageImage={defaultMessageImage}
					onChange={onChange}
					variant={variant}
					variables={variables}
					ref={pushMessageEditorRef}
					disabled={disabled}
					// test notification props:
					link={link}
					language={language}
					campaignCinemaIds={campaignCinemaIds}
					onValidatePushNotification={handleValidate}
				/>
				<PushLinkEditor
					defaultLink={defaultLink}
					onChange={handleChangeLink}
					hasNewsItem
					onSaveCampaign={onSaveCampaign}
					campaignCinemaIds={campaignCinemaIds}
					ref={pushLinkEditorRef}
					disabled={disabled}
					bonusProgramIds={bonusProgramIds}
					language={language}
				/>
			</ContentWrapper>
		);
	}
);

export default PushEditorSection;
