import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Box } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField';
import { ID } from '@cinuru/utils/types';
import { useSnackbar } from 'notistack';

import { CampaignLink, saveNewsItem, useNewsItem } from '../../utils/campaign';
import useTextFieldContoller from '../../utils/useTextFieldController';
import TextField from '../../components/TextField';
import Txt from '../../components/Txt';
import PushLinkEditor, { PushLinkEditorRef } from './PushLinkEditor';
import ProgressBox from '../../components/ProgressBox';
import Button from '../../components/Button';
import ImageSelectButton, { ImageSelectButtonRef } from '../../components/ImageSelectButton';
import CustomAppViewPreviewer from './CustomAppViewPreviewer';
import { useInitializeStateWithDefault } from '../../utils/helpers';
import { FALLBACK_BANNER } from '../../utils/constants';
import { Language } from '../../utils/language';
import EditorFieldWrapper from '../../components/EditorFieldWrapper';

const Column = styled(Box)`
	display: flex;
	flex-direction: column;
`;

const Row = styled(Box)`
	display: flex;
	flex-direction: row;
`;

export type CustomAppViewEditorRef = {
	save: () => Promise<boolean>;
	validate: () => boolean;
};

export const CustomAppViewEditor = React.forwardRef<
	CustomAppViewEditorRef,
	{
		newsItemId?: string;
		onSave: (customAppViewId: string) => void;
		disabled?: boolean;
		variant?: TextFieldProps['variant'];
		dismissPortal?: () => void;
		campaignCinemaIds?: ID[];
		bonusProgramIds: ID[];
		language: Language;
	}
>(
	(
		{
			newsItemId,
			disabled,
			variant,
			dismissPortal,
			onSave,
			campaignCinemaIds,
			bonusProgramIds,
			language,
		},
		ref
	): JSX.Element => {
		const theme = useTheme();
		const [loading, setLoading] = React.useState(false);
		const { enqueueSnackbar } = useSnackbar();

		const newsItem = useNewsItem(newsItemId);

		// initialize title and message
		const {
			newStateValue: messageTitle,
			textInputProps: messageTitleProps,
			validate: validateMessageTitle,
		} = useTextFieldContoller({
			defaultValue: newsItem?.title,
			inputLabel: 'Titel',
			stateKey: 'messageTitle',
			validationFunction: (value) => (!value ? 'Bitte ausfüllen' : undefined),
			finishedInitializing: newsItem !== undefined,
		});
		const {
			newStateValue: teaser,
			textInputProps: teaserProps,
			validate: validateTeaser,
		} = useTextFieldContoller({
			defaultValue: newsItem?.teaser,
			inputLabel: 'Teaser',
			stateKey: 'teaser',
			validationFunction: (value) => (!value ? 'Bitte ausfüllen' : undefined),
			finishedInitializing: newsItem !== undefined,
		});
		const {
			newStateValue: message,
			textInputProps: messageProps,
			validate: validateMessage,
		} = useTextFieldContoller({
			defaultValue: newsItem?.message,
			inputLabel: 'Text',
			stateKey: 'message',
			validationFunction: (value) => (!value ? 'Bitte ausfüllen' : undefined),
			finishedInitializing: newsItem !== undefined,
		});
		const {
			newStateValue: linkText,
			textInputProps: linkTextProps,
			validate: validateLinkText,
		} = useTextFieldContoller({
			defaultValue: newsItem?.linkText,
			inputLabel: 'Link Text',
			stateKey: 'linkText',
			validationFunction: (value) => (!value ? 'Bitte ausfüllen' : undefined),
			finishedInitializing: newsItem !== undefined,
		});

		// custom images
		const defaultCustomPoster = newsItem?.poster;
		const defaultCustomBanner = newsItem?.banner;
		// handle changes of the custom banner
		const [customBanner, setCustomBanner] = useInitializeStateWithDefault<string | undefined>(
			defaultCustomBanner
		);
		const handleChangeCustomBanner = React.useCallback(
			(newBanner) => {
				setCustomBanner(newBanner);
			},
			[setCustomBanner]
		);
		// handles changes of the custom poster
		const [customPoster, setCustomPoster] = useInitializeStateWithDefault<undefined | string>(
			defaultCustomPoster
		);
		const handleChangeCustomPoster = React.useCallback(
			(newPoster) => {
				setCustomPoster(newPoster);
			},
			[setCustomPoster]
		);

		// link related data
		const [link, setLink] = useInitializeStateWithDefault<CampaignLink>(
			newsItem?.link as CampaignLink
		);
		const handleChangeLink = React.useCallback(
			(newLink) => {
				setLink(newLink);
			},
			[setLink]
		);

		const currentPoster = customPoster || FALLBACK_BANNER;
		const currentBanner = customBanner || FALLBACK_BANNER;

		const showLinkLabelTextField = Boolean(link) && link !== 'NONE:';
		// validation
		const pushLinkEditorRef = React.useRef<PushLinkEditorRef>(null);
		const customPosterRef = React.useRef<ImageSelectButtonRef>(null);
		const customBannerRef = React.useRef<ImageSelectButtonRef>(null);
		const handleValidation = React.useCallback(() => {
			const invalid = [
				validateMessageTitle!(),
				validateMessage!(),
				validateTeaser!(),
				showLinkLabelTextField ? validateLinkText!() : undefined,
				pushLinkEditorRef?.current?.validate(),
				!currentPoster ? customPosterRef?.current?.validate() : null,
				!currentBanner ? customBannerRef?.current?.validate() : null,
			].some(Boolean);
			return invalid;
		}, [
			currentBanner,
			currentPoster,
			showLinkLabelTextField,
			validateLinkText,
			validateMessage,
			validateMessageTitle,
			validateTeaser,
		]);

		// save
		const handleSave = React.useCallback(async () => {
			const hasValidationErrors = handleValidation();
			if (hasValidationErrors) return false;
			setLoading(true);
			const { success, newsItemId: customAppViewId } = await saveNewsItem({
				id: newsItemId,
				message: message!,
				teaser: teaser!,
				messageTitle: messageTitle!,
				link: link,
				poster: currentPoster,
				image: currentBanner,
				linkText: linkText!,
			});
			setLoading(false);
			if (success) {
				enqueueSnackbar('Detail-Seite gespeichert', { variant: 'success' });
				onSave?.(customAppViewId);
				return true;
			} else {
				enqueueSnackbar(`Fehler beim Speichern der Detailseite`, { variant: 'error' });
				return false;
			}
		}, [
			enqueueSnackbar,
			handleValidation,
			newsItemId,
			message,
			teaser,
			messageTitle,
			link,
			currentPoster,
			currentBanner,
			linkText,
			onSave,
		]);

		// validation
		React.useImperativeHandle(
			ref,
			() => ({
				validate: handleValidation,
				save: handleSave,
			}),
			[handleSave, handleValidation]
		);

		const isInitializing = newsItemId && newsItem === undefined;
		const isLoading = loading;

		return (
			<Box width="100rem" display="flex" flexDirection="column" p="4rem">
				{isInitializing ? (
					<ProgressBox height="100%" />
				) : (
					<>
						<Row>
							<Column width="50%">
								<Txt variant="h6" m="0 0 1rem 0">
									{disabled ? 'Eigene App-Ansicht' : 'Eigene App-Ansicht editieren'}
								</Txt>
								<EditorFieldWrapper
									margin="2rem 0 1rem"
									markdown={false}
									numberOfLines={2}
									variant={variant}
									{...messageTitleProps}
									disabled={messageTitleProps.disabled || disabled}
								/>
								<EditorFieldWrapper
									margin="2rem 0 1rem"
									markdown={false}
									numberOfLines={2}
									variant={variant}
									{...teaserProps}
									disabled={teaserProps.disabled || disabled}
								/>
								<EditorFieldWrapper
									margin="2rem 0 1rem"
									markdown={false}
									numberOfLines={2}
									variant={variant}
									{...messageProps}
									disabled={messageProps.disabled || disabled}
								/>
								<ImageSelectButton
									width="100%"
									label="Banner wählen"
									m="2rem 0 1rem"
									onChange={handleChangeCustomBanner}
									defaultImageUrl={currentBanner}
									disabled={isLoading || disabled}
									ref={customBannerRef}
								/>
								<ImageSelectButton
									width="100%"
									label="Poster wählen"
									m="2rem 0 1rem"
									onChange={handleChangeCustomPoster}
									defaultImageUrl={currentPoster}
									disabled={isLoading || disabled}
									ref={customPosterRef}
								/>
								<PushLinkEditor
									defaultLink={newsItem?.link}
									onChange={handleChangeLink}
									hasNewsItem={false}
									campaignCinemaIds={campaignCinemaIds}
									ref={pushLinkEditorRef}
									disabled={disabled}
									bonusProgramIds={bonusProgramIds}
									language={language}
								/>
								{showLinkLabelTextField ? (
									<TextField
										m="2rem 0 1rem"
										fullWidth={true}
										variant={variant}
										{...linkTextProps}
										disabled={linkTextProps.disabled || disabled}
									/>
								) : null}
							</Column>
							<Column width="50%">
								<CustomAppViewPreviewer
									messageTitle={messageTitle || undefined}
									message={message || undefined}
									poster={currentPoster}
									banner={currentBanner}
									linkLabel={linkText || ''}
									loading={isLoading}
									hasLink={showLinkLabelTextField}
									teaser={teaser || undefined}
								/>
							</Column>
						</Row>
						<Row
							m="3rem 0 0"
							justifyContent="flex-end"
							borderTop={`1px solid ${theme.customColors.lightGrey}`}
							p="2rem 0 0 0"
						>
							<Button m="0 1rem 0 0" onClick={dismissPortal} loading={isLoading}>
								Schließen
							</Button>
							{disabled ? null : (
								<Button variant="mainButton" onClick={handleSave} loading={isLoading}>
									Speichern
								</Button>
							)}
						</Row>
					</>
				)}
			</Box>
		);
	}
);
