import React from 'react';

import { Box } from '@mui/material';

const PortalContent = React.memo(
	({
		id,
		componentName,
		...props
	}: {
		id: string;
		componentName: string;
	} & unknown) => {
		const handleDismiss = React.useCallback(() => globalThis.portalRef1?.unmount(id), [id]);
		if (!(globalThis.portalRef1 && componentName in globalThis.portalRef1.components)) {
			throw new Error(`trying to render unknown portal ${componentName}`);
		}
		const Component = globalThis.portalRef1.components[componentName];
		return (
			<Box position="absolute" top="0" bottom="0" left="0" right="0">
				<Component {...props} dismissPortal={handleDismiss} />
			</Box>
		);
	}
);

const PortalProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
	const [contents, setContents] = React.useState<
		{ id: string; componentName: string; props: unknown }[]
	>([]);

	// register the global portal ref, using globalThis ensures fast refresh works
	React.useLayoutEffect(() => {
		globalThis.portalRef1 = {
			components: {},
			render: (id: string, componentName: string, props: unknown): void => {
				setContents((cs) => {
					const contentIndex = cs.findIndex((c) => c.id === id);
					if (contentIndex >= 0) {
						const newContents = [...cs];
						newContents[contentIndex] = { id, componentName, props };
						return newContents;
					} else {
						return [...cs, { id, componentName, props }];
					}
				});
			},
			unmount: (id: string): void => {
				setContents((cs) => cs.filter((c) => c.id !== id));
			},
		};
		// reset global portal ref on unmount
		return () => {
			globalThis.portalRef1 = null;
		};
	}, []);

	return (
		<Box width="100%" minHeight="100vh" position="relative">
			{children}
			{contents.map(({ id, componentName, props }) => (
				<PortalContent key={id} id={id} componentName={componentName} {...props} />
			))}
		</Box>
	);
};

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
PortalProvider.render = function <P>(
	id: string,
	Component: React.ComponentType<P>,
	props: { [key: string]: unknown }
): void {
	const componentName = Component.name;
	if (!componentName) throw new Error('Portal components need a name attribute');
	if (!globalThis.portalRef1) throw new Error('No PortalProvider present');
	// register component if it doesn't exist yet
	if (!(componentName in globalThis.portalRef1.components)) {
		globalThis.portalRef1.components[componentName] = Component;
	}
	globalThis.portalRef1.render(id, componentName, props);
};

PortalProvider.unmount = (id: string): void => globalThis.portalRef1?.unmount(id);

export default PortalProvider;
