import * as React from 'react';
import { Grid, FormControlLabel, RadioGroup, Radio } from '@mui/material';
import TimeUnitSelectField, { TimeUnit, TimeUnitSelectFieldRef } from './TimeUnitSelectField';
import IntegerSelectField from './IntegerSelectField';
import type { IntegerSelectFieldRef } from './IntegerSelectField';
import SectionWrapper2 from './SectionWrapper2';
import FormControlWrapper from './FormControlWrapper';

export type TimePeriodEventSelectFormRef = {
	validate: () => boolean;
};

export type EventType = 'BEFORE' | 'AFTER';

const enabledTimeUnits: TimeUnit[] = ['HOUR', 'DAY', 'WEEK', 'MONTH'];

const TimePeriodEventSelectForm = React.forwardRef<
	TimePeriodEventSelectFormRef,
	{
		margin?: string;
		label: string;
		eventLabel?: string;
		padding?: string;
		variant?: 'outlined' | 'filled' | 'standard';
		beforeDisabled?: boolean;
		afterDisabled?: boolean;
		defaultEventType?: EventType;
		onChangeEventType?: (value?: EventType) => void;
		defaultTimeUnit?: TimeUnit;
		onChangeTimeUnit?: (value?: string) => void;
		defaultTimeUnitFactor?: number;
		onChangeTimeUnitFactor?: (value?: number) => void;
		disabled?: boolean;
		flex?: boolean;
		canSendBeforeTrigger?: boolean;
	}
>(
	(
		{
			margin,
			label,
			eventLabel = 'Auslöser',
			padding = '2rem',
			variant,
			beforeDisabled,
			afterDisabled,
			defaultEventType,
			onChangeEventType,
			defaultTimeUnit,
			onChangeTimeUnit,
			defaultTimeUnitFactor,
			onChangeTimeUnitFactor,
			disabled,
			flex,
			canSendBeforeTrigger,
		},
		ref
	): JSX.Element => {
		const [radioButtonValue, setRadioButtonValue] = React.useState<EventType | undefined>(
			!canSendBeforeTrigger ? 'AFTER' : defaultEventType
		);

		const [errorMessage, setErrorMessage] = React.useState<undefined | string>();

		const handleRadioButtonChange = React.useCallback(
			(event: React.ChangeEvent<HTMLInputElement>) => {
				const newValue = event?.target?.value as EventType;
				setErrorMessage(undefined);
				setRadioButtonValue(newValue);
				onChangeEventType?.(newValue);
			},
			[onChangeEventType]
		);

		React.useEffect(() => {
			if (!canSendBeforeTrigger) {
				setErrorMessage(undefined);
				setRadioButtonValue('AFTER');
				onChangeEventType?.('AFTER');
			} else {
				setErrorMessage(undefined);
				setRadioButtonValue(defaultEventType || undefined);
				onChangeEventType?.(defaultEventType || undefined);
			}
		}, [canSendBeforeTrigger, defaultEventType, onChangeEventType, radioButtonValue]);

		const integerSelectFieldRef = React.useRef<IntegerSelectFieldRef>(null);
		const timeUnitSelectFieldRef = React.useRef<TimeUnitSelectFieldRef>(null);

		const handleValidate = React.useCallback(() => {
			if (!radioButtonValue) {
				setErrorMessage('Bitte wählen');
			}
			const errors = [
				!radioButtonValue,
				...[integerSelectFieldRef, timeUnitSelectFieldRef].map((r) => r.current?.validate()),
			];
			const invalid = errors.some(Boolean);
			return invalid;
		}, [radioButtonValue]);

		React.useImperativeHandle(
			ref,
			() => ({
				validate: handleValidate,
			}),
			[handleValidate]
		);

		return (
			<SectionWrapper2 m={margin} p={padding} label={label}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
						<IntegerSelectField
							label="Anzahl"
							defaultValue={defaultTimeUnitFactor}
							onChange={onChangeTimeUnitFactor}
							variant={variant}
							ref={integerSelectFieldRef}
							disabled={disabled}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TimeUnitSelectField
							onChange={onChangeTimeUnit}
							defaultValue={defaultTimeUnit}
							variant={variant}
							ref={timeUnitSelectFieldRef}
							disabled={disabled}
							enabledTimeUnits={enabledTimeUnits}
						/>
					</Grid>
					<Grid item xs={12}>
						<FormControlWrapper
							flex={flex}
							m={margin}
							errorMessage={errorMessage}
							variant={variant}
							disabled={disabled}
						>
							<RadioGroup row value={radioButtonValue} onChange={handleRadioButtonChange}>
								{canSendBeforeTrigger ? (
									<FormControlLabel
										disabled={beforeDisabled}
										value="BEFORE"
										control={<Radio />}
										label={`vor "${eventLabel}"`}
									/>
								) : null}
								<FormControlLabel
									disabled={afterDisabled || !canSendBeforeTrigger}
									value="AFTER"
									control={<Radio />}
									label={`nach "${eventLabel}"`}
								/>
							</RadioGroup>
						</FormControlWrapper>
					</Grid>
				</Grid>
			</SectionWrapper2>
		);
	}
);

export default TimePeriodEventSelectForm;
