import * as React from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { ID } from '@cinuru/utils/types';

import { TargetGroupFiltersAvailability } from './EditTargetGroup';
import { Cluster, Options } from '../../utils/targetGroup';
import DistanceFilter, { DistanceFilterRef } from './Components/DistanceFilter';
import SliderFilter, { TimeUnit } from './Components/SliderFilter';
import TimePeriodFilter, { TimePeriodFilteRef } from './Components/TimePeriodFilter';
import SwitchFilter from './Components/SwitchFilter';
import SearchSelectFilter, {
	SelectFieldItem,
	GeneralSearchFilterRef,
} from './Components/SearchSelectFilter';
import ValueRangeFilter, { ValueRangeFilterRef } from './Components/ValueRangeFilter';
import Expandable, { ExpandableRef } from '../../components/Expandable';
import TextField from '../../components/TextField';
import IconButton from '../../components/IconButton';

import useTextFieldContoller from '../../utils/useTextFieldController';

const genderDict = {
	MALE: 'männlich',
	FEMALE: 'weiblich',
	DIVERSE: 'divers',
};
const groupSizeKeys = {
	LARGEGROUP: 'Gruppenorganisator',
	PAIRS: 'Pärchenkäufer',
	SINGLE: 'Einzelgänger',
};

const expandableProps = {
	margin: '0 0 2rem 0',
	padding: '5rem 3rem 0 0',
};

const Row = styled(Box)`
	display: flex;
	flex-direction: row;
	width: 100%;
`;

export type ClusterPropName =
	| 'ageFilter'
	| 'gendersFilter'
	| 'movieAttributesAffinityFilter'
	| 'SliderFilter'
	| 'parentStatusFilter'
	| 'bonusProgramMemberStatusFilter'
	| 'longTermBenefitsReceivedFilter'
	| 'distanceFilter'
	| 'castOrCrewMemberAffinityFilter'
	| 'genreAffinityFilter'
	| 'movieAffinityFilter'
	| 'moviesSeenFilter'
	| 'moviesNotSeenFilter'
	| 'moviesOnWatchListFilter'
	| 'vouchersReceivedFilter'
	| 'vouchersRedeemedFilter'
	| 'stickersReceivedFilter'
	| 'bonusPointsFilter'
	| 'statusPointsFilter'
	| 'statusLevelFilter'
	| 'noCinemaVisitSinceFilter'
	| 'cinemaVisitSinceFilter'
	| 'averageGroupSizesFilter'
	| 'name'
	| 'averageCinemaVisitsFilter';

const gendersOptions: SelectFieldItem[] = [
	{ label: 'männlich', value: 'MALE' },
	{ label: 'weiblich', value: 'FEMALE' },
	{ label: 'divers', value: 'DIVERSE' },
];

const averageGroupSizeOptions: SelectFieldItem[] = [
	{ label: 'Einzelgänger', value: 'SINGLE' },
	{ label: 'Pärchenkäufer', value: 'PAIRS' },
	{ label: 'Gruppenorganisator', value: 'LARGEGROUP' },
];

export interface ClusterProps {
	clusterPropName: ClusterPropName;
	clusterPropValue: string | { [key: string]: unknown };
	transformItemListValueToListOfIds?: boolean;
}

const enabledTimeUnits: TimeUnit[] = ['WEEK', 'MONTH', 'YEAR'];

export type TargetGroupClusterRef = {
	validate: () => Promise<boolean>;
};

const TargetGroupCluster = React.forwardRef<
	TargetGroupClusterRef,
	{
		cluster: Cluster;
		onChange: ({
			clusterId,
			clusterPropName,
			clusterPropValue,
		}: {
			clusterId: ID;
			clusterPropName: ClusterPropName;
			clusterPropValue: string | { [key: string]: unknown };
		}) => void;
		removeCluster: (clusterId: ID) => void;
		selectionOptions?: Options;
		targetGroupFiltersAvailability: TargetGroupFiltersAvailability;
		changedWithoutSaving: boolean;
		disabled?: boolean;
	}
>(
	(
		{
			cluster,
			onChange,
			removeCluster,
			selectionOptions = { genres: [], vouchers: [], stickers: [], movieAttributes: [] },
			targetGroupFiltersAvailability,
			changedWithoutSaving,
			disabled,
		},
		ref
	): JSX.Element => {
		const { textInputProps: clusterNameProps } = useTextFieldContoller({
			defaultValue: cluster.name,
			inputLabel: 'Cluster-Titel',
			stateKey: '',
			onChange: (value) => {
				onChange({
					clusterId: cluster.id,
					clusterPropName: 'name',
					clusterPropValue: value!,
				});
			},
		});

		const handleChangeClusterProps = React.useCallback(
			({ clusterPropName, clusterPropValue, transformItemListValueToListOfIds }: ClusterProps) => {
				const key =
					transformItemListValueToListOfIds && clusterPropValue && Object.keys(clusterPropValue)[0];
				const newValue =
					transformItemListValueToListOfIds && key && key !== 'active' && clusterPropValue[key]
						? {
								[key]: clusterPropValue[key].map((v) => v.id),
						  }
						: clusterPropValue;
				onChange({ clusterId: cluster.id, clusterPropName, clusterPropValue: newValue });
			},
			[cluster.id, onChange]
		);

		const handleChangeActivity = React.useCallback(
			(clusterPropName: ClusterPropName, active: boolean) => {
				handleChangeClusterProps({
					clusterPropName,
					clusterPropValue: {
						active,
					},
				});
			},
			[handleChangeClusterProps]
		);

		const handleChange = React.useCallback(
			(clusterPropName: ClusterPropName, key: string, items: SelectFieldItem[]) => {
				const transformedItems = items.map((item) => item.value);
				handleChangeClusterProps({
					clusterPropName,
					clusterPropValue: {
						[key]: transformedItems,
					},
				});
			},
			[handleChangeClusterProps]
		);

		const useHandleChangeCluster = (key) => {
			return React.useCallback(
				(clusterPropName, items) => {
					handleChange(clusterPropName, key, items);
				},
				[key]
			);
		};

		const handleChangeGenres = useHandleChangeCluster('genres');
		const handleChangeGroupSizes = useHandleChangeCluster('sizes');
		const handleChangeStickers = useHandleChangeCluster('stickers');
		const handleChangeVouchers = useHandleChangeCluster('vouchers');
		const handleChangeCastOrCrewMember = useHandleChangeCluster('castOrCrewMembers');
		const handleChangeMovies = useHandleChangeCluster('movies');
		const handleChangeGenders = useHandleChangeCluster('genders');

		const handleChangeMovieAttributes = React.useCallback(
			(clusterPropName: ClusterPropName, items: SelectFieldItem[]) => {
				const transformedItems = items.map((item) => ({ id: item.value, label: item.label }));
				handleChangeClusterProps({
					clusterPropName,
					clusterPropValue: {
						movieAttributes: transformedItems,
					},
				});
			},
			[handleChangeClusterProps]
		);

		const handleRemoveCluster = React.useCallback(() => {
			removeCluster(cluster.id);
		}, [cluster.id, removeCluster]);

		const movieAffinityFilteRef = React.useRef<GeneralSearchFilterRef>(null);
		const generalSearchFilterRef2 = React.useRef<GeneralSearchFilterRef>(null);
		const generalSearchFilterRef3 = React.useRef<GeneralSearchFilterRef>(null);
		const generalSearchFilterRef4 = React.useRef<GeneralSearchFilterRef>(null);
		const generalSearchFilterRef5 = React.useRef<GeneralSearchFilterRef>(null);
		const generalSearchFilterRef6 = React.useRef<GeneralSearchFilterRef>(null);
		const generalSearchFilterRef7 = React.useRef<GeneralSearchFilterRef>(null);
		const generalSearchFilterRef8 = React.useRef<GeneralSearchFilterRef>(null);
		const generalSearchFilterRef9 = React.useRef<GeneralSearchFilterRef>(null);
		const generalSearchFilterRef10 = React.useRef<GeneralSearchFilterRef>(null);
		const generalSearchFilterRef11 = React.useRef<GeneralSearchFilterRef>(null);
		const generalSearchFilterRef12 = React.useRef<GeneralSearchFilterRef>(null);
		const distanceFilterRef = React.useRef<DistanceFilterRef>(null);
		const bonusPointsFilterRef = React.useRef<ValueRangeFilterRef>(null);
		const statusPointsFilterRef = React.useRef<ValueRangeFilterRef>(null);
		const statusLevelFilterRef = React.useRef<ValueRangeFilterRef>(null);
		const noCinemaVisitsFilterRef = React.useRef<TimePeriodFilteRef>(null);
		const cinemaVisitSinceFilterRef = React.useRef<TimePeriodFilteRef>(null);

		const renderDemographyContent = React.useCallback(
			(isDisabled?: boolean) => {
				return (
					<>
						<SliderFilter
							label="Empfänger müssen folgender Altersspanne angehören:"
							active={cluster.ageFilter.active}
							showFilter={targetGroupFiltersAvailability.ageFilter}
							onChange={handleChangeClusterProps}
							clusterPropName="ageFilter"
							hideTimeUnit
							defaultMin={cluster.ageFilter.minAge}
							defaultMax={cluster.ageFilter.maxAge}
							minMark={0}
							maxMark={100}
							markStep={10}
							fallbackMin={16}
							fallbackMax={45}
							disabled={isDisabled}
						/>
						<SearchSelectFilter
							active={cluster.gendersFilter.active}
							defaultItems={cluster.gendersFilter.genders.map((g) => ({
								value: g,
								label: genderDict[g],
							}))}
							allItems={gendersOptions}
							showFilter={targetGroupFiltersAvailability.gendersFilter}
							onChange={handleChangeGenders}
							clusterPropName="gendersFilter"
							onChangeActivity={handleChangeActivity}
							label="Empfänger können folgendem Geschlecht angehören"
							ref={generalSearchFilterRef10}
							disabled={isDisabled}
						/>
						<SwitchFilter
							active={cluster.parentStatusFilter.active}
							defaultStatus={cluster.parentStatusFilter.status}
							showFilter={targetGroupFiltersAvailability.parentStatusFilter}
							onChange={handleChangeClusterProps}
							clusterPropName="parentStatusFilter"
							disabled={isDisabled}
						/>
						<DistanceFilter
							active={cluster.distanceFilter.active}
							defaultZipCode={cluster.distanceFilter.zipCode}
							defaultDistanceInKm={cluster.distanceFilter.distanceInKm}
							showFilter={targetGroupFiltersAvailability.distanceFilter}
							onChange={handleChangeClusterProps}
							clusterPropName="distanceFilter"
							ref={distanceFilterRef}
							disabled={isDisabled}
						/>
					</>
				);
			},
			[
				cluster.ageFilter.active,
				cluster.ageFilter.maxAge,
				cluster.ageFilter.minAge,
				cluster.distanceFilter.active,
				cluster.distanceFilter.distanceInKm,
				cluster.distanceFilter.zipCode,
				cluster.gendersFilter.active,
				cluster.gendersFilter.genders,
				cluster.parentStatusFilter.active,
				cluster.parentStatusFilter.status,
				handleChangeActivity,
				handleChangeClusterProps,
				handleChangeGenders,
				targetGroupFiltersAvailability.ageFilter,
				targetGroupFiltersAvailability.distanceFilter,
				targetGroupFiltersAvailability.gendersFilter,
				targetGroupFiltersAvailability.parentStatusFilter,
			]
		);

		const renderFilmContent = React.useCallback(
			(isDisabled?: boolean) => {
				return (
					<>
						<SearchSelectFilter
							active={cluster.movieAffinityFilter.active}
							defaultItems={cluster.movieAffinityFilter.movies.map(({ id, title }) => ({
								value: id as string,
								label: title,
							}))}
							showFilter={targetGroupFiltersAvailability.movieAffinityFilter}
							onChange={handleChangeMovies}
							clusterPropName="movieAffinityFilter"
							onChangeActivity={handleChangeActivity}
							label="Film-Affinität"
							type="MOVIE"
							ref={movieAffinityFilteRef}
							disabled={isDisabled}
						/>
						<SearchSelectFilter
							active={cluster.genreAffinityFilter.active}
							defaultItems={cluster.genreAffinityFilter.genres.map((genre) => ({
								value: genre,
								label: genre.charAt(0) + genre.substring(1).toLowerCase(),
							}))}
							allItems={selectionOptions.genres.map((g) => ({
								value: g.id as string,
								label: g.name,
							}))}
							showFilter={targetGroupFiltersAvailability.genreAffinityFilter}
							onChange={handleChangeGenres}
							clusterPropName="genreAffinityFilter"
							onChangeActivity={handleChangeActivity}
							label="Genre-Affinität"
							ref={generalSearchFilterRef2}
							disabled={isDisabled}
						/>

						<SearchSelectFilter
							active={cluster.castOrCrewMemberAffinityFilter.active}
							defaultItems={cluster.castOrCrewMemberAffinityFilter.castOrCrewMembers.map(
								({ id, fullName }) => ({ value: id as string, label: fullName })
							)}
							showFilter={targetGroupFiltersAvailability.castOrCrewMemberAffinityFilter}
							onChange={handleChangeCastOrCrewMember}
							clusterPropName="castOrCrewMemberAffinityFilter"
							onChangeActivity={handleChangeActivity}
							label="Filmcrew-Affinität"
							type="CAST_OR_CREW_MEMBER"
							ref={generalSearchFilterRef3}
							disabled={isDisabled}
						/>
						<SearchSelectFilter
							active={cluster.movieAttributesAffinityFilter.active}
							defaultItems={cluster.movieAttributesAffinityFilter.movieAttributes.map(
								({ id, label }) => ({ value: id as string, label })
							)}
							allItems={selectionOptions.movieAttributes.map(({ id, label }) => ({
								value: id as string,
								label,
							}))}
							showFilter={targetGroupFiltersAvailability.movieAttributesAffinityFilter}
							onChange={handleChangeMovieAttributes}
							clusterPropName="movieAttributesAffinityFilter"
							onChangeActivity={handleChangeActivity}
							label="Filmeigenschaften-Affinität"
							ref={generalSearchFilterRef11}
							disabled={isDisabled}
						/>
						<SearchSelectFilter
							active={cluster.moviesSeenFilter.active}
							defaultItems={cluster.moviesSeenFilter.movies.map(({ id, title }) => ({
								value: id as string,
								label: title,
							}))}
							showFilter={targetGroupFiltersAvailability.moviesSeenFilter}
							onChange={handleChangeMovies}
							clusterPropName="moviesSeenFilter"
							onChangeActivity={handleChangeActivity}
							label="Filme die Empfänger gesehen haben müssen"
							type="MOVIE"
							ref={generalSearchFilterRef4}
							disabled={isDisabled}
						/>
						<SearchSelectFilter
							active={cluster.moviesNotSeenFilter.active}
							defaultItems={cluster.moviesNotSeenFilter.movies.map(({ id, title }) => ({
								value: id as string,
								label: title,
							}))}
							showFilter={targetGroupFiltersAvailability.moviesNotSeenFilter}
							onChange={handleChangeMovies}
							clusterPropName="moviesNotSeenFilter"
							onChangeActivity={handleChangeActivity}
							label="Filme die Empfänger nicht gesehen haben dürfen"
							type="MOVIE"
							ref={generalSearchFilterRef5}
							disabled={isDisabled}
						/>
						<SearchSelectFilter
							active={cluster.moviesOnWatchListFilter.active}
							defaultItems={cluster.moviesOnWatchListFilter.movies.map(({ id, title }) => ({
								value: id as string,
								label: title,
							}))}
							showFilter={targetGroupFiltersAvailability.moviesOnWatchListFilter}
							onChange={handleChangeMovies}
							clusterPropName="moviesOnWatchListFilter"
							onChangeActivity={handleChangeActivity}
							label="Filme die Empfänger auf der Merkliste haben müssen"
							type="MOVIE"
							ref={generalSearchFilterRef6}
							disabled={isDisabled}
						/>
					</>
				);
			},
			[
				cluster.castOrCrewMemberAffinityFilter.active,
				cluster.castOrCrewMemberAffinityFilter.castOrCrewMembers,
				cluster.genreAffinityFilter.active,
				cluster.genreAffinityFilter.genres,
				cluster.movieAffinityFilter.active,
				cluster.movieAffinityFilter.movies,
				cluster.movieAttributesAffinityFilter.active,
				cluster.movieAttributesAffinityFilter.movieAttributes,
				cluster.moviesNotSeenFilter.active,
				cluster.moviesNotSeenFilter.movies,
				cluster.moviesOnWatchListFilter.active,
				cluster.moviesOnWatchListFilter.movies,
				cluster.moviesSeenFilter.active,
				cluster.moviesSeenFilter.movies,
				handleChangeActivity,
				handleChangeCastOrCrewMember,
				handleChangeGenres,
				handleChangeMovieAttributes,
				handleChangeMovies,
				selectionOptions.genres,
				selectionOptions.movieAttributes,
				targetGroupFiltersAvailability.castOrCrewMemberAffinityFilter,
				targetGroupFiltersAvailability.genreAffinityFilter,
				targetGroupFiltersAvailability.movieAffinityFilter,
				targetGroupFiltersAvailability.movieAttributesAffinityFilter,
				targetGroupFiltersAvailability.moviesNotSeenFilter,
				targetGroupFiltersAvailability.moviesOnWatchListFilter,
				targetGroupFiltersAvailability.moviesSeenFilter,
			]
		);

		const renderBonusProgramContent = React.useCallback(
			(isDisabled?: boolean) => {
				return (
					<>
						<SwitchFilter
							active={cluster.bonusProgramMemberStatusFilter.active}
							defaultStatus={cluster.bonusProgramMemberStatusFilter.status}
							showFilter={targetGroupFiltersAvailability.bonusProgramMemberStatusFilter}
							onChange={handleChangeClusterProps}
							clusterPropName="bonusProgramMemberStatusFilter"
							disabled={isDisabled}
						/>
						<ValueRangeFilter
							showFilter={targetGroupFiltersAvailability.bonusPointsFilter}
							active={cluster.bonusPointsFilter.active}
							defaultMinimum={cluster.bonusPointsFilter.minBonusPoints}
							defaultMaximum={cluster.bonusPointsFilter.maxBonusPoints}
							onChange={handleChangeClusterProps}
							clusterPropName="bonusPointsFilter"
							ref={bonusPointsFilterRef}
							disabled={isDisabled}
						/>
						<ValueRangeFilter
							showFilter={targetGroupFiltersAvailability.statusPointsFilter}
							active={cluster.statusPointsFilter.active}
							defaultMinimum={cluster.statusPointsFilter.minStatusPoints}
							defaultMaximum={cluster.statusPointsFilter.maxStatusPoints}
							onChange={handleChangeClusterProps}
							clusterPropName="statusPointsFilter"
							ref={statusPointsFilterRef}
							disabled={isDisabled}
						/>
						<ValueRangeFilter
							showFilter={targetGroupFiltersAvailability.statusLevelFilter}
							active={cluster.statusLevelFilter.active}
							defaultMinimum={cluster.statusLevelFilter.minStatusLevel}
							defaultMaximum={cluster.statusLevelFilter.maxStatusLevel}
							onChange={handleChangeClusterProps}
							clusterPropName="statusLevelFilter"
							ref={statusLevelFilterRef}
							disabled={isDisabled}
						/>

						<SearchSelectFilter
							active={cluster.vouchersReceivedFilter.active}
							defaultItems={cluster.vouchersReceivedFilter.vouchers.map(({ id, title }) => ({
								value: id as string,
								label: title,
							}))}
							allItems={selectionOptions.vouchers.map(({ id, title }) => ({
								value: id as string,
								label: title || '',
							}))}
							showFilter={targetGroupFiltersAvailability.vouchersReceivedFilter}
							onChange={handleChangeVouchers}
							clusterPropName="vouchersReceivedFilter"
							onChangeActivity={handleChangeActivity}
							label="Empfänger müssen Gutscheine erhalten haben"
							ref={generalSearchFilterRef7}
							disabled={isDisabled}
						/>

						<SearchSelectFilter
							active={cluster.vouchersRedeemedFilter.active}
							defaultItems={cluster.vouchersRedeemedFilter.vouchers.map(({ id, title }) => ({
								value: id as string,
								label: title,
							}))}
							allItems={selectionOptions.vouchers.map(({ id, title }) => ({
								value: id as string,
								label: title || '',
							}))}
							showFilter={targetGroupFiltersAvailability.vouchersRedeemedFilter}
							onChange={handleChangeVouchers}
							clusterPropName="vouchersRedeemedFilter"
							onChangeActivity={handleChangeActivity}
							label="Empfänger müssen Gutscheine eingelöst haben"
							ref={generalSearchFilterRef8}
							disabled={isDisabled}
						/>
						<SearchSelectFilter
							active={cluster.stickersReceivedFilter.active}
							defaultItems={cluster.stickersReceivedFilter.stickers.map(
								({ id, title, description }) => ({
									value: id as string,
									label: `${title}${description ? ': "' + description + '"' : ''}`,
								})
							)}
							allItems={selectionOptions.stickers.map(({ id, title }) => ({
								value: id as string,
								label: title,
							}))}
							showFilter={targetGroupFiltersAvailability.stickersReceivedFilter}
							onChange={handleChangeStickers}
							clusterPropName="stickersReceivedFilter"
							onChangeActivity={handleChangeActivity}
							label="Empfänger müssen Sticker erhalten haben"
							ref={generalSearchFilterRef9}
							disabled={isDisabled}
						/>
						<SwitchFilter
							showFilter={targetGroupFiltersAvailability.longTermBenefitsReceivedFilter}
							active={cluster.longTermBenefitsReceivedFilter.active}
							defaultStatus={cluster.longTermBenefitsReceivedFilter.status}
							onChange={handleChangeClusterProps}
							clusterPropName="longTermBenefitsReceivedFilter"
							disabled={isDisabled}
						/>
					</>
				);
			},
			[
				cluster.bonusPointsFilter.active,
				cluster.bonusPointsFilter.maxBonusPoints,
				cluster.bonusPointsFilter.minBonusPoints,
				cluster.bonusProgramMemberStatusFilter.active,
				cluster.bonusProgramMemberStatusFilter.status,
				cluster.longTermBenefitsReceivedFilter.active,
				cluster.longTermBenefitsReceivedFilter.status,
				cluster.statusLevelFilter.active,
				cluster.statusLevelFilter.maxStatusLevel,
				cluster.statusLevelFilter.minStatusLevel,
				cluster.statusPointsFilter.active,
				cluster.statusPointsFilter.maxStatusPoints,
				cluster.statusPointsFilter.minStatusPoints,
				cluster.stickersReceivedFilter.active,
				cluster.stickersReceivedFilter.stickers,
				cluster.vouchersReceivedFilter.active,
				cluster.vouchersReceivedFilter.vouchers,
				cluster.vouchersRedeemedFilter.active,
				cluster.vouchersRedeemedFilter.vouchers,
				handleChangeActivity,
				handleChangeClusterProps,
				handleChangeStickers,
				handleChangeVouchers,
				selectionOptions.stickers,
				selectionOptions.vouchers,
				targetGroupFiltersAvailability.bonusPointsFilter,
				targetGroupFiltersAvailability.bonusProgramMemberStatusFilter,
				targetGroupFiltersAvailability.longTermBenefitsReceivedFilter,
				targetGroupFiltersAvailability.statusLevelFilter,
				targetGroupFiltersAvailability.statusPointsFilter,
				targetGroupFiltersAvailability.stickersReceivedFilter,
				targetGroupFiltersAvailability.vouchersReceivedFilter,
				targetGroupFiltersAvailability.vouchersRedeemedFilter,
			]
		);

		const renderVisitorBehaviourContent = React.useCallback(
			(isDisabled?: boolean) => {
				return (
					<>
						<SliderFilter
							label="Durchschnittliche Kinobesuchs-Häufigkeit"
							showFilter={targetGroupFiltersAvailability.averageCinemaVisitsFilter}
							active={cluster.averageCinemaVisitsFilter.active}
							defaultTimePeriod={cluster.averageCinemaVisitsFilter.timePeriod}
							onChange={handleChangeClusterProps}
							clusterPropName="averageCinemaVisitsFilter"
							enabledTimeUnits={enabledTimeUnits}
							defaultMin={cluster.averageCinemaVisitsFilter.minVisits}
							defaultMax={cluster.averageCinemaVisitsFilter.maxVisits}
							minMark={0}
							maxMark={20}
							markStep={5}
							fallbackMin={1}
							fallbackMax={5}
							disabled={isDisabled}
						/>
						<TimePeriodFilter
							label="Kein Kinobesuch in Zeitraum"
							showFilter={targetGroupFiltersAvailability.noCinemaVisitSinceFilter}
							active={cluster.noCinemaVisitSinceFilter.active}
							defaultTimePeriod={cluster.noCinemaVisitSinceFilter.timePeriod}
							defaultAmount={cluster.noCinemaVisitSinceFilter.amount}
							onChange={handleChangeClusterProps}
							clusterPropName="noCinemaVisitSinceFilter"
							ref={noCinemaVisitsFilterRef}
							disabled={isDisabled}
						/>
						<TimePeriodFilter
							label="Kinobesuch in Zeitraum"
							showFilter={targetGroupFiltersAvailability.cinemaVisitSinceFilter}
							active={cluster.cinemaVisitSinceFilter.active}
							defaultTimePeriod={cluster.cinemaVisitSinceFilter.timePeriod}
							defaultAmount={cluster.cinemaVisitSinceFilter.amount}
							onChange={handleChangeClusterProps}
							clusterPropName="cinemaVisitSinceFilter"
							ref={cinemaVisitSinceFilterRef}
							disabled={isDisabled}
						/>
						<SearchSelectFilter
							active={cluster.averageGroupSizesFilter.active}
							defaultItems={cluster.averageGroupSizesFilter.sizes.map((size) => ({
								value: size,
								label: groupSizeKeys[size],
							}))}
							allItems={averageGroupSizeOptions}
							showFilter={targetGroupFiltersAvailability.averageGroupSizesFilter}
							onChange={handleChangeGroupSizes}
							clusterPropName="averageGroupSizesFilter"
							onChangeActivity={handleChangeActivity}
							label="Käufereigenschaften"
							ref={generalSearchFilterRef12}
							disabled={isDisabled}
						/>
					</>
				);
			},
			[
				cluster.averageCinemaVisitsFilter.active,
				cluster.averageCinemaVisitsFilter.maxVisits,
				cluster.averageCinemaVisitsFilter.minVisits,
				cluster.averageCinemaVisitsFilter.timePeriod,
				cluster.averageGroupSizesFilter.active,
				cluster.averageGroupSizesFilter.sizes,
				cluster.cinemaVisitSinceFilter.active,
				cluster.cinemaVisitSinceFilter.amount,
				cluster.cinemaVisitSinceFilter.timePeriod,
				cluster.noCinemaVisitSinceFilter.active,
				cluster.noCinemaVisitSinceFilter.amount,
				cluster.noCinemaVisitSinceFilter.timePeriod,
				handleChangeActivity,
				handleChangeClusterProps,
				handleChangeGroupSizes,
				targetGroupFiltersAvailability.averageCinemaVisitsFilter,
				targetGroupFiltersAvailability.averageGroupSizesFilter,
				targetGroupFiltersAvailability.cinemaVisitSinceFilter,
				targetGroupFiltersAvailability.noCinemaVisitSinceFilter,
			]
		);

		const demographyRef = React.useRef<ExpandableRef>(null);
		const filmContentRef = React.useRef<ExpandableRef>(null);
		const bonusProgramRef = React.useRef<ExpandableRef>(null);
		const visitorBehaviourRef = React.useRef<ExpandableRef>(null);

		const expandables = React.useMemo(
			() => [
				{
					ref: demographyRef,
					subRefs: [generalSearchFilterRef10],
				},
				{
					ref: filmContentRef,
					subRefs: [
						movieAffinityFilteRef,
						generalSearchFilterRef2,
						generalSearchFilterRef3,
						generalSearchFilterRef11,
						generalSearchFilterRef4,
						generalSearchFilterRef5,
						generalSearchFilterRef6,
					],
				},
				{
					ref: bonusProgramRef,
					subRefs: [
						bonusPointsFilterRef,
						statusPointsFilterRef,
						statusLevelFilterRef,
						generalSearchFilterRef7,
						generalSearchFilterRef8,
						generalSearchFilterRef9,
					],
				},
				{
					ref: visitorBehaviourRef,
					subRefs: [noCinemaVisitsFilterRef, cinemaVisitSinceFilterRef, generalSearchFilterRef12],
				},
			],
			[demographyRef, filmContentRef, bonusProgramRef, visitorBehaviourRef]
		);

		const handleValidate = React.useCallback(async () => {
			// get the expandable where we have validation erros in the contents of the expandable
			const expandablesWithErrors = expandables.filter((expandable) => {
				const expandableContentHasError = expandable.subRefs
					.map((r) => r.current?.validate())
					.some(Boolean);
				return expandableContentHasError;
			});
			let invalid = false;
			if (expandablesWithErrors.length) {
				// collapse all expandables first, then expand those that have errors
				expandables.forEach((exp) => exp.ref.current?.collapse());
				expandablesWithErrors.forEach((expandable) => expandable.ref.current?.expand());
				invalid = true;
				await new Promise((resolve) => {
					setTimeout(() => {
						resolve(true);
					}, 500);
				});
				// scroll to the first invalid expandable content ref
				const firstInvalidSubRef = expandablesWithErrors
					.reduce<
						React.RefObject<GeneralSearchFilterRef | ValueRangeFilterRef | TimePeriodFilteRef>[]
					>((acc, nextExp) => [...acc, ...nextExp.subRefs], [])
					.find((r) => r.current?.validate());
				firstInvalidSubRef!.current?.scrollIntoView();
			}
			return invalid;
		}, [expandables]);

		React.useImperativeHandle(
			ref,
			() => ({
				validate: handleValidate,
			}),
			[handleValidate]
		);

		return (
			<>
				<Row justifyContent="space-between" m="2rem 0">
					<TextField
						m="0 1rem 0 0"
						flex
						variant="outlined"
						{...clusterNameProps}
						disabled={disabled}
					/>
					<TextField
						m="0 1rem 0 0"
						label="Größe des Clusters"
						variant="outlined"
						value={changedWithoutSaving ? '?' : cluster.clusterSize || ''}
						width="20rem"
						disabled
						helperText={changedWithoutSaving ? 'für Berechnung bitte speichern' : ''}
					/>
					<IconButton
						iconName="DeleteOutline"
						m="1rem"
						onClick={handleRemoveCluster}
						disabled={disabled}
					>
						<DeleteIcon />
					</IconButton>
				</Row>

				<Expandable
					label="Demografie"
					{...expandableProps}
					disabled={disabled}
					renderContent={renderDemographyContent}
					ref={demographyRef}
				/>

				<Expandable
					label="Filme"
					{...expandableProps}
					disabled={disabled}
					renderContent={renderFilmContent}
					ref={filmContentRef}
				/>
				<Expandable
					label="Bonusprogram"
					{...expandableProps}
					disabled={disabled}
					renderContent={renderBonusProgramContent}
					ref={bonusProgramRef}
				/>
				<Expandable
					label="Besuchsverhalten"
					{...expandableProps}
					disabled={disabled}
					renderContent={renderVisitorBehaviourContent}
					ref={visitorBehaviourRef}
				/>
			</>
		);
	}
);

export default TargetGroupCluster;
