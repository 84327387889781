import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import {
	Box,
	FormControl,
	TextField,
	Popper,
	Paper,
	Fade,
	InputLabel,
	Chip,
	Input,
	FormHelperText,
	CircularProgress,
} from '@mui/material';
import { ListItem, ListItemButton } from '@mui/material';
import { FixedSizeList } from 'react-window';
import { TextFieldProps } from '@mui/material/TextField';
import uuid from 'uuid/v4';

import fuzzySearch from '@cinuru/utils/fuzzySearch';

import { useDivDimensions } from '../utils/dimensions';
import Txt from '../components/Txt';
import { POPPER_ZINDEX } from '../utils/constants';

export type SelectFieldItem = { label: string; value?: string };
type SelectFieldOrControlOrActionItem = SelectFieldItem & { isControl?: true } & {
	isAction?: true;
};

const MAX_HEIGHT = 400;
const ITEM_HEIGHT = 46;

type Action = 'SELECT_ALL' | 'DESELECT_ALL';
const actions: { value: Action; label: string }[] = [
	{
		value: 'SELECT_ALL' as Action,
		label: 'Alles auswählen',
	},
	{
		value: 'DESELECT_ALL' as Action,
		label: 'Alles abwählen',
	},
].map((obj) => ({ ...obj, isAction: true }));

const VirtualizedList = React.forwardRef(
	(
		{
			items,
			onChange,
			selectedItems,
			controls,
			onHandleControl,
			showActions,
			onHandleAction,
		}: {
			items: SelectFieldItem[];
			onChange: (item: SelectFieldItem) => void;
			selectedItems: SelectFieldItem[];
			controls?: SelectFieldItem[];
			onHandleControl?: (controlValue: string | undefined) => void;
			showActions?: boolean;
			onHandleAction: (actionValue: Action) => void;
		},
		ref
	): JSX.Element => {
		const theme = useTheme();

		const controlsAndItems: SelectFieldOrControlOrActionItem[] = React.useMemo(
			() => [
				...(controls ? controls.map((obj) => ({ ...obj, isControl: true })) : []),
				...(showActions ? actions : []),
				...items,
			],
			[controls, items, showActions]
		);
		const numberOfItems = React.useMemo(() => controlsAndItems?.length, [controlsAndItems?.length]);
		const height = React.useMemo(
			() => (numberOfItems * ITEM_HEIGHT > MAX_HEIGHT ? MAX_HEIGHT : numberOfItems * ITEM_HEIGHT),
			[numberOfItems]
		);
		const wrapperStyle = React.useMemo(
			() => ({ width: '100%', height, bgcolor: 'background.paper' }),
			[height]
		);

		const handleClick = React.useCallback(
			(clickedItem: SelectFieldOrControlOrActionItem) => {
				if (clickedItem.isControl) {
					onHandleControl?.(clickedItem.value);
				} else if (clickedItem.isAction) {
					onHandleAction?.(clickedItem.value as Action);
				} else {
					onChange?.(clickedItem);
				}
			},
			[onChange, onHandleAction, onHandleControl]
		);

		const renderRow = React.useCallback(
			({ index, style }) => {
				const item = controlsAndItems?.[index];
				const nextItem = controlsAndItems?.[index + 1];
				const nextItemIsControl = nextItem?.isControl;
				const nextItemIsAction = nextItem?.isAction;
				if (item) {
					return (
						<ListItem
							style={style}
							key={index}
							component="div"
							disablePadding
							// eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
							onClick={() => handleClick(item)}
						>
							<Box
								width="100%"
								borderBottom={
									(item.isControl && !nextItemIsControl) || (item.isAction && !nextItemIsAction)
										? `1px solid ${theme.customColors.lightGrey}`
										: undefined
								}
								m="0"
							>
								<ListItemButton selected={selectedItems.some(({ value }) => value === item.value)}>
									<Txt>{item.label}</Txt>
								</ListItemButton>
							</Box>
						</ListItem>
					);
				} else {
					return null;
				}
			},
			[controlsAndItems, handleClick, selectedItems, theme.customColors.lightGrey]
		);

		return (
			<Box sx={wrapperStyle}>
				<FixedSizeList
					height={height}
					width="100%"
					itemSize={ITEM_HEIGHT}
					itemCount={numberOfItems}
					overscanCount={5}
				>
					{renderRow}
				</FixedSizeList>
			</Box>
		);
	}
);

const StyledInputLabel = styled(InputLabel)`
	background-color: white;
`;

const InputWithChips = React.forwardRef(
	(
		{
			selectedItems,
			handleFocus,
			onClick,
			variant = 'outlined',
			onChange,
			value,
			disabled,
		}: {
			selectedItems?: SelectFieldItem[];
			handleFocus: () => void;
			onClick: (item: SelectFieldItem) => void;
			variant: 'outlined' | 'filled';
			onChange: (event) => void;
			value: string;
			disabled?: boolean;
		},
		ref
	): JSX.Element => {
		const handleClick = React.useCallback(
			(item) => {
				onClick?.(item);
			},
			[onClick]
		);

		return (
			<Box width="100%">
				<Box width="100%" padding="1.5rem 2rem">
					<Input
						onFocus={handleFocus}
						fullWidth
						disableUnderline={!selectedItems?.length}
						onChange={onChange}
						value={value}
						disabled={disabled}
					/>
				</Box>
				{selectedItems?.length ? (
					<Box width="100%" display="flex" flexWrap="wrap" padding="0 2rem 1rem">
						{selectedItems.map((item) => (
							<Box mr="0.25rem" mb="0.25rem" key={item.value}>
								<Chip
									size="small"
									label={item.label}
									variant={variant}
									// eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
									onDelete={() => handleClick(item)}
									disabled={disabled}
								/>
							</Box>
						))}
					</Box>
				) : null}
			</Box>
		);
	}
);

export type SearchSelectFieldRef = {
	validate: () => boolean;
};

const popperStyle = {
	style: {
		zIndex: POPPER_ZINDEX,
	},
};

const ClickawayDiv = styled(Box)`
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
`;

const SearchSelectField = React.forwardRef<
	SearchSelectFieldRef,
	{
		label: string;
		onChange: (selectedItems: SelectFieldItem[]) => void;
		multi?: boolean;
		variant?: TextFieldProps['variant'];
		defaultItems?: SelectFieldItem[] | null;
		m?: string;
		allItems?: SelectFieldItem[];
		fuseSearchOptions?: { [prop: string]: number | boolean };
		disabled?: boolean;
		externalSearch?: boolean; // performs an externalSearch i.e. the typed query is sent to the parent component, which performs the search and feeds the results of the search back into this component (into the allItems array)
		onChangeQuery?: (query: string) => void;
		flex?: string;
		width?: string;
		controls?: SelectFieldItem[];
		onHandleControl?: (controlValue: string | undefined) => void;
		showActions?: boolean;
	}
>(
	(
		{
			label,
			onChange,
			multi,
			variant = 'outlined',
			defaultItems,
			m,
			allItems,
			fuseSearchOptions = { shouldSort: true, includeScore: true, threshold: 0.2 },
			disabled,
			externalSearch,
			onChangeQuery,
			flex,
			width,
			controls,
			onHandleControl,
			showActions,
		},
		ref
	): JSX.Element => {
		const [selectedItems, setSelectedItems] = React.useState<SelectFieldItem[]>(defaultItems || []);
		const [query, setQuery] = React.useState<string>('');
		const popperId = React.useMemo(() => uuid(), []);

		// internalSearchResults are those items in the allItems array that fit to the respective query, this is only relevant if externalSearch is false
		const [internalSearchResults, setInternalSearchResults] = React.useState<SelectFieldItem[]>([]);
		const [
			onlyShowInternalSearchResults,
			setOnlyShowInternalSearchResults,
		] = React.useState<boolean>(false);

		const [focus, setFocus] = React.useState<boolean>(false);
		const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
		const [open, setOpen] = React.useState(false);
		const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

		const textFieldRef = React.useRef(null);
		const containerDimensions = useDivDimensions(textFieldRef);

		const valueToLabelDictionary = React.useMemo(
			() =>
				allItems
					? new Map<string | undefined, string>(allItems.map((c) => [c.value, c.label]))
					: new Map<string | undefined, string>(),
			[allItems]
		);

		const handleQuery = React.useCallback(
			(event) => {
				const newQuery = event?.target?.value || '';
				setErrorMessage(null);
				setQuery(newQuery);

				if (!multi) {
					setSelectedItems([]);
					onChange?.([]);
				}
				// we are performing an (internal) search on the allItems array
				if (!externalSearch) {
					if (newQuery === '') {
						setOnlyShowInternalSearchResults(false);
					} else {
						const searchResults = fuzzySearch(
							allItems!,
							newQuery.trim(),
							'label',
							fuseSearchOptions,
							true
						).map(({ item }) => item);
						setInternalSearchResults(searchResults as { value: string; label: string }[]);
						setOnlyShowInternalSearchResults(true);
					}
				} else {
					// we are sending the query to the parent, which performs the (external) search and the parent then sends us the search results as the allItems array
					onChangeQuery?.(newQuery);
				}
			},
			[externalSearch, allItems, fuseSearchOptions, multi, onChange, onChangeQuery]
		);

		const handleSelect = React.useCallback(
			(newItem: SelectFieldItem) => {
				setErrorMessage(null);
				if (!externalSearch) {
					setOnlyShowInternalSearchResults(false);
				}
				if (!multi) {
					const updatedSelectedItems = [newItem!];
					setSelectedItems(updatedSelectedItems);
					onChange && onChange(updatedSelectedItems);
					setOpen(false);
					setFocus(false);
				} else {
					if (selectedItems.some(({ value }) => value === newItem.value)) {
						const updatedSelectedItems = selectedItems.filter(
							({ value }) => value !== newItem.value
						);
						setSelectedItems(updatedSelectedItems);
						onChange?.(updatedSelectedItems);
					} else {
						const updatedSelectedItems = [...selectedItems, newItem];
						setSelectedItems(updatedSelectedItems);
						onChange?.(updatedSelectedItems);
					}
				}
			},
			[externalSearch, multi, onChange, selectedItems]
		);

		const handleFocus = React.useCallback(() => {
			setAnchorEl(textFieldRef.current);
			setOpen(true);
			setFocus(true);
		}, []);

		const handleClickAway = React.useCallback(() => {
			if (open && focus) {
				setOpen(false);
				setFocus(false);
			}
		}, [focus, open]);

		const handleAction = React.useCallback(
			(action: Action) => {
				if (action === 'DESELECT_ALL') {
					setSelectedItems([]);
					onChange?.([]);
				} else if (action === 'SELECT_ALL') {
					setSelectedItems(allItems!);
					onChange?.(allItems!);
				} else {
					throw new Error('Unknonw Action');
				}
			},
			[allItems, onChange]
		);

		const paperyStyle = React.useMemo(() => ({ width: containerDimensions.width }), [
			containerDimensions.width,
		]);
		const boxStyle = React.useMemo(() => ({ boxShadow: 3 }), []);

		const inputProps = React.useMemo(
			() => ({
				inputComponent: InputWithChips,
				inputProps: {
					selectedItems: selectedItems,
					handleFocus,
					onClick: handleSelect,
					onChange: handleQuery,
					valueToLabelDictionary,
					value: !allItems ? '...lade' : query,
					disabled: !allItems || disabled,
				},
			}),
			[
				selectedItems,
				handleFocus,
				handleSelect,
				handleQuery,
				valueToLabelDictionary,
				allItems,
				query,
				disabled,
			]
		);

		React.useEffect(() => {
			if (disabled) {
				setErrorMessage(null);
			}
		}, [disabled]);

		const handleValidate = React.useCallback(() => {
			const invalid = selectedItems.length === 0;
			if (invalid) {
				setErrorMessage('Bitte wählen');
			} else {
				setErrorMessage(null);
			}
			return invalid;
		}, [selectedItems.length]);

		React.useImperativeHandle(ref, () => ({
			validate: handleValidate,
		}));

		const error = React.useMemo(() => Boolean(errorMessage), [errorMessage]);

		return (
			<>
				{open ? <ClickawayDiv onClick={handleClickAway} /> : null}
				<Box flex={flex} m={m} width={width}>
					<Box>
						<FormControl fullWidth variant={variant} error={error}>
							{multi ? (
								<StyledInputLabel variant={variant} shrink disabled={disabled}>
									{label}
								</StyledInputLabel>
							) : null}
							{multi ? (
								<TextField
									label=" "
									// @ts-ignore
									InputProps={inputProps}
									variant="outlined"
									ref={textFieldRef}
									focused={focus}
									error={error}
									disabled={!allItems || disabled}
								/>
							) : (
								<TextField
									label={label}
									value={!allItems ? '...lade' : selectedItems[0]?.label || query || ''}
									onChange={handleQuery}
									variant="outlined"
									onFocus={handleFocus}
									ref={textFieldRef}
									focused={focus}
									error={error}
									disabled={!allItems || disabled}
								/>
							)}

							<Popper
								id={open && Boolean(anchorEl) ? popperId : undefined}
								open={open}
								anchorEl={anchorEl}
								transition
								{...popperStyle}
							>
								{({ TransitionProps }) => (
									<Fade {...TransitionProps}>
										<Box sx={boxStyle}>
											{!allItems ? (
												<Paper sx={paperyStyle}>
													<Box
														height="10rem"
														display="flex"
														flexDirection="row"
														alignItems="center"
														justifyContent="center"
													>
														<CircularProgress />
													</Box>
												</Paper>
											) : (
												<Paper sx={paperyStyle}>
													<VirtualizedList
														selectedItems={selectedItems}
														items={onlyShowInternalSearchResults ? internalSearchResults : allItems}
														onChange={handleSelect}
														controls={controls}
														onHandleControl={onHandleControl}
														showActions={showActions && multi}
														onHandleAction={handleAction}
													/>
												</Paper>
											)}
										</Box>
									</Fade>
								)}
							</Popper>
							{!disabled && error ? <FormHelperText>{errorMessage}</FormHelperText> : null}
						</FormControl>
					</Box>
				</Box>
			</>
		);
	}
);

export default SearchSelectField;
