import React from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';
import { Brand } from '@cinuru/utils/theme';
import { DashboardEmailHeaderProps as HeaderProps } from '@cinuru/emails/src/components/DashboardEmailComponents/DashboardEmailHeader';

import ApiRenderedComponent from './ApiRenderedComponent';
import { transformElement, createDefaultHeaderText } from '../utils/emailConfigurationHelpers';

import { ColorController } from '../utils/allFormControlComponents';
import { HeaderData } from '../../../utils/campaignEmailContent';

import TextController from './FormControls/TextController';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

const HeaderPreview = ({
	id,
	element,
	brand,
}: {
	id: string;
	element: HeaderData;
	brand: Brand;
}) => {
	const componentProps: HeaderProps = React.useMemo(
		() => ({
			...element,
			children:
				!element.children && !element.textChangedByCustomer
					? createDefaultHeaderText()
					: element.children,
		}),
		[element]
	);
	return (
		<ApiRenderedComponent
			id={id}
			brand={brand}
			componentName="DashboardEmailHeader"
			componentProps={componentProps}
		/>
	);
};
export const HeaderEditor = React.memo(
	({
		element,
		updateElement,
		brand,
		spacerHeight,
	}: {
		element: HeaderData;
		updateElement: (headerData) => void;
		brand: Brand;
		spacerHeight?: string;
	}) => {
		const handleChangeController = React.useCallback(
			(path: string, newValue: any) => {
				const pathArray = path.split('/');
				const newElement = transformElement(element, pathArray, newValue);
				updateElement({
					...(newElement as HeaderData),
					textChangedByCustomer: !pathArray.includes('backgroundColor') ? true : false,
				});
			},
			[element, updateElement]
		);

		return (
			<Wrapper>
				<Wrapper className="wrapper">
					<TextController
						type="HEADER"
						value={
							!element.children && !element.textChangedByCustomer
								? createDefaultHeaderText()
								: element.children
						}
						onChange={handleChangeController}
						path="children"
					/>
					<Box height={spacerHeight} />
					<ColorController
						value={element.backgroundColor}
						brand={brand}
						onChange={handleChangeController}
						path="backgroundColor"
						label="Hintergrundfarbe"
						fallBackColor="background0"
					/>
				</Wrapper>
			</Wrapper>
		);
	}
);

export const HeaderDefaultParams: HeaderData = {
	type: 'HEADER',
	children: createDefaultHeaderText(),
	textChangedByCustomer: false,
	index: -1,
};

export default React.memo(HeaderPreview);
