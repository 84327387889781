import React from 'react';
import templateData from '../json/templateData.json';

import { Brand } from '@cinuru/utils/theme';
import { RecommendationSectionProps as RecommendationsProps } from '@cinuru/emails/src/components/DashboardEmailComponents/RecommendationSection';

import {
	stripValueFromDimensionObject,
	transformElement,
} from '../utils/emailConfigurationHelpers';
import ApiRenderedComponent from './ApiRenderedComponent';
import {
	ColorController,
	CheckBoxController,
	SelectController,
	TextEditorController,
} from '../utils/allFormControlComponents';
import { useElementsWithUpdatedRecommendations } from '../utils/updateTrueElements';
import { Campaign } from '../../../utils/campaign';
import { EmailElement, RecommendationsData } from '../../../utils/campaignEmailContent';
import SizeController from './FormControls/SizeController';
import ExpandableContents from '../../../components/ExpandableContents';

const { recommendedTemplateMovies, templateCinemas } = templateData;
const RecommendationsPreview = ({
	id,
	element,
	elements,
	brand,
	campaign,
}: {
	id: string;
	element: RecommendationsData;
	elements: EmailElement[];
	brand: Brand;
	campaign: Campaign;
}) => {
	const elementsWithUpdatedRecommendations = useElementsWithUpdatedRecommendations({
		elements,
		campaign,
	}).filter((el) => el.key === element.key)[0] as RecommendationsData;

	const componentProps: RecommendationsProps = React.useMemo(
		() => ({
			...element,
			movieConfiguration: stripValueFromDimensionObject(
				element.movieConfiguration
			) as RecommendationsProps['movieConfiguration'],
			recommendedMovies: elementsWithUpdatedRecommendations.recommendedMovies,
			cinemas: elementsWithUpdatedRecommendations.cinemas,
		}),
		[
			element,
			elementsWithUpdatedRecommendations.cinemas,
			elementsWithUpdatedRecommendations.recommendedMovies,
		]
	);

	return (
		<ApiRenderedComponent
			id={id}
			brand={brand}
			componentName="RecommendationSection"
			componentProps={componentProps}
		/>
	);
};

export const RecommendationsEditor = React.memo(
	({
		element,
		updateElement,
		brand,
	}: {
		element: RecommendationsData;
		updateElement: (recommendationsData: RecommendationsData) => void;
		brand: Brand;
	}) => {
		const handleChangeController = React.useCallback(
			(path: string, newValue: any) => {
				const pathArray = path.split('/');
				const newElement = transformElement(element, pathArray, newValue);
				updateElement(newElement as RecommendationsData);
			},
			[element, updateElement]
		);

		const contents = React.useMemo(
			() =>
				!element.showInitializer
					? [
							{
								label: 'Allgemeine Einstellungen',
								items: [
									<ColorController
										key="1"
										value={element.backgroundColor}
										brand={brand}
										onChange={handleChangeController}
										path="backgroundColor"
										label="Hintergrundfarbe"
										fallBackColor="background0"
									/>,

									<SelectController
										key="2"
										type="NUMBER_OF_MOVIES"
										value={element.movieAmount}
										onChange={handleChangeController}
										path="movieAmount"
									/>,
								],
							},
							{
								label: 'Hauptüberschrift und -beschreibung',
								items: [
									<TextEditorController
										key="1"
										id={`recommendation_header_${element.key}`}
										value={element.description?.text}
										onChange={handleChangeController}
										path="description/text"
									/>,
									<SelectController
										key="2"
										type="FONT_FAMILY"
										value={element.description?.family}
										onChange={handleChangeController}
										path="description/family"
									/>,
									<SelectController
										key="3"
										type="FONT_SIZE"
										value={element.description?.size}
										onChange={handleChangeController}
										path="description/size"
									/>,
									<SelectController
										key="4"
										type="FONT_ALIGN"
										value={element.description?.align}
										onChange={handleChangeController}
										path="description/align"
									/>,
									<ColorController
										key="5"
										value={element.description?.color}
										brand={brand}
										onChange={handleChangeController}
										path="description/color"
										label="Schriftfarbe"
										fallBackColor="neutral0"
									/>,
								],
							},
							{
								label: 'Filmplakat',
								items: [
									<SizeController
										key="1"
										type="WIDTH"
										value={element.movieConfiguration?.poster?.width}
										onChange={handleChangeController}
										path="movieConfiguration/poster/width"
									/>,
									<SizeController
										key="2"
										type="BORDER_RADIUS"
										value={element.movieConfiguration?.poster?.borderRadius}
										onChange={handleChangeController}
										path="movieConfiguration/poster/borderRadius"
									/>,
									<CheckBoxController
										key="3"
										type="SHADOW"
										value={element.movieConfiguration?.poster?.shadow}
										onChange={handleChangeController}
										path="movieConfiguration/poster/shadow"
									/>,
								],
							},
							{
								label: 'Filmtitel',
								items: [
									<SelectController
										key="1"
										type="MARKDOWN_TYPE"
										value={element.movieConfiguration?.movieTitle?.textType}
										onChange={handleChangeController}
										path="movieConfiguration/movieTitle/textType"
									/>,
									<SelectController
										key="2"
										type="FONT_FAMILY"
										value={element.movieConfiguration?.movieTitle?.family}
										onChange={handleChangeController}
										path="movieConfiguration/movieTitle/family"
									/>,
									<SelectController
										key="3"
										type="FONT_SIZE"
										value={element.movieConfiguration?.movieTitle?.size}
										onChange={handleChangeController}
										path="movieConfiguration/movieTitle/size"
									/>,
									<SelectController
										key="4"
										type="FONT_ALIGN"
										value={element.movieConfiguration?.movieTitle?.align}
										onChange={handleChangeController}
										path="movieConfiguration/movieTitle/align"
									/>,
									<ColorController
										key="5"
										value={element.movieConfiguration?.movieTitle?.color}
										brand={brand}
										onChange={handleChangeController}
										path="movieConfiguration/movieTitle/color"
										label="Schriftfarbe"
										fallBackColor="neutral0"
									/>,
									<CheckBoxController
										key="6"
										type="BOLDNESS"
										value={element.movieConfiguration?.movieTitle?.bold}
										onChange={handleChangeController}
										path="movieConfiguration/movieTitle/bold"
									/>,
								],
							},
							{
								label: 'Weitere Filminfos',
								items: [
									<SelectController
										key="1"
										type="MARKDOWN_TYPE"
										value={element.movieConfiguration?.movieData?.textType}
										onChange={handleChangeController}
										path="movieConfiguration/movieData/textType"
									/>,
									<SelectController
										key="2 "
										type="FONT_FAMILY"
										value={element.movieConfiguration?.movieData?.family}
										onChange={handleChangeController}
										path="movieConfiguration/movieData/family"
									/>,
									<SelectController
										key="3"
										type="FONT_SIZE"
										value={element.movieConfiguration?.movieData?.size}
										onChange={handleChangeController}
										path="movieConfiguration/movieData/size"
									/>,
									<SelectController
										key="4"
										type="FONT_ALIGN"
										value={element.movieConfiguration?.movieData?.align}
										onChange={handleChangeController}
										path="movieConfiguration/movieData/align"
									/>,
									<ColorController
										key="4"
										value={element.movieConfiguration?.movieData?.color}
										brand={brand}
										onChange={handleChangeController}
										path="movieConfiguration/movieData/color"
										label="Schriftfarbe"
										fallBackColor="neutral0"
									/>,
									<CheckBoxController
										key="5"
										type="BOLDNESS"
										value={element.movieConfiguration?.movieData?.bold}
										onChange={handleChangeController}
										path="movieConfiguration/movieData/bold"
									/>,
								],
							},
							{
								label: 'Filmsynopsis',
								items: [
									<SelectController
										key="1"
										type="MARKDOWN_TYPE"
										value={element.movieConfiguration?.movieSynopsis?.textType}
										onChange={handleChangeController}
										path="movieConfiguration/movieSynopsis/textType"
									/>,
									<SelectController
										key="2"
										type="FONT_FAMILY"
										value={element.movieConfiguration?.movieSynopsis?.family}
										onChange={handleChangeController}
										path="movieConfiguration/movieSynopsis/family"
									/>,
									<SelectController
										key="3"
										type="FONT_SIZE"
										value={element.movieConfiguration?.movieSynopsis?.size}
										onChange={handleChangeController}
										path="movieConfiguration/movieSynopsis/size"
									/>,
									<SelectController
										key="4"
										type="FONT_ALIGN"
										value={element.movieConfiguration?.movieSynopsis?.align}
										onChange={handleChangeController}
										path="movieConfiguration/movieSynopsis/align"
									/>,
									<ColorController
										key="5"
										value={element.movieConfiguration?.movieSynopsis?.color}
										brand={brand}
										onChange={handleChangeController}
										path="movieConfiguration/movieSynopsis/color"
										label="Schriftfarbe"
										fallBackColor="neutral0"
									/>,
									<CheckBoxController
										key="6"
										type="BOLDNESS"
										value={element.movieConfiguration?.movieSynopsis?.bold}
										onChange={handleChangeController}
										path="movieConfiguration/movieSynopsis/bold"
									/>,
								],
							},
							{
								label: 'Button',
								items: [
									<TextEditorController
										key="1"
										id={`button1_description_${element.key}`}
										value={element.buttonConfiguration?.text}
										onChange={handleChangeController}
										path="buttonConfiguration/text"
									/>,
									<CheckBoxController
										key="2"
										type="SHADOW"
										value={element.buttonConfiguration?.shadow}
										onChange={handleChangeController}
										path="buttonConfiguration/shadow"
									/>,
									<SelectController
										key="3"
										type="FONT_SIZE"
										value={element.buttonConfiguration?.size}
										onChange={handleChangeController}
										path="buttonConfiguration/size"
									/>,
									<SelectController
										key="4"
										type="FONT_ALIGN"
										value={element.buttonConfiguration?.align}
										onChange={handleChangeController}
										path="buttonConfiguration/align"
									>
										Buttonposition
									</SelectController>,
									<ColorController
										key="5"
										value={element.buttonConfiguration?.fontColor}
										brand={brand}
										onChange={handleChangeController}
										path="buttonConfiguration/fontColor"
										label="Schriftfarbe"
										fallBackColor="neutral0"
									/>,
									<ColorController
										key="6"
										value={element.buttonConfiguration?.color}
										brand={brand}
										onChange={handleChangeController}
										path="buttonConfiguration/color"
										label="Buttonfarbe"
										fallBackColor="background0"
									/>,
								],
							},
							{
								label: 'Spielzeitenabschnitt: Überschrift und Beschreibung',
								items: [
									<TextEditorController
										key="1"
										id={`showtimes_description_${element.key}`}
										value={element.screeningDescription?.text}
										onChange={handleChangeController}
										path="screeningDescription/text"
									/>,
									<SelectController
										key="2"
										type="FONT_FAMILY"
										value={element.screeningDescription?.family}
										onChange={handleChangeController}
										path="screeningDescription/family"
									/>,
									<SelectController
										key="3"
										type="FONT_SIZE"
										value={element.screeningDescription?.size}
										onChange={handleChangeController}
										path="screeningDescription/size"
									/>,
									<SelectController
										key="4"
										type="FONT_ALIGN"
										value={element.screeningDescription?.align}
										onChange={handleChangeController}
										path="screeningDescription/align"
									/>,
									<ColorController
										key="5"
										value={element.screeningDescription?.color}
										brand={brand}
										onChange={handleChangeController}
										path="screeningDescription/color"
										label="Schriftfarbe"
										fallBackColor="neutral0"
									/>,
								],
							},
							{
								label: 'Spielzeitenabschnitt: Kinonamen',
								items: [
									<SelectController
										key="1"
										type="MARKDOWN_TYPE"
										value={element.screeningConfiguration?.cinemaName?.textType}
										onChange={handleChangeController}
										path="screeningConfiguration/cinemaName/textType"
									/>,
									<SelectController
										key="2"
										type="FONT_FAMILY"
										value={element.screeningConfiguration?.cinemaName?.family}
										onChange={handleChangeController}
										path="screeningConfiguration/cinemaName/family"
									/>,
									<SelectController
										key="3"
										type="FONT_SIZE"
										value={element.screeningConfiguration?.cinemaName?.size}
										onChange={handleChangeController}
										path="screeningConfiguration/cinemaName/size"
									/>,
									<SelectController
										key="4"
										type="FONT_ALIGN"
										value={element.screeningConfiguration?.cinemaName?.align}
										onChange={handleChangeController}
										path="screeningConfiguration/cinemaName/align"
									/>,
									<ColorController
										key="5"
										value={element.screeningConfiguration?.cinemaName?.color}
										brand={brand}
										onChange={handleChangeController}
										path="screeningConfiguration/cinemaName/color"
										label="Schriftfarbe"
										fallBackColor="neutral0"
									/>,
									<CheckBoxController
										key="6"
										type="BOLDNESS"
										value={element.screeningConfiguration?.cinemaName?.bold}
										onChange={handleChangeController}
										path="screeningConfiguration/cinemaName/bold"
									/>,
								],
							},
							{
								label: 'Spielzeitenabschnitt: Datum und Uhrzeit',
								items: [
									<SelectController
										key="1"
										type="FONT_SIZE"
										value={element.screeningConfiguration?.screeningData?.size}
										onChange={handleChangeController}
										path="screeningConfiguration/screeningData/size"
									/>,
									<ColorController
										key="2"
										value={element.screeningConfiguration?.screeningData?.color}
										brand={brand}
										onChange={handleChangeController}
										path="screeningConfiguration/screeningData/color"
										label="Schriftfarbe"
										fallBackColor="neutral0"
									/>,
									<ColorController
										key="3"
										value={element.screeningConfiguration?.screeningData?.backgroundColor}
										brand={brand}
										onChange={handleChangeController}
										path="screeningConfiguration/screeningData/backgroundColor"
										label="Spielzeitenhintergrundfarbe"
										fallBackColor="background0"
									/>,
									<CheckBoxController
										key="4"
										type="BOLDNESS"
										value={element.screeningConfiguration?.screeningData?.bold}
										onChange={handleChangeController}
										path="screeningConfiguration/screeningData/bold"
									/>,
								],
							},
					  ]
					: [
							{
								label: 'Allgemeine Einstellungen',
								items: [
									<ColorController
										key="1"
										value={element.backgroundColor}
										brand={brand}
										onChange={handleChangeController}
										path="backgroundColor"
										label="Hintergrundfarbe"
										fallBackColor="background0"
									/>,
								],
							},
							{
								label: 'Hauptüberschrift und -beschreibung',
								items: [
									<TextEditorController
										key="1"
										id={`quiz_header_${element.key}`}
										value={element.initializerConfiguration?.description?.text}
										onChange={handleChangeController}
										path="initializerConfiguration/description/text"
									/>,
									<SelectController
										key="2"
										type="FONT_FAMILY"
										value={element.initializerConfiguration?.description?.family}
										onChange={handleChangeController}
										path="initializerConfiguration/description/family"
									/>,
									<SelectController
										key="3"
										type="FONT_SIZE"
										value={element.initializerConfiguration?.description?.size}
										onChange={handleChangeController}
										path="initializerConfiguration/description/size"
									/>,
									<SelectController
										key="4"
										type="FONT_ALIGN"
										value={element.initializerConfiguration?.description?.align}
										onChange={handleChangeController}
										path="initializerConfiguration/description/align"
									/>,
									<ColorController
										key="5"
										value={element.initializerConfiguration?.description?.color}
										brand={brand}
										onChange={handleChangeController}
										path="initializerConfiguration/description/color"
										label="Schriftfarbe"
										fallBackColor="neutral0"
									/>,
								],
							},
							{
								label: 'Beschreibungstext',
								items: [
									<TextEditorController
										key="1"
										id={`quiz_description_${element.key}`}
										value={
											element.initializerConfiguration?.movieConfiguration?.movieSynopsis?.text
										}
										onChange={handleChangeController}
										path="initializerConfiguration/movieConfiguration/movieSynopsis/text"
									/>,
									<SelectController
										key="2"
										type="FONT_FAMILY"
										value={
											element.initializerConfiguration?.movieConfiguration?.movieSynopsis?.family
										}
										onChange={handleChangeController}
										path="initializerConfiguration/movieConfiguration/movieSynopsis/family"
									/>,
									<SelectController
										key="3"
										type="FONT_SIZE"
										value={
											element.initializerConfiguration?.movieConfiguration?.movieSynopsis?.size
										}
										onChange={handleChangeController}
										path="initializerConfiguration/movieConfiguration/movieSynopsis/size"
									/>,
									<SelectController
										key="4"
										type="FONT_ALIGN"
										value={
											element.initializerConfiguration?.movieConfiguration?.movieSynopsis?.align
										}
										onChange={handleChangeController}
										path="initializerConfiguration/movieConfiguration/movieSynopsis/align"
									/>,
									<ColorController
										key="5"
										value={
											element.initializerConfiguration?.movieConfiguration?.movieSynopsis?.color
										}
										brand={brand}
										onChange={handleChangeController}
										path="initializerConfiguration/movieConfiguration/movieSynopsis/color"
										label="Schriftfarbe"
										fallBackColor="neutral0"
									/>,
								],
							},
							{
								label: 'Button',
								items: [
									<TextEditorController
										key="1"
										id={`button_description_${element.key}`}
										value={element.initializerConfiguration?.buttonConfiguration?.text}
										onChange={handleChangeController}
										path="initializerConfiguration/buttonConfiguration/text"
									/>,
									<CheckBoxController
										key="2"
										type="SHADOW"
										value={element.initializerConfiguration?.buttonConfiguration?.shadow}
										onChange={handleChangeController}
										path="initializerConfiguration/buttonConfiguration/shadow"
									/>,
									<SelectController
										key="3"
										type="FONT_SIZE"
										value={element.initializerConfiguration?.buttonConfiguration?.size}
										onChange={handleChangeController}
										path="initializerConfiguration/buttonConfiguration/size"
									/>,
									<SelectController
										key="4"
										type="FONT_ALIGN"
										value={element.initializerConfiguration?.buttonConfiguration?.align}
										onChange={handleChangeController}
										path="initializerConfiguration/buttonConfiguration/align"
									>
										Buttonposition
									</SelectController>,
									<ColorController
										key="5"
										value={element.initializerConfiguration?.buttonConfiguration?.fontColor}
										brand={brand}
										onChange={handleChangeController}
										path="initializerConfiguration/buttonConfiguration/fontColor"
										label="Schriftfarbe"
										fallBackColor="neutral0"
									/>,
									<ColorController
										key="6"
										value={element.initializerConfiguration?.buttonConfiguration?.color}
										brand={brand}
										onChange={handleChangeController}
										path="initializerConfiguration/buttonConfiguration/color"
										label="Buttonfarbe"
										fallBackColor="background0"
									/>,
								],
							},
					  ],
			[
				brand,
				element.backgroundColor,
				element.buttonConfiguration?.align,
				element.buttonConfiguration?.color,
				element.buttonConfiguration?.fontColor,
				element.buttonConfiguration?.shadow,
				element.buttonConfiguration?.size,
				element.buttonConfiguration?.text,
				element.description?.align,
				element.description?.color,
				element.description?.family,
				element.description?.size,
				element.description?.text,
				element.key,
				element.movieAmount,
				element.movieConfiguration?.movieData?.align,
				element.movieConfiguration?.movieData?.bold,
				element.movieConfiguration?.movieData?.color,
				element.movieConfiguration?.movieData?.family,
				element.movieConfiguration?.movieData?.size,
				element.movieConfiguration?.movieData?.textType,
				element.movieConfiguration?.movieSynopsis?.align,
				element.movieConfiguration?.movieSynopsis?.bold,
				element.movieConfiguration?.movieSynopsis?.color,
				element.movieConfiguration?.movieSynopsis?.family,
				element.movieConfiguration?.movieSynopsis?.size,
				element.movieConfiguration?.movieSynopsis?.textType,
				element.movieConfiguration?.movieTitle?.align,
				element.movieConfiguration?.movieTitle?.bold,
				element.movieConfiguration?.movieTitle?.color,
				element.movieConfiguration?.movieTitle?.family,
				element.movieConfiguration?.movieTitle?.size,
				element.movieConfiguration?.movieTitle?.textType,
				element.movieConfiguration?.poster?.borderRadius,
				element.movieConfiguration?.poster?.shadow,
				element.movieConfiguration?.poster?.width,
				element.screeningConfiguration?.cinemaName?.align,
				element.screeningConfiguration?.cinemaName?.bold,
				element.screeningConfiguration?.cinemaName?.color,
				element.screeningConfiguration?.cinemaName?.family,
				element.screeningConfiguration?.cinemaName?.size,
				element.screeningConfiguration?.cinemaName?.textType,
				element.screeningConfiguration?.screeningData?.backgroundColor,
				element.screeningConfiguration?.screeningData?.bold,
				element.screeningConfiguration?.screeningData?.color,
				element.screeningConfiguration?.screeningData?.size,
				element.screeningDescription?.align,
				element.screeningDescription?.color,
				element.screeningDescription?.family,
				element.screeningDescription?.size,
				element.screeningDescription?.text,
				element.showInitializer,
				handleChangeController,
				element.initializerConfiguration?.buttonConfiguration?.align,
				element.initializerConfiguration?.buttonConfiguration?.color,
				element.initializerConfiguration?.buttonConfiguration?.fontColor,
				element.initializerConfiguration?.buttonConfiguration?.shadow,
				element.initializerConfiguration?.buttonConfiguration?.size,
				element.initializerConfiguration?.buttonConfiguration?.text,
				element.initializerConfiguration?.description?.align,
				element.initializerConfiguration?.description?.color,
				element.initializerConfiguration?.description?.family,
				element.initializerConfiguration?.description?.size,
				element.initializerConfiguration?.description?.text,
				element.initializerConfiguration?.movieConfiguration?.movieSynopsis?.align,
				element.initializerConfiguration?.movieConfiguration?.movieSynopsis?.color,
				element.initializerConfiguration?.movieConfiguration?.movieSynopsis?.family,
				element.initializerConfiguration?.movieConfiguration?.movieSynopsis?.size,
				element.initializerConfiguration?.movieConfiguration?.movieSynopsis?.text,
			]
		);

		return (
			<>
				<CheckBoxController
					type="QUIZ"
					value={element.showInitializer}
					onChange={handleChangeController}
					path="showInitializer"
					m="2rem 0 1rem"
				/>
				<ExpandableContents
					width="100%"
					contents={contents}
					itemMargin="2rem 0 1rem"
					expandableMargin="1rem 0"
				/>
			</>
		);
	}
);

export const RecommendationsDefaultParams: RecommendationsData = {
	type: 'RECOMMENDATIONS',
	recommendedMovies: recommendedTemplateMovies,
	cinemas: templateCinemas,
	movieAmount: 3,
	showInitializer: false,
	description: {
		textType: 'PARAGRAPH',
		text: '# Deine Filmempfehlung der Woche:',
		color: 'accent0',
	},
	screeningDescription: {
		textType: 'PARAGRAPH',
		size: 'l',
		color: 'accent0',
		bold: true,
		text: `-Spielzeiten in deinen Kinos:`,
	},
	movieConfiguration: {
		poster: { shadow: true, width: '300px' },
		movieTitle: {
			textType: 'HEADING',
			bold: true,
			size: 'l',
			color: 'accent0',
		},
		movieData: {
			textType: 'PARAGRAPH',
			bold: true,
			size: 'm',
		},
		movieSynopsis: {
			textType: 'PARAGRAPH',
		},
	},
	screeningConfiguration: {
		cinemaName: {
			textType: 'PARAGRAPH',
			bold: true,
		},
		screeningData: {
			textType: 'PARAGRAPH',
			backgroundColor: 'neutral4',
			bold: true,
		},
	},
	buttonConfiguration: {
		text: 'Mehr zum Film',
		color: 'accent0',
		fontColor: 'background0',
		shadow: true,
	},
	initializerConfiguration: {
		description: {
			textType: 'PARAGRAPH',
			text: '# Deine Filmempfehlung der Woche:',
			color: 'accent0',
		},
		movieConfiguration: {
			poster: {},
			movieSynopsis: {
				textType: 'PARAGRAPH',
				text:
					'Leider wissen wir noch zu wenig über deinen Filmgeschmack. Nimm einmalig an unserem Filmbewertungsquiz teil und erhalte künftig individuell auf dich zugeschnittene Filmempfehlungen!',
			},
			movieData: {
				textType: 'PARAGRAPH',
			},
			movieTitle: {
				textType: 'PARAGRAPH',
			},
		},
		buttonConfiguration: {
			text: 'Zum Quiz',
			color: 'accent0',
			fontColor: 'background0',
			shadow: true,
		},
	},
	index: 0,
	borderRadiusWrapper: { top: true, bottom: true },
};

export default React.memo(RecommendationsPreview);
