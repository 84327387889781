import React from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';
import { Brand, createTheme } from '@cinuru/utils/theme';
import { ButtonProps } from '@cinuru/emails/src/components/DashboardEmailComponents/Button';

import ApiRenderedComponent from './ApiRenderedComponent';
import {
	colorsToChooseFrom,
	stripValueFromDimensionObject,
	transformElement,
} from '../utils/emailConfigurationHelpers';
import { ColorController, TextEditorController } from '../utils/allFormControlComponents';
import { ButtonData } from '../../../utils/campaignEmailContent';
import SizeController from './FormControls/SizeController';
import SelectController from './FormControls/SelectController';
import CheckBoxController from './FormControls/CheckBoxController';
import TextController from './FormControls/TextController';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

const ButtonPreview = ({
	id,
	element,
	brand,
}: {
	id: string;
	element: ButtonData;
	brand: Brand;
}) => {
	const componentProps: ButtonProps = React.useMemo(() => stripValueFromDimensionObject(element), [
		element,
	]);
	return (
		<ApiRenderedComponent
			id={id}
			brand={brand}
			componentName="Button"
			componentProps={componentProps}
		/>
	);
};

export const ButtonEditor = React.memo(
	({
		element,
		updateElement,
		brand,
		spacerHeight,
	}: {
		element: ButtonData;
		updateElement: (buttonData: ButtonData) => void;
		brand: Brand;
		spacerHeight?: string;
	}) => {
		const theme = createTheme(brand, { darkMode: false });
		const colors = colorsToChooseFrom(theme.colors);

		const handleChangeController = React.useCallback(
			(path: string, newValue: any) => {
				const pathArray = path.split('/');
				const newElement = transformElement(element, pathArray, newValue);

				updateElement(newElement as ButtonData);
			},
			[element, updateElement]
		);

		return (
			<Wrapper>
				<TextController
					type="LINK"
					value={element.href}
					onChange={handleChangeController}
					path="href"
				/>
				<Box height={spacerHeight} />
				<TextEditorController
					id={`Button_${element.key}`}
					value={element.text || ''}
					onChange={handleChangeController}
					path="text"
				/>
				<Box height={spacerHeight} />
				<SizeController
					type="WIDTH"
					value={element.width}
					onChange={handleChangeController}
					path="width"
					label="Buttonbreite"
				/>
				<Box height={spacerHeight} />
				<CheckBoxController
					type="FULL_WIDTH"
					value={element.fullWidth}
					onChange={handleChangeController}
					path="fullWidth"
				/>
				<Box height={spacerHeight} />
				<CheckBoxController
					type="SHADOW"
					value={element.shadow}
					onChange={handleChangeController}
					path="shadow"
				/>
				<Box height={spacerHeight} />
				<SelectController
					type="FONT_SIZE"
					value={element.size}
					onChange={handleChangeController}
					path="size"
				/>
				<Box height={spacerHeight} />
				<SelectController
					type="FONT_ALIGN"
					value={element.align}
					onChange={handleChangeController}
					path="align"
				>
					Buttonposition
				</SelectController>
				<Box height={spacerHeight} />
				<ColorController
					value={element.fontColor}
					brand={brand}
					onChange={handleChangeController}
					path="fontColor"
					label="Schriftfarbe"
					fallBackColor="neutral0"
				/>
				<Box height={spacerHeight} />
				<ColorController
					value={element.color}
					brand={brand}
					onChange={handleChangeController}
					path="color"
					label="Buttonfarbe"
					fallBackColor="background0"
				/>
				<Box height={spacerHeight} />
				<ColorController
					value={element.backgroundColor}
					brand={brand}
					onChange={handleChangeController}
					path="backgroundColor"
					label="Hintergrundfarbe"
					fallBackColor="background0"
				/>
			</Wrapper>
		);
	}
);

export const ButtonDefaultParams: ButtonData = {
	type: 'BUTTON',
	shadow: true,
	color: 'accent0',
	fontColor: 'background0',
	index: 0,
	text: `Hier geht's zum Link`,
	borderRadiusWrapper: { top: true, bottom: true },
};

export default React.memo(ButtonPreview);
