import React from 'react';

import ActiveWrapper from './ActiveWrapper';
import { ClusterPropName } from '../TargetGroupCluster';
import SearchSelectField, {
	SearchSelectFieldRef,
	SelectFieldItem as _SelectFieldItem,
} from '../../../components/SearchSelectField';
import SearchApiSelectField, {
	SearchApiSelectFieldRef,
} from '../../../components/SearchApiSelectField';
import { scrollIntoView } from '../../../utils/dimensions';

export type SelectFieldItem = _SelectFieldItem;

const defaultProps = {
	label: 'Suchen',
	flex: '1',
	multi: true,
};

export type GeneralSearchFilterRef = {
	validate: () => boolean;
	scrollIntoView: () => void;
};

const SearchSelectFilter = React.forwardRef<
	GeneralSearchFilterRef,
	{
		defaultItems: SelectFieldItem[];
		allItems?: SelectFieldItem[];
		active?: boolean;
		onChange: (clusterPropName: ClusterPropName, selectedItems: SelectFieldItem[]) => void;
		showFilter: boolean;
		clusterPropName: ClusterPropName;
		onChangeActivity: (clusterPropName: ClusterPropName, value: boolean) => void;
		label: string;
		type?: 'MOVIE' | 'CAST_OR_CREW_MEMBER';
		disabled?: boolean;
	}
>(
	(
		{
			defaultItems,
			allItems,
			active,
			onChange,
			showFilter,
			clusterPropName,
			onChangeActivity,
			label,
			type,
			disabled,
		},
		ref
	): JSX.Element | null => {
		const handleChange = React.useCallback(
			(selectedItems: SelectFieldItem[]) => {
				onChange?.(clusterPropName, selectedItems);
			},
			[clusterPropName, onChange]
		);

		const handleToggleActivity = React.useCallback(() => {
			onChangeActivity(clusterPropName, !active);
		}, [onChangeActivity, clusterPropName, active]);

		const wrapperRef = React.useRef<HTMLDivElement>(null);
		const searchSelectFieldRef = React.useRef<SearchSelectFieldRef>(null);
		const searchApiSelectFieldRef = React.useRef<SearchApiSelectFieldRef>(null);

		const handleValidate = React.useCallback(() => {
			const errors = !active
				? []
				: [searchSelectFieldRef, searchApiSelectFieldRef].map((r) => r.current?.validate());
			const invalid = errors.some(Boolean);
			return invalid;
		}, [active]);

		React.useImperativeHandle(
			ref,
			() => ({
				validate: handleValidate,
				scrollIntoView: () => scrollIntoView(wrapperRef),
			}),
			[handleValidate]
		);

		return showFilter ? (
			<div ref={wrapperRef}>
				<ActiveWrapper
					label={label}
					active={active}
					onHandleToggleActivity={handleToggleActivity}
					p="2rem 2rem 1rem"
					disabled={disabled}
				>
					{!type ? (
						<SearchSelectField
							allItems={allItems}
							disabled={disabled || !active}
							onChange={handleChange}
							defaultItems={defaultItems}
							ref={searchSelectFieldRef}
							{...defaultProps}
						/>
					) : type === 'CAST_OR_CREW_MEMBER' || type === 'MOVIE' ? (
						<SearchApiSelectField
							disabled={disabled || !active}
							onChange={handleChange}
							defaultItems={defaultItems}
							type={type}
							ref={searchApiSelectFieldRef}
							{...defaultProps}
						/>
					) : null}
				</ActiveWrapper>
			</div>
		) : null;
	}
);

export default React.memo(SearchSelectFilter);
