import React from 'react';
import styled from 'styled-components';
import { Box, InputAdornment, TextField as MuiTextField } from '@mui/material';
import type { BaseTextFieldProps } from '@mui/material/TextField';

import IconButton, { IconName } from './IconButton';
import Tooltip from '../components/Tooltip';

// this is a HOC that allows to wrap a Component to show a loading TextField if necessary
// something did not quite work

// export const withLoading = <T extends Record<string, any>, G extends Record<string, any>>(
// 	Component: React.ComponentType<T & React.RefAttributes<G>>
// ): React.FC<
// 	{
// 		isLoading?: boolean;
// 	} & T
// > => {
// 	return ({ isLoading, ...rest }) => {
// 		return isLoading ? (
// 			<TextField
// 				label={rest?.label}
// 				variant={rest?.variant}
// 				fullWidth
// 				disabled
// 				m={rest?.m}
// 				value="...lade"
// 			/>
// 		) : (
// 			<Component {...((rest as unknown) as T)} />
// 		);
// 	};
// };

const StyledIconButton = styled(IconButton)<{ $color?: string }>`
	color: ${(p) => p.$color};
`;

type TextFieldRef = Record<string, unknown>;

const TextField = React.forwardRef<
	TextFieldRef,
	{
		m?: string;
		p?: string;
		width?: string;
		startLabel?: string;
		startIconName?: IconName;
		startIconNameColor?: string;
		onClickStartIcon?: () => void;
		endLabel?: string;
		endIconName?: IconName;
		onChange?: (value: string) => void;
		flex?: boolean;
		toolTip?: string;
		isLoading?: boolean;
	} & BaseTextFieldProps
>(
	(
		{
			m,
			p,
			flex,
			width,
			startLabel,
			startIconName,
			startIconNameColor,
			onClickStartIcon,
			endLabel,
			endIconName,
			onChange,
			toolTip,
			isLoading,
			...rest
		},
		ref
	): JSX.Element => {
		const handleChange = React.useCallback(
			(event) => {
				const newValue = event?.target?.value;
				onChange?.(newValue);
			},
			[onChange]
		);

		const inputProps = React.useMemo(() => {
			return startIconName || startLabel || toolTip
				? {
						startAdornment: startLabel ? (
							<InputAdornment position="start">{startLabel}</InputAdornment>
						) : startIconName ? (
							<InputAdornment position="start">
								<StyledIconButton
									onClick={onClickStartIcon}
									iconName={startIconName}
									$color={startIconNameColor}
								/>
							</InputAdornment>
						) : toolTip ? (
							<InputAdornment position="start">
								<Tooltip width="40rem" text={toolTip} />
							</InputAdornment>
						) : null,
				  }
				: endIconName || endLabel
				? {
						endAdornment: endLabel ? (
							<InputAdornment position="start">{endLabel}</InputAdornment>
						) : endIconName ? (
							<InputAdornment position="start">
								<IconButton iconName={endIconName} />
							</InputAdornment>
						) : null,
				  }
				: undefined;
		}, [
			endIconName,
			endLabel,
			onClickStartIcon,
			startIconName,
			startIconNameColor,
			startLabel,
			toolTip,
		]);

		return (
			<Box m={m} p={p} flex={flex ? '1' : undefined} width={width} ref={ref}>
				<MuiTextField
					fullWidth
					onChange={handleChange}
					InputProps={inputProps}
					value={isLoading ? '...lade' : undefined}
					disabled={isLoading}
					type="text"
					{...rest}
				/>
			</Box>
		);
	}
);

export default React.memo(TextField);
