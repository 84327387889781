import React from 'react';
import { Box } from '@mui/material';
import styled from 'styled-components';
import { Brand } from '@cinuru/utils/theme';

import { transformElement } from '../utils/emailConfigurationHelpers';
import ApiRenderedComponent from './ApiRenderedComponent';
import {
	ColorController,
	SelectController,
	TextEditorController,
} from '../utils/allFormControlComponents';
import { MarkdownTextData } from '../../../utils/campaignEmailContent';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

const MarkdownTextPreview = ({
	id,
	element,
	brand,
}: {
	id: string;
	element: MarkdownTextData;
	brand: Brand;
}) => {
	return (
		<ApiRenderedComponent
			id={id}
			brand={brand}
			componentName="MarkdownText"
			componentProps={element}
		/>
	);
};

export const MarkdownTextEditor = React.memo(
	({
		element,
		updateElement,
		brand,
		spacerHeight,
	}: {
		element: MarkdownTextData;
		updateElement: (markdownTextData) => void;
		brand: Brand;
		spacerHeight?: string;
	}) => {
		const handleChangeController = React.useCallback(
			(path: string, newValue: any) => {
				const pathArray = path.split('/');
				const newElement = transformElement(element, pathArray, newValue);
				updateElement(newElement as MarkdownTextData);
			},
			[element, updateElement]
		);

		return (
			<Wrapper>
				<TextEditorController
					id={`markdown_text_${element.key}`}
					value={element?.text || ''}
					onChange={handleChangeController}
					path="text"
				/>
				<Box height={spacerHeight} />
				<SelectController
					type="FONT_FAMILY"
					value={element.family}
					onChange={handleChangeController}
					path="family"
				/>
				<Box height={spacerHeight} />
				<SelectController
					type="FONT_SIZE"
					value={element.size}
					onChange={handleChangeController}
					path="size"
				/>
				<Box height={spacerHeight} />
				<SelectController
					type="FONT_ALIGN"
					value={element.align}
					onChange={handleChangeController}
					path="align"
				/>
				<Box height={spacerHeight} />
				<ColorController
					value={element.color}
					brand={brand}
					onChange={handleChangeController}
					path="color"
					label="Schriftfarbe"
					fallBackColor="neutral0"
				/>
				<Box height={spacerHeight} />
				<ColorController
					value={element.backgroundColor}
					brand={brand}
					onChange={handleChangeController}
					path="backgroundColor"
					label="Hintergrundfarbe"
					fallBackColor="background0"
				/>
			</Wrapper>
		);
	}
);

export const MarkdownTextDefaultParams: MarkdownTextData = {
	type: 'TEXT',
	textType: 'PARAGRAPH',
	text:
		'(Platzhalter) Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin quis quam nec velit scelerisque placerat. Aliquam blandit erat in felis malesuada sagittis. Fusce gravida purus sed maximus imperdiet. Donec pellentesque, diam quis dictum interdum, mi mi volutpat tortor, quis sodales sapien nulla vel neque. Etiam volutpat, elit laoreet mattis porta, diam diam aliquet massa, vel finibus nisi tellus a massa. Nullam ornare porta magna et sagittis. Donec tempus, sem a tincidunt ornare, diam dolor porta odio, id maximus ex massa non tellus. Nulla id nibh odio. Vestibulum fermentum vestibulum magna ac laoreet. Duis at faucibus augue, a consequat lorem. Nulla non leo odio. Donec risus enim, auctor ut pharetra at, facilisis ac neque.',
	index: 0,
	borderRadiusWrapper: { top: true, bottom: true },
};

export default React.memo(MarkdownTextPreview);
