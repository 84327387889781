import React from 'react';
import styledWeb from 'styled-components';
import { chunk } from 'lodash';
import {
	FormLabel,
	FormControl,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Box,
	FormHelperText,
} from '@mui/material';
import { Campaign } from '../../utils/campaign';

const Row = styledWeb(Box)`
	display: flex;
	width: 100%;
	justify-content: space-between;
`;

const options = [
	{
		id: 'EMAIL',
		label: 'E-Mail',
	},
	{
		id: 'PUSH',
		label: 'Push-Nachricht',
	},
];

export type SelectChannelsRef = {
	validate: () => boolean;
};

const SelectChannels = React.forwardRef<
	SelectChannelsRef,
	{
		selectedChannels: Campaign['channels'];
		label: string;
		onChange?: (value: Campaign['channels']) => void;
		disabled?: boolean;
	}
>(
	({ selectedChannels, label, onChange, disabled }, ref): JSX.Element => {
		const [errorMessage, setErrorMessage] = React.useState<undefined | string>();

		const handleChange = React.useCallback(
			(event) => {
				if (!selectedChannels) {
					return;
				}
				setErrorMessage(undefined);
				const selectedId = event.target.name;
				selectedChannels.includes(selectedId)
					? onChange?.(selectedChannels.filter((id) => id !== selectedId))
					: onChange?.([...selectedChannels, selectedId]);
			},
			[selectedChannels, onChange]
		);

		const handleValidate = React.useCallback(() => {
			if (!selectedChannels.length) {
				setErrorMessage('Bitte wählen');
				return true;
			} else {
				return false;
			}
		}, [selectedChannels.length]);

		React.useImperativeHandle(
			ref,
			() => ({
				validate: handleValidate,
			}),
			[handleValidate]
		);

		return (
			<Box m="2rem 0rem 0rem 0rem">
				<FormControl fullWidth variant="standard" error={Boolean(errorMessage)} disabled={disabled}>
					<FormLabel>{label}</FormLabel>
					<FormGroup row>
						{chunk(options, 4).map((chunked, index) => (
							<Row key={index}>
								{chunked.map(({ id, label: checkboxLabel }) => (
									<Box flex="1" key={id}>
										<FormControlLabel
											control={
												<Checkbox
													color="primary"
													// TODO:
													// @ts-ignore not sure how to tell typescript `...(id as item of Campaign['channels'])`
													checked={selectedChannels?.includes(id)}
													onChange={handleChange}
													name={id}
												/>
											}
											label={checkboxLabel}
										/>
									</Box>
								))}
							</Row>
						))}
					</FormGroup>
					<FormHelperText>{errorMessage || null}</FormHelperText>
				</FormControl>
			</Box>
		);
	}
);

export default SelectChannels;
