import React from 'react';
import { FormControlLabel, RadioGroup as MuiRadioGroup, Radio } from '@mui/material';

import FormControlWrapper from './FormControlWrapper';

export type RadioGroupRef = {
	validate: () => boolean;
};

const RadioGroup = React.forwardRef<
	RadioGroupRef,
	{
		options: { label: string; value: string; disabled?: boolean }[];
		onChange: (value: string) => void;
		defaultValue?: string;
		flex?: boolean;
		m?: string;
		disabled?: boolean;
	}
>(({ options, onChange, defaultValue, flex, m, disabled }, ref) => {
	const [selectedValue, setSelectedValue] = React.useState<string | null>(defaultValue || null);
	const [errorMessage, setErrorMessage] = React.useState('');

	const handleChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const newValue = event?.target?.value;
			setErrorMessage('');
			setSelectedValue(newValue);
			onChange?.(newValue);
		},
		[onChange]
	);

	const handleValidate = React.useCallback(() => {
		const invalid = !selectedValue;
		if (invalid) {
			setErrorMessage('Bitte wählen');
		}
		return invalid;
	}, [selectedValue]);

	React.useImperativeHandle(
		ref,
		() => ({
			validate: handleValidate,
		}),
		[handleValidate]
	);

	return (
		<FormControlWrapper flex={flex} m={m} errorMessage={errorMessage} disabled={disabled}>
			<MuiRadioGroup row value={selectedValue} onChange={handleChange}>
				{options.map(({ value, disabled: radioItemDisabled, label }) => (
					<FormControlLabel
						key={value}
						disabled={radioItemDisabled}
						value={value}
						control={<Radio />}
						label={label}
					/>
				))}
			</MuiRadioGroup>
		</FormControlWrapper>
	);
});

export default RadioGroup;
