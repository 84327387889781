import React from 'react';
import { Checkbox, Box } from '@mui/material';

import PseudoOutlinedTextFieldWrapper from '../../../../components/PseudoOutlinedTextFieldWrapper';
import Tooltip from '../../../../components/Tooltip';
import Txt from '../../../../components/Txt';

type CheckBoxControllerType = 'BOLDNESS' | 'FULL_WIDTH' | 'SHADOW' | 'PROGRAM_DETAILS' | 'QUIZ';

const tooltipText = `Für den Fall, dass dem Nutzer aufgrund fehlender Nutzerdaten keine adäquaten
Filmempfehlungen gemacht werden können, wird diesem an dieser Stelle die Teilnahme an
einem Filmbewertungsquiz vorgeschlagen. Hierbei kann der Nutzer zufällig ausgewählte Filme
bewerten und uns somit mehr über seinen Filmgeschmack verraten. Ist die Option "Filmquiz
anzeigen" angewählt, können Sie sich das Filmbewertungsquiz beispielhaft anzeigen lassen,
Konfigurationen an ihm vornehmen und sich eine Testemail mit dem Filmbewertungsquiz
anstelle von Filmvorschlägen senden lassen. Achtung: Beim Launch der Kampagne werden
Nutzern mit ausreichenden Nutzerdaten jedoch immer! individuell auf den Nutzer
zugeschnittene Filmempfehlungen angezeigt.`;

const typeLabelDict = {
	BOLDNESS: 'Fettgeschrieben',
	FULL_WIDTH: 'Volle Breite',
	SHADOW: 'Schlagschatten',
	PROGRAM_DETAILS: 'Erweiterte Filminfos einblenden',
	QUIZ: 'Filmquiz anzeigen',
};

const CheckBoxController = ({
	onChange,
	path,
	children,
	value,
	type,
	label,
	m,
}: {
	onChange: (path: string, newValue: any) => void;
	path: string;
	children?: string;
	value?: boolean;
	type: CheckBoxControllerType;
	label?: string;
	m?: string;
}): JSX.Element => {
	const handleChange = React.useCallback(
		(event) => {
			const updatedValue = Boolean(event?.target?.checked);
			onChange(path, updatedValue);
		},
		[onChange, path]
	);

	return (
		<>
			<PseudoOutlinedTextFieldWrapper label={label} m={m}>
				<Box
					display="flex"
					flexDirection="row"
					justifyContent="start"
					alignItems="center"
					width="100%"
				>
					<Checkbox
						value={value || ''}
						onChange={handleChange}
						color="primary"
						checked={Boolean(value)}
					/>
					<Txt>{children || typeLabelDict[type]}</Txt>
					{type === 'QUIZ' ? <Tooltip text={tooltipText} /> : null}
				</Box>
			</PseudoOutlinedTextFieldWrapper>
		</>
	);
};

export default CheckBoxController;
