import React from 'react';
import { TextField, Box, Grid, Input, FormControl, InputLabel } from '@mui/material';

import type { ID } from '@cinuru/utils/types';
import { LoadingIndicator } from '@cinuru/components';

import CinemaSelectFieldNew from '../../components/CinemaSelectFieldNew';
import { saveCinema, useCinemaDetails, createCinema } from '../../utils/cinema';
import type { Cinema } from '../../utils/cinema';
import useTextFieldContoller from '../../utils/useTextFieldController';
import type { TextInputPropsType } from '../../utils/useTextFieldController';
import { TextfieldSection, CheckboxSection } from './Sections';
import EditorFieldWrapper from '../../components/EditorFieldWrapper';
import AlertSnackBar from '../../components/AlertSnackBar';
import Expandable from '../../components/Expandable';
import StickyHeaderWrapper from '../../components/StickyHeaderWrapper';
import ContentWrapper from '../../components/ContentWrapper';
import { IconName as ButtonIconName } from '../../components/Button';
import { IconName as IconButtonIconName } from '../../components/IconButton';

export const useCheckboxStateAndProps = ({
	defaultValue,
	options,
	inputLabel,
	stateKey,
}: {
	defaultValue: string | boolean;
	inputLabel: string;
	stateKey: string;
	options: { value: string | boolean; label: string }[];
}): {
	checkboxProps: {
		label: string;
		defaultValue: string | boolean;
		options: { value: string | boolean; label: string }[];
		onChange: (optionId: string | boolean) => void;
		key: string;
	};
	newState: { [key: string]: string | boolean };
	changed: boolean;
} => {
	const [state, setState] = React.useState<string | boolean>(defaultValue);
	const [changed, setChanged] = React.useState(false);

	const handleChange = React.useCallback(
		(newValue: string | boolean) => {
			const hasChanged = newValue !== defaultValue;
			setState(newValue);
			setChanged(hasChanged);
		},
		[defaultValue]
	);

	return React.useMemo(
		() => ({
			checkboxProps: {
				label: inputLabel,
				defaultValue: state,
				options,
				onChange: handleChange,
				key: stateKey,
			},
			newState: { [stateKey]: state },
			changed,
		}),
		[inputLabel, state, options, handleChange, stateKey, changed]
	);
};

export type SectionType = {
	key: string;
	label: string;
	handleChange: (value: string, index: number, key: string) => void;
	handleAddItem: (index?: number) => void;
	handleDeleteItem: (index: number) => void;
	items: { [key: string]: string | undefined }[];
	itemKeys: string[];
};

export const useSectionStateAndProps = ({
	defaultValue,
	stateKey,
	label,
	itemKeys,
}: {
	defaultValue: { [key: string]: string | undefined }[];
	stateKey: string;
	label: string;
	itemKeys: string[];
}): {
	section: SectionType;
	newState: { [key: string]: { [key: string]: string | undefined }[] };
	changed: boolean;
} => {
	const [state, setState] = React.useState<{ [key: string]: string | undefined }[] | []>(
		defaultValue
	);
	const [changed, setChanged] = React.useState(false);
	const handleChange = React.useCallback((value, index, key) => {
		setState((itemsBefore) =>
			itemsBefore.map((item, i) => (i === index ? { ...item, [key]: value || '' } : item))
		);
	}, []);
	const handleDeleteItem = React.useCallback((index) => {
		setState((itemsBefore) => itemsBefore.filter((item, i) => index !== i));
	}, []);
	const handleAddItem = React.useCallback(
		(index?: number) => {
			const emptyItem = {};
			itemKeys.forEach((itemKey) => (emptyItem[itemKey] = ''));
			index !== undefined
				? setState((itemsBefore) => [
					...itemsBefore.filter((_, i) => i <= index),
					emptyItem,
					...itemsBefore.filter((_, i) => i > index),
				])
				: setState((itemsBefore) => [...itemsBefore, emptyItem]);
		},
		[itemKeys]
	);

	React.useEffect(() => {
		const hasChanged = JSON.stringify(defaultValue) !== JSON.stringify(state);
		setChanged(hasChanged);
	}, [defaultValue, state]);

	return React.useMemo(
		() => ({
			section: {
				key: stateKey,
				label,
				handleAddItem,
				handleDeleteItem,
				handleChange,
				items: state,
				itemKeys,
			},
			newState: { [stateKey]: state },
			changed,
		}),
		[stateKey, label, handleAddItem, handleDeleteItem, handleChange, state, itemKeys, changed]
	);
};

const openingHoursKeys = ['weekdays', 'hours'];
const baseKeys = ['title', 'image', 'description', 'price'];

interface TextInputPropsAndMarginType extends TextInputPropsType {
	margin?: string;
}

const MemoedTextField = React.memo((props: TextInputPropsAndMarginType) => {
	const { margin, ...rest } = props;
	return (
		<Box m={margin}>
			<TextField fullWidth={true} maxRows={5} {...rest} multiline variant="standard" />
		</Box>
	);
});

const MemoedNumberField = React.memo((props: TextInputPropsAndMarginType) => {
	const { margin, value, onChange, label } = props;
	return (
		<Box m={margin}>
			<FormControl fullWidth variant="standard">
				<InputLabel id="demo-simple-select-filled-label" margin="dense" shrink>
					{label}
				</InputLabel>
				<Input type="number" value={value} onChange={onChange} fullWidth={true} />
			</FormControl>
		</Box>
	);
});

const EXPANDABLE_MARGIN = '2.5rem 0';

export type EditCinemaFormRef = {
	save: () => Promise<{ success: boolean }>;
};

const EditCinemaForm2 = React.forwardRef<
	EditCinemaFormRef,
	{ cinema: Cinema; onChange?: (changed: boolean) => void; userHasRootRole: boolean }
>(({ cinema, onChange, userHasRootRole }, ref) => {
	const {
		newState: nameState,
		textInputProps: nameProps,
		changed: nameChanged,
	} = useTextFieldContoller({
		defaultValue: cinema.name,
		inputLabel: 'Kinoname',
		stateKey: 'name',
	});

	// descriptions

	const {
		newState: currentInformationState,
		textInputProps: currentInformationProps,
		changed: currentInformationChanged,
	} = useTextFieldContoller({
		defaultValue: cinema.currentInformation,
		inputLabel: 'Aktuelles',
		stateKey: 'currentInformation',
	});
	const {
		newState: specialAboutUsState,
		textInputProps: specialAboutUsProps,
		changed: specialAboutUsChanged,
	} = useTextFieldContoller({
		defaultValue: cinema.specialAboutUs,
		inputLabel: 'Über uns',
		stateKey: 'specialAboutUs',
	});
	const {
		newState: technologiesState,
		textInputProps: technologiesProps,
		changed: technologiesChanged,
	} = useTextFieldContoller({
		defaultValue: cinema.technologies,
		inputLabel: 'Unsere Technik',
		stateKey: 'technologies',
	});
	const {
		newState: historyState,
		textInputProps: historyProps,
		changed: historyChanged,
	} = useTextFieldContoller({
		defaultValue: cinema.history,
		inputLabel: 'Unsere Geschichte',
		stateKey: 'history',
	});
	const {
		newState: parkingDescriptionState,
		textInputProps: parkingDescriptionProps,
		changed: parkingDescriptionChanged,
	} = useTextFieldContoller({
		defaultValue: cinema.parkingDescription,
		inputLabel: 'Beschreibung Parken',
		stateKey: 'parkingDescription',
	});
	const {
		newState: locationDescriptionState,
		textInputProps: locationDescriptionProps,
		changed: locationDescriptionChanged,
	} = useTextFieldContoller({
		defaultValue: cinema.locationDescription,
		inputLabel: 'Beschreibung Lage',
		stateKey: 'locationDescription',
	});
	const {
		newState: barrierFreeTextState,
		textInputProps: barrierFreeTextProps,
		changed: barrierFreeTextChanged,
	} = useTextFieldContoller({
		defaultValue: cinema.barrierFreeText,
		inputLabel: 'Barrierefreiheit',
		stateKey: 'barrierFreeText',
	});

	const {
		newState: hearingImpairedTextState,
		textInputProps: hearingImpairedTextProps,
		changed: hearingImpairedTextChanged,
	} = useTextFieldContoller({
		defaultValue: cinema.hearingImpairedText,
		inputLabel: 'Geeignet für Gehörlose',
		stateKey: 'hearingImpairedText',
	});
	const {
		newState: blindTextState,
		textInputProps: blindTextProps,
		changed: blindTextChanged,
	} = useTextFieldContoller({
		defaultValue: cinema.blindText,
		inputLabel: 'Für Blinde geeignet',
		stateKey: 'blindText',
	});

	// others

	const {
		newState: onlineTicketingBaseUrlState,
		textInputProps: onlineTicketingBaseUrlProps,
		changed: onlineTicketingBaseUrlChanged,
	} = useTextFieldContoller({
		defaultValue: cinema.onlineTicketingBaseUrl,
		inputLabel: 'Online Ticketing Basis URL',
		stateKey: 'onlineTicketingBaseUrl',
	});
	const {
		newState: imprintState,
		textInputProps: imprintProps,
		changed: imprintChanged,
	} = useTextFieldContoller({
		defaultValue: cinema.imprint,
		inputLabel: 'Impressum',
		stateKey: 'imprint',
	});
	const {
		newState: streetState,
		textInputProps: streetProps,
		changed: streetChanged,
	} = useTextFieldContoller({
		defaultValue: cinema.street,
		inputLabel: 'Straße',
		stateKey: 'street',
	});
	const {
		newState: houseNumberState,
		textInputProps: houseNumberProps,
		changed: houseNumberChanged,
	} = useTextFieldContoller({
		defaultValue: cinema.houseNumber,
		inputLabel: 'Hausnummer',
		stateKey: 'houseNumber',
	});
	const {
		newState: zipCodeState,
		textInputProps: zipCodeProps,
		changed: zipCodeChanged,
	} = useTextFieldContoller({
		defaultValue: cinema.zipCode,
		inputLabel: 'Postleitzahl',
		stateKey: 'zipCode',
	});
	const {
		newState: cityState,
		textInputProps: cityProps,
		changed: cityChanged,
	} = useTextFieldContoller({
		defaultValue: cinema.city,
		inputLabel: 'Stadt',
		stateKey: 'city',
	});

	const {
		newState: googleMapsLinkState,
		textInputProps: googleMapsLinkProps,
		changed: googleMapsLinkChanged,
	} = useTextFieldContoller({
		defaultValue: cinema.googleMapsLink,
		inputLabel: 'Google Maps Link',
		stateKey: 'googleMapsLink',
	});
	const {
		newState: appleMapsLinkState,
		textInputProps: appleMapsLinkProps,
		changed: appleMapsLinkChanged,
	} = useTextFieldContoller({
		defaultValue: cinema.appleMapsLink,
		inputLabel: 'Apple Maps Link',
		stateKey: 'appleMapsLink',
	});
	const {
		newState: facebookState,
		textInputProps: facebookProps,
		changed: facebookChanged,
	} = useTextFieldContoller({
		defaultValue: cinema.facebook,
		inputLabel: 'Facebook',
		stateKey: 'facebook',
	});
	const {
		newState: twitterState,
		textInputProps: twitterProps,
		changed: twitterChanged,
	} = useTextFieldContoller({
		defaultValue: cinema.twitter,
		inputLabel: 'Twitter',
		stateKey: 'twitter',
	});
	const {
		newState: websiteState,
		textInputProps: websiteProps,
		changed: websiteChanged,
	} = useTextFieldContoller({
		defaultValue: cinema.website,
		inputLabel: 'Website',
		stateKey: 'website',
	});
	const {
		newState: phoneState,
		textInputProps: phoneProps,
		changed: phoneChanged,
	} = useTextFieldContoller({
		defaultValue: cinema.phone,
		inputLabel: 'Telefon',
		stateKey: 'phone',
	});
	const {
		newState: emailState,
		textInputProps: emailProps,
		changed: emailChanged,
	} = useTextFieldContoller({
		defaultValue: cinema.email,
		inputLabel: 'Email',
		stateKey: 'email',
	});
	const {
		newState: instagramState,
		textInputProps: instagramProps,
		changed: instagramChanged,
	} = useTextFieldContoller({
		defaultValue: cinema.instagram,
		inputLabel: 'Instagram',
		stateKey: 'instagram',
	});

	const {
		newState: headerImageState,
		textInputProps: headerImageProps,
		changed: headerImageChanged,
	} = useTextFieldContoller({
		defaultValue: cinema.headerImage,
		inputLabel: 'Header Bild',
		stateKey: 'headerImage',
	});

	// sections

	const {
		newState: openingHoursDisplayState,
		section: openingHoursDisplaySection,
		changed: openingHoursDisplayChanged,
	} = useSectionStateAndProps({
		label: 'Öffnungszeiten',
		defaultValue: cinema.openingHoursDisplay,
		stateKey: 'openingHoursDisplay',
		itemKeys: openingHoursKeys,
	});

	const {
		newState: pricesDisplayState,
		section: pricesDisplaySection,
		changed: pricesDisplayChanged,
	} = useSectionStateAndProps({
		label: 'Preise',
		defaultValue: cinema.pricesDisplay,
		stateKey: 'pricesDisplay',
		itemKeys: baseKeys,
	});

	const {
		newState: specialOffersDisplayState,
		section: specialOffersDisplaySection,
		changed: specialOffersDisplayChanged,
	} = useSectionStateAndProps({
		label: 'Besondere Angebote / Vergünstigungen, Sonstiges',
		defaultValue: cinema.specialOffersDisplay,
		stateKey: 'specialOffersDisplay',
		itemKeys: baseKeys,
	});

	const {
		newState: giftVouchersDisplayState,
		section: giftVouchersDisplaySection,
		changed: giftVouchersDisplayChanged,
	} = useSectionStateAndProps({
		label: 'Geschenkgutscheine',
		defaultValue: cinema.giftVouchersDisplay,
		stateKey: 'giftVouchersDisplay',
		itemKeys: baseKeys,
	});

	const {
		newState: sneaksDisplayState,
		section: sneaksDisplaySection,
		changed: sneaksDisplayChanged,
	} = useSectionStateAndProps({
		label: 'Sneak Previews',
		defaultValue: cinema.sneaksDisplay,
		stateKey: 'sneaksDisplay',
		itemKeys: baseKeys,
	});

	// checkbox

	const {
		newState: accessRestrictedToState,
		checkboxProps: accessRestrictedToCheckBoxProps,
		changed: accessRestrictedToChanged,
	} = useCheckboxStateAndProps({
		defaultValue: cinema.accessRestrictedTo,
		inputLabel: 'Zugriffsbeschränkung',
		stateKey: 'accessRestrictedTo',
		options: [
			{ label: 'Production', value: 'PRODUCTION' },
			{ label: 'Staging', value: 'STAGING' },
			{ label: 'Development', value: 'DEVELOPMENT' },
		],
	});

	const {
		newState: hasBonusProgramState,
		checkboxProps: hasBonusProgramCheckBoxProps,
		changed: hasBonusProgramChanged,
	} = useCheckboxStateAndProps({
		defaultValue: Boolean(cinema.hasBonusProgram),
		inputLabel: 'Bonussystem aktiv',
		stateKey: 'hasBonusProgram',
		options: [
			{ label: 'Ja', value: true },
			{ label: 'Nein', value: false },
		],
	});

	// barrier

	const {
		newState: barrierFreeState,
		checkboxProps: barrierFreeCheckBoxProps,
		changed: barrierFreeChanged,
	} = useCheckboxStateAndProps({
		defaultValue: Boolean(cinema.barrierFree),
		inputLabel: 'BarriereFreiheit',
		stateKey: 'barrierFree',
		options: [
			{ label: 'Ja', value: true },
			{ label: 'Nein', value: false },
		],
	});

	const {
		newState: hearingImpairedState,
		checkboxProps: hearingImpairedCheckBoxProps,
		changed: hearingImpairedChanged,
	} = useCheckboxStateAndProps({
		defaultValue: Boolean(cinema.hearingImpaired),
		inputLabel: 'Geeignet für Gehörlose',
		stateKey: 'hearingImpaired',
		options: [
			{ label: 'Ja', value: true },
			{ label: 'Nein', value: false },
		],
	});

	const {
		newState: blindState,
		checkboxProps: blindCheckBoxProps,
		changed: blindChanged,
	} = useCheckboxStateAndProps({
		defaultValue: Boolean(cinema.blind),
		inputLabel: 'Für Blinde geeignet',
		stateKey: 'blind',
		options: [
			{ label: 'Ja', value: true },
			{ label: 'Nein', value: false },
		],
	});

	// location

	const {
		newState: latitudeState,
		textInputProps: latitudeProps,
		changed: latitudeChanged,
	} = useTextFieldContoller({
		defaultValue: cinema.location?.latitude,
		inputLabel: 'Breite',
		stateKey: 'latitude',
		type: 'NUMBER',
	});

	const {
		newState: longitudeState,
		textInputProps: longitudeProps,
		changed: longitudeChanged,
	} = useTextFieldContoller({
		defaultValue: cinema.location?.longitude,
		inputLabel: 'Höhe',
		stateKey: 'longitude',
		type: 'NUMBER',
	});

	const dataChanged = React.useMemo(
		() =>
			[
				nameChanged,
				appleMapsLinkChanged,
				cityChanged,
				currentInformationChanged,
				googleMapsLinkChanged,
				historyChanged,
				houseNumberChanged,
				imprintChanged,
				locationDescriptionChanged,
				onlineTicketingBaseUrlChanged,
				parkingDescriptionChanged,
				specialAboutUsChanged,
				streetChanged,
				technologiesChanged,
				zipCodeChanged,
				facebookChanged,
				twitterChanged,
				websiteChanged,
				phoneChanged,
				emailChanged,
				instagramChanged,
				headerImageChanged,
				barrierFreeTextChanged,
				hearingImpairedTextChanged,
				blindTextChanged,
				openingHoursDisplayChanged,
				pricesDisplayChanged,
				specialOffersDisplayChanged,
				giftVouchersDisplayChanged,
				sneaksDisplayChanged,
				accessRestrictedToChanged,
				hasBonusProgramChanged,
				latitudeChanged,
				longitudeChanged,
				barrierFreeChanged,
				hearingImpairedChanged,
				blindChanged,
			].some((value) => value === true),
		[
			appleMapsLinkChanged,
			nameChanged,
			cityChanged,
			currentInformationChanged,
			googleMapsLinkChanged,
			historyChanged,
			houseNumberChanged,
			imprintChanged,
			locationDescriptionChanged,
			onlineTicketingBaseUrlChanged,
			parkingDescriptionChanged,
			specialAboutUsChanged,
			streetChanged,
			technologiesChanged,
			zipCodeChanged,
			facebookChanged,
			twitterChanged,
			websiteChanged,
			phoneChanged,
			emailChanged,
			instagramChanged,
			headerImageChanged,
			barrierFreeTextChanged,
			hearingImpairedTextChanged,
			blindTextChanged,
			openingHoursDisplayChanged,
			pricesDisplayChanged,
			specialOffersDisplayChanged,
			giftVouchersDisplayChanged,
			sneaksDisplayChanged,
			accessRestrictedToChanged,
			hasBonusProgramChanged,
			latitudeChanged,
			longitudeChanged,
			barrierFreeChanged,
			hearingImpairedChanged,
			blindChanged,
		]
	);

	const changedCinema = React.useMemo(
		() => ({
			...cinema,
			...nameState,
			...currentInformationState,
			...specialAboutUsState,
			...technologiesState,
			...historyState,
			...onlineTicketingBaseUrlState,
			...imprintState,
			...streetState,
			...houseNumberState,
			...zipCodeState,
			...cityState,
			...parkingDescriptionState,
			...locationDescriptionState,
			...googleMapsLinkState,
			...appleMapsLinkState,
			...facebookState,
			...twitterState,
			...websiteState,
			...phoneState,
			...emailState,
			...instagramState,
			...headerImageState,
			...barrierFreeTextState,
			...hearingImpairedTextState,
			...blindTextState,
			...openingHoursDisplayState,
			...pricesDisplayState,
			...specialOffersDisplayState,
			...giftVouchersDisplayState,
			...sneaksDisplayState,
			...accessRestrictedToState,
			...hasBonusProgramState,
			...barrierFreeState,
			...hearingImpairedState,
			...blindState,
			location: {
				...latitudeState,
				...longitudeState,
			},
		}),
		[
			nameState,
			appleMapsLinkState,
			cinema,
			cityState,
			currentInformationState,
			googleMapsLinkState,
			historyState,
			houseNumberState,
			imprintState,
			locationDescriptionState,
			onlineTicketingBaseUrlState,
			parkingDescriptionState,
			specialAboutUsState,
			streetState,
			technologiesState,
			zipCodeState,
			facebookState,
			twitterState,
			websiteState,
			phoneState,
			emailState,
			instagramState,
			headerImageState,
			barrierFreeTextState,
			hearingImpairedTextState,
			blindTextState,
			openingHoursDisplayState,
			pricesDisplayState,
			specialOffersDisplayState,
			giftVouchersDisplayState,
			sneaksDisplayState,
			accessRestrictedToState,
			hasBonusProgramState,
			latitudeState,
			longitudeState,
			barrierFreeState,
			hearingImpairedState,
			blindState,
		]
	);

	React.useEffect(() => {
		onChange && onChange(dataChanged);
	}, [dataChanged, onChange]);

	const barrierInfos = React.useMemo(
		() => [barrierFreeTextProps, hearingImpairedTextProps, blindTextProps],
		[barrierFreeTextProps, hearingImpairedTextProps, blindTextProps]
	);

	const details = React.useMemo(
		() => [
			currentInformationProps,
			specialAboutUsProps,
			technologiesProps,
			historyProps,
			parkingDescriptionProps,
			locationDescriptionProps,
		],
		[
			currentInformationProps,
			specialAboutUsProps,
			technologiesProps,
			historyProps,
			parkingDescriptionProps,
			locationDescriptionProps,
		]
	);

	const adressItems = React.useMemo(
		() => [streetProps, houseNumberProps, zipCodeProps, cityProps],
		[cityProps, houseNumberProps, streetProps, zipCodeProps]
	);

	const mapsItems = React.useMemo(() => [googleMapsLinkProps, appleMapsLinkProps], [
		appleMapsLinkProps,
		googleMapsLinkProps,
	]);

	const locationItems = React.useMemo(() => [latitudeProps, longitudeProps], [
		latitudeProps,
		longitudeProps,
	]);

	const contactItems = React.useMemo(
		() => [
			emailProps,
			phoneProps,
			websiteProps,
			facebookProps,
			twitterProps,
			instagramProps,
			imprintProps,
		],
		[
			imprintProps,
			facebookProps,
			twitterProps,
			websiteProps,
			phoneProps,
			emailProps,
			instagramProps,
		]
	);

	const system = React.useMemo(() => [headerImageProps, onlineTicketingBaseUrlProps], [
		headerImageProps,
		onlineTicketingBaseUrlProps,
	]);

	const sections = React.useMemo(
		() => [
			openingHoursDisplaySection,
			pricesDisplaySection,
			specialOffersDisplaySection,
			giftVouchersDisplaySection,
			sneaksDisplaySection,
		],
		[
			openingHoursDisplaySection,
			pricesDisplaySection,
			specialOffersDisplaySection,
			giftVouchersDisplaySection,
			sneaksDisplaySection,
		]
	);

	const checkBoxSections = React.useMemo(
		() => [accessRestrictedToCheckBoxProps, hasBonusProgramCheckBoxProps],
		[accessRestrictedToCheckBoxProps, hasBonusProgramCheckBoxProps]
	);

	const barrierCheckboxSections = React.useMemo(
		() => [barrierFreeCheckBoxProps, hearingImpairedCheckBoxProps, blindCheckBoxProps],
		[barrierFreeCheckBoxProps, hearingImpairedCheckBoxProps, blindCheckBoxProps]
	);

	React.useImperativeHandle(
		ref,
		() => ({
			save: async () => {
				const { success } = await saveCinema(changedCinema);
				return { success };
			},
		}),
		[changedCinema]
	);

	return (
		<>
			{userHasRootRole ? (
				<Expandable margin={EXPANDABLE_MARGIN} label="System">
					<Box m="2rem 0 0">
						<MemoedTextField {...nameProps} />
					</Box>
					<Box m="4rem 0 0">
						<Grid container spacing={2}>
							{checkBoxSections.map((section) => (
								<Grid key={section.key} item xs={12} sm={6}>
									<CheckboxSection {...section} key={section.key} />
								</Grid>
							))}
						</Grid>
					</Box>
					<Box m="2rem 0 0">
						<Grid container spacing={2}>
							{system.map((item) => (
								<Grid key={item.key} item xs={12} md={6}>
									<MemoedTextField {...item} key={item.key} />
								</Grid>
							))}
						</Grid>
					</Box>
				</Expandable>
			) : null}

			<Expandable margin={EXPANDABLE_MARGIN} label="Adresse" defaultExpanded>
				<Box m="2rem 0 1rem">
					<Grid container spacing={2}>
						{adressItems.map((item) => (
							<Grid key={item.key} item xs={12} sm={6} md={3}>
								<MemoedTextField {...item} key={item.key} />
							</Grid>
						))}
					</Grid>
				</Box>
			</Expandable>
			<Expandable margin={EXPANDABLE_MARGIN} label="Kontakt" defaultExpanded>
				<Box m="2rem 0 1rem">
					<Grid container spacing={2}>
						{contactItems.map((item) => (
							<Grid key={item.key} item xs={12} md={6}>
								<MemoedTextField {...item} key={item.key} />
							</Grid>
						))}
					</Grid>
				</Box>
			</Expandable>
			{userHasRootRole ? (
				<Expandable margin={EXPANDABLE_MARGIN} label="Maps">
					{mapsItems.map((item) => (
						<MemoedTextField margin="2rem 0 1rem" {...item} key={item.key} />
					))}
				</Expandable>
			) : null}
			<Expandable margin={EXPANDABLE_MARGIN} label="Koordinaten">
				{locationItems.map((item) => (
					<MemoedNumberField margin="2rem 0 1rem" {...item} key={item.key} />
				))}
			</Expandable>

			{details.map((item) => (
				<Expandable key={item.key} margin={EXPANDABLE_MARGIN} label={item.label}>
					<EditorFieldWrapper
						{...item}
						label=""
						margin="1rem -0.5rem 0rem"
						key={item.key}
						onChange={item.onChange as (value: string) => void}
					/>
				</Expandable>
			))}

			<Expandable margin={EXPANDABLE_MARGIN} label="Barrierefreiheit">
				{barrierInfos.map((item, index) => (
					<Box key={item.key}>
						<CheckboxSection
							{...barrierCheckboxSections[index]}
							key={barrierCheckboxSections[index].key}
						/>
						<EditorFieldWrapper
							{...item}
							label=""
							margin={index === barrierInfos.length - 1 ? '1rem -0.5rem 0rem' : '1rem -0.5rem 4rem'}
							key={item.key}
							onChange={item.onChange as (value: string) => void}
						/>
					</Box>
				))}
			</Expandable>

			{sections.map(
				({
					key,
					label,
					handleChange,
					handleAddItem,
					handleDeleteItem,
					items,
					itemKeys,
				}: SectionType) => (
					<TextfieldSection
						key={key}
						label={label}
						handleChange={handleChange}
						handleAddItem={handleAddItem}
						handleDeleteItem={handleDeleteItem}
						items={items}
						margin={EXPANDABLE_MARGIN}
						itemMargin="3rem 0"
						itemPadding="1rem 0"
						itemKeys={itemKeys}
					/>
				)
			)}
		</>
	);
});

const EditCinemaNew = ({ privileges }: { privileges: { rootRole: boolean } }): JSX.Element => {
	const [selectedCinemaId, setSelectedCinemaId] = React.useState<ID | undefined>(undefined);
	const [cinemaDataChanged, setCinemaDataChanged] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [showSnackBar, setShowSnackBar] = React.useState<boolean>(false);
	const [success, setSuccess] = React.useState<boolean | undefined>(undefined);
	const [remountCounter, setRemountCounter] = React.useState(1);

	const handleSelectCinemaId = React.useCallback((items: { id: ID; name: string }[]) => {
		const newCinemaId = items[0]?.id;
		setSelectedCinemaId(newCinemaId);
	}, []);
	const cinema = useCinemaDetails(selectedCinemaId);

	const editCinemaFormRef = React.useRef<EditCinemaFormRef>(null);

	const handleSave = React.useCallback(async () => {
		if (editCinemaFormRef.current) {
			setLoading(true);
			const { success: saveCinemaSuccess } = await editCinemaFormRef.current!.save();
			setLoading(false);
			setSuccess(saveCinemaSuccess);
			setShowSnackBar(true);
			setRemountCounter((b) => b + 1);
		}
	}, []);

	const handleClose = React.useCallback(() => {
		setShowSnackBar(false);
	}, []);

	const handleWithdraw = React.useCallback(() => {
		setRemountCounter((b) => b + 1);
	}, []);

	const handleCreateNewCinema = React.useCallback(async () => {
		setLoading(true);
		const { success: createCinemaSuccess, id } = await createCinema();
		setLoading(false);
		if (createCinemaSuccess) {
			setSelectedCinemaId(id!);
		} else {
			setSuccess(false);
			setShowSnackBar(true);
		}
	}, []);

	const buttons = React.useMemo(() => {
		return [
			privileges.rootRole && {
				label: 'Neues Kino',
				startIconName: 'Add',
				disabled: loading,
				loading: loading,
				onClick: handleCreateNewCinema,
				collapsedIconName: 'Add',
			},
			{
				label: 'Verwerfen',
				startIconName: 'DeleteOutline',
				disabled: !selectedCinemaId || !cinema || loading || !cinemaDataChanged,
				loading: loading,
				onClick: handleWithdraw,
				collapsedIconName: 'DeleteOutline',
			},
			{
				label: 'Speichern',
				startIconName: 'SaveOutlined',
				disabled: !selectedCinemaId || !cinema || loading || !cinemaDataChanged,
				loading: loading,
				onClick: handleSave,
				collapsedIconName: 'SaveOutlined',
			},
		].filter((element) => element) as {
			label: string;
			startIconName: ButtonIconName;
			disabled: boolean;
			onClick: () => void;
			collapsedIconName: IconButtonIconName;
		}[];
	}, [
		cinema,
		cinemaDataChanged,
		handleCreateNewCinema,
		handleSave,
		handleWithdraw,
		loading,
		selectedCinemaId,
		privileges.rootRole,
	]);


	return (
		<StickyHeaderWrapper
			label="Kino editieren"
			buttons={buttons}
			showWarningOnLeave={cinemaDataChanged}
			warningOnLeave="Änderungen wurden nicht gespeichert! Fortfahren?"
			isLoading={false}
		>
			<ContentWrapper>
				<Box m="0 0 1rem">
					<CinemaSelectFieldNew
						label="Kino auswählen"
						onChange={handleSelectCinemaId}
						disabled={cinemaDataChanged}
					/>
				</Box>

				{selectedCinemaId ? (
					cinema ? (
						<EditCinemaForm2
							key={`${cinema.id + remountCounter}`}
							cinema={cinema}
							ref={editCinemaFormRef}
							userHasRootRole={privileges.rootRole}
							onChange={setCinemaDataChanged}
						/>
					) : (
						<LoadingIndicator />
					)
				) : null}
			</ContentWrapper>

			<AlertSnackBar
				show={showSnackBar}
				handleClose={handleClose}
				message={success ? 'Gespeichert!' : 'Ein Fehler ist aufgetreten.'}
				severity={success ? 'success' : 'error'}
			/>
		</StickyHeaderWrapper>
	);
};

export default EditCinemaNew;
