import React from 'react';
import { createTheme } from '@cinuru/utils/theme';
import { createTheme as createMuiTheme } from '@mui/material/styles';
import { useWindowDimensions } from '../utils/dimensions';
import { brand } from '../consts';

declare module '@mui/material/styles' {
	interface Theme {
		customColors: {
			accentBlue: string;
			accentDarkBlue: string;
			accentLightBlue: string;
			white: string;
			accentGrey: string;
			accentDarkGrey: string;
			accentLighGrey: string;
			grey: string;
			lightGrey: string;
			disabledTextGrey: string;
			accentGradient0: string;
		};
	}
	interface ThemeOptions {
		customColors?: {
			accentBlue: string;
			accentDarkBlue: string;
			accentLightBlue: string;
			white: string;
			accentGrey: string;
			accentDarkGrey: string;
			accentLighGrey: string;
			grey: string;
			lightGrey: string;
			disabledTextGrey: string;
			accentGradient0: string;
		};
	}
}

declare module '@mui/material/styles' {
	interface Palette {
		altButton: Palette['primary'];
	}
	interface PaletteOptions {
		altButton: Palette['primary'];
	}
}

declare module '@mui/material/Button' {
	interface ButtonPropsColorOverrides {
		altButton: true;
	}
	interface ButtonPropsVariantOverrides {
		mainButton: true;
		textButton: true;
		loadingButton: true;
		disabledTextButton: true;
		selectButton: true;
		selectButtonActive: true;
	}
}

export const cinuruTheme = createTheme(brand, { darkMode: false });

const brandedColours =
	{
		'CINURU': {},
		'CINFINITY-WEB': {
			accentBlue: '#4114C8',
			accentLightBlue: '#8e73e0',
			accentGradient0: '#4114C8',
			accentDarkBlue: '#000033',
		},
	}[brand] || {};

const accentBlue = brandedColours.accentBlue || cinuruTheme.colors.accent0;
const accentDarkBlue = brandedColours.accentDarkBlue || '#1066b2';
const accentLightBlue = brandedColours.accentLightBlue || '#45a8ff';
const white = brandedColours.white || '#ffffff';
const accentGrey = brandedColours.accentGrey || '#1236A:';
const accentDarkGrey = brandedColours.accentDarkGrey || '#0c2577';
const accentLighGrey = brandedColours.accentLighGrey || '#415ebb';
const grey = brandedColours.grey || '#D0D0D0';
const lightGrey = brandedColours.lightGrey || '#E8E8E8';
const disabledTextGrey = brandedColours.disabledTextGrey || '#A6A6A6';
const accentGradient0 =
	brandedColours.accentGradient0 || 'linear-gradient(to right bottom, #00d9ff, #376df0)';

const defaultMuiTheme = createMuiTheme({});

const buttonShadow = defaultMuiTheme.shadows[1];

const breakPoints = {
	values: {
		xs: 0,
		sm: 600,
		md: 900,
		lg: 1200,
		xl: 1536,
	},
};

const theme = createMuiTheme({
	...breakPoints,
	components: {
		MuiButton: {
			variants: [
				{
					props: { variant: 'selectButton' },
					style: {
						'borderRadius': '0.5rem',
						'border': 'none',
						'backgroundColor': lightGrey,
						'textTransform': 'none',
						'&:hover': {
							color: white,
							backgroundColor: accentBlue,
						},
					},
				},
				{
					props: { variant: 'selectButtonActive' },
					style: {
						'borderRadius': '0.5rem',
						'border': 'none',
						'backgroundColor': accentBlue,
						'textTransform': 'none',
						'color': white,
						'&:hover': {
							backgroundColor: accentDarkBlue,
						},
					},
				},
				{
					props: { variant: 'mainButton' },
					style: {
						'borderRadius': '0.5rem',
						'border': `1px solid ${accentBlue}`,
						'color': white,
						'backgroundColor': accentBlue,
						'box-shadow': buttonShadow,
						'&:hover': {
							border: `1px solid ${accentDarkBlue}`,
							color: white,
							backgroundColor: accentDarkBlue,
						},
					},
				},
				{
					props: { variant: 'textButton' },
					style: {
						'color': white,
						'backgroundColor': 'transparent',
						'&:hover': {
							color: accentBlue,
							backgroundColor: white,
						},
					},
				},
				{
					props: { variant: 'loadingButton' },
					style: {
						'borderRadius': '0.5rem',
						'border': `1px solid ${white}`,
						'color': lightGrey,
						'backgroundColor': white,
						'box-shadow': buttonShadow,
					},
				},
				{
					props: { variant: 'disabledTextButton' },
					style: {
						color: accentDarkBlue,
						backgroundColor: 'transparent',
					},
				},
			],
		},
	},
	typography: {
		fontSize: 28,
	},
	customColors: {
		accentBlue,
		accentDarkBlue,
		accentLightBlue,
		white,
		accentGrey,
		accentDarkGrey,
		accentLighGrey,
		grey,
		lightGrey,
		disabledTextGrey,
		accentGradient0,
	},
	palette: {
		primary: {
			main: accentBlue,
			dark: accentDarkBlue,
			light: accentLightBlue,
			contrastText: white,
		},
		secondary: {
			main: accentGrey,
			dark: accentDarkGrey,
			light: accentLighGrey,
		},
		altButton: {
			main: white,
			light: white,
			dark: white,
			contrastText: white,
		},
	},
});

export default theme;

const breakPointsValues = breakPoints.values;

export const useActiveBreakPoint = (): 'xs' | 'sm' | 'md' | 'lg' | 'xl' => {
	const { width } = useWindowDimensions();
	const breakPointRanges: [number, number][] = React.useMemo(() => {
		const breakPointsKey = Object.keys(breakPointsValues);
		const result: [number, number][] = [];
		for (const key in breakPointsValues) {
			const currentIndex = breakPointsKey.findIndex((k) => k === key);
			result.push([
				breakPointsValues[key],
				breakPointsValues[breakPointsKey[currentIndex + 1]] || 100000,
			]);
		}
		return result;
	}, []);
	const activeBreakPoint = React.useMemo(() => {
		return breakPointRanges
			.map(([min, max], index) =>
				width > min && width <= max ? Object.keys(breakPointsValues)[index] : null
			)
			.filter(Boolean)[0];
	}, [breakPointRanges, width]);
	return activeBreakPoint as 'xs' | 'sm' | 'md' | 'lg' | 'xl';
};
