// @flow
import * as React from 'react';
import styled from 'styled-native-components';

import Icon from '../Icon';
import Touchable from '../Touchable';

const Wrapper = styled(Touchable).attrs({
	stopPropagation: true,
	noCursor: true,
})`
	border-color: $border0;
	border-top-width: 1px;
	margin-bottom: -0.5rem;
	padding: 0.25rem 1.5rem 1rem;
	flex: 1 0;
	max-width: 100%;
	flex-direction: row;
	justify-content: space-between;
	overflow: scroll;
`;

const Separator = styled.View`
	border-color: $border0;
	border-left-width: 1px;
	margin: 0.5rem 2rem;
`;

const IconWrapper = styled.TouchableOpacity`
	background-color: ${(p) => (p.hovered ? '$border0' : 'transparent')};
	border-radius: ${(p) => p.theme.borderRadius[1]};
	padding: 0.25rem;
`;

class IconButton extends React.PureComponent {
	state = { hovered: false };

	handleMouseOver = () => !this.state.hovered && this.setState({ hovered: true });
	handleMouseLeave = () => this.setState({ hovered: false });

	handleToggle = () => this.props.onToggle && this.props.onToggle(this.props.type);

	render = () => (
		<IconWrapper
			onPress={this.handleToggle}
			onMouseOver={this.handleMouseOver}
			onMouseLeave={this.handleMouseLeave}
			hovered={this.state.hovered}
		>
			<Icon
				name={this.props.iconName}
				color={this.props.active ? '$accent0' : this.state.hovered ? '$neutral0' : '$neutral2'}
			/>
		</IconWrapper>
	);
}

const EditorControls = ({
	activeInlineStyles,
	activeBlockType,
	onToggleInlineStyle,
	onToggleBlockType,
	onInsertBlock,
	onToggleEntity,
}) => {
	return (
		<Wrapper>
			<IconButton
				type="BOLD"
				iconName="bold"
				onToggle={onToggleInlineStyle}
				active={activeInlineStyles.has('BOLD')}
			/>
			<IconButton
				type="ITALIC"
				iconName="italic"
				onToggle={onToggleInlineStyle}
				active={activeInlineStyles.has('ITALIC')}
			/>
			{/* TODO: move link to entity group, once images are possible */}
			<IconButton iconName="link" onToggle={() => onToggleEntity('LINK', 'MUTABLE', {})} />
			<Separator />
			<IconButton
				type="header-one"
				iconName="h1"
				onToggle={onToggleBlockType}
				active={activeBlockType === 'header-one'}
			/>
			<IconButton
				type="header-two"
				iconName="h2"
				onToggle={onToggleBlockType}
				active={activeBlockType === 'header-two'}
			/>
			<IconButton
				type="ordered-list-item"
				iconName="ol"
				onToggle={onToggleBlockType}
				active={activeBlockType === 'ordered-list-item'}
			/>
			<IconButton
				type="unordered-list-item"
				iconName="ul"
				onToggle={onToggleBlockType}
				active={activeBlockType === 'unordered-list-item'}
			/>
			<IconButton
				type="blockquote"
				iconName="quotation"
				onToggle={onToggleBlockType}
				active={activeBlockType === 'blockquote'}
			/>
			<IconButton
				type="horizontal-ruler"
				iconName="ruler"
				onToggle={(type) => onInsertBlock(type, '------')}
			/>
			{/* <Separator /> */}
			{/* <IconButton iconName="linkEntity" /> */}
			{/* <IconButton iconName="emoji" /> */}
			{/* <IconButton
				type="image"
				iconName="image"
				onToggle={(type) => onInsertBlock(type, ' ', { type: 'IMAGE', data: {} })}
			/> */}
		</Wrapper>
	);
};

export default EditorControls;
