import gql from 'graphql-tag';
import client from '../apollo';
import { ID } from '@cinuru/utils/types';

export type Language =
	| 'de'
	| 'de-cinfinity'
	| 'de-cineplex'
	| 'de-cinuru'
	| 'de-lumos'
	| 'de-cineweb'
	| 'de-drehwerk'
	| 'de-elselichtspiele';

export type PushNotificationChannelId = 'CINEMA_NEWS' | 'WATCHLIST' | 'EXIT_POLL' | 'BONUS_PROGRAM';

type Error =
	| 'TOKEN_NOT_REGISTERED'
	| 'INVALID_IMAGE'
	| 'UNEXPECTED_ERROR'
	| 'NETWORK_ERROR'
	| 'IVNALID_PAYLOAD';

export const sendTestNotification = async ({
	title,
	body,
	link,
	imageUrl,
	inAppNotification,
	pushNotificationChannel,
	language = 'de-cinuru',
	cinemaIds,
}: {
	title: string;
	body: string;
	link: string;
	imageUrl: string;
	inAppNotification: boolean;
	pushNotificationChannel: PushNotificationChannelId;
	language: Language;
	cinemaIds: ID[];
}): Promise<{ success: true; error: null } | { success: false; error: Error }> => {
	try {
		const { errors } = await client.mutate({
			mutation: gql`
				mutation SendTestNotification(
					$title: String!
					$body: String!
					$link: String!
					$imageUrl: String!
					$inAppNotification: Boolean!
					$pushNotificationChannel: String!
					$language: String!
					$cinemaIds: [ID!]!
				) {
					sendTestNotification(
						title: $title
						body: $body
						link: $link
						imageUrl: $imageUrl
						inAppNotification: $inAppNotification
						pushNotificationChannel: $pushNotificationChannel
						language: $language
						cinemaIds: $cinemaIds
					) {
						success
					}
				}
			`,
			variables: {
				title,
				body,
				link,
				imageUrl,
				inAppNotification,
				pushNotificationChannel,
				language,
				cinemaIds,
			},
			errorPolicy: 'all',
		});
		if (errors) {
			const error = errors[0]?.message;
			return { success: false, error: error as Error };
		} else return { success: true, error: null };
	} catch (e: any) {
		if (e.networkError) return { success: false, error: 'NETWORK_ERROR' };
		else throw e;
	}
};
