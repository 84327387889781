import React from 'react';

import Dialog from './Dialog';
import Button from './Button';
import ImageSelectDialog, { ImageSourceType, ImageSelectDialogTab } from './ImageSelectDialog';
import FormControlWrapper from './FormControlWrapper';

export type ImageSelectButtonRef = {
	validate: () => boolean;
};

const ImageSelectButton = React.forwardRef<
	ImageSelectButtonRef,
	{
		label?: string;
		defaultImageUrl?: string;
		defaultTab?: ImageSelectDialogTab;
		onChange?: (imageUrl?: string | null) => void;
		placeHolderImageUrl?: string | null;
		disabled?: boolean;
		m?: string;
		width?: string;
	}
>(
	(
		{
			label,
			defaultImageUrl,
			defaultTab,
			onChange,
			placeHolderImageUrl = 'https://static.cinuru.com/public/dashboard/placeholder.jpg',
			disabled, // TODO: handle disabled
			m,
			width,
		},
		ref
	): JSX.Element | null => {
		const [errorMessage, setErrorMessage] = React.useState<undefined | string>(undefined);

		const [imageUrl, setImageUrl] = React.useState(defaultImageUrl);

		const handleChange = React.useCallback(
			(changedImageUrl?: string) => {
				setImageUrl(changedImageUrl);
				setErrorMessage(undefined);
				onChange?.(changedImageUrl);
			},
			[onChange]
		);

		const handleValidate = React.useCallback(() => {
			const error = !imageUrl;
			if (error) {
				setErrorMessage('Bitte wählen');
				return true;
			} else {
				return false;
			}
		}, [imageUrl]);

		const renderContent = React.useCallback(
			({ dismissPortal }: { dismissPortal: () => void }) => (
				<ImageSelectDialog
					onChange={handleChange}
					defaultImageUrl={defaultImageUrl}
					defaultTab={defaultTab}
					dismissPortal={dismissPortal}
				/>
			),
			[defaultImageUrl, defaultTab, handleChange]
		);

		const handleOpen = React.useCallback(() => {
			Dialog.render({ renderContent, isLocked: true, buttons: [] });
		}, [renderContent]);

		React.useImperativeHandle(
			ref,
			() => ({
				validate: handleValidate,
			}),
			[handleValidate]
		);

		return (
			<FormControlWrapper errorMessage={errorMessage} m={m} width={width}>
				<Button disabled={disabled} onClick={handleOpen} variant="mainButton" fullWidth>
					{label ? label : 'Bild wählen'}
				</Button>
			</FormControlWrapper>
		);
	}
);

export default ImageSelectButton;
