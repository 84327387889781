import * as React from 'react';

import ActiveWrapper from './ActiveWrapper';
import { ClusterPropName, ClusterProps } from '../TargetGroupCluster';
import IntegerRangeSelectForm from '../../../components/IntegerRangeSelectForm';

const optionLabel = {
	statusLevelFilter: {
		label: 'Empfänger müssen Statuslevel haben',
		minimumOnChangePropertyName: 'minStatusLevel',
		maximumOnChangePropertyName: 'maxStatusLevel',
	},
	statusPointsFilter: {
		label: 'Empfänger müssen Statuspunkte haben',
		minimumOnChangePropertyName: 'minStatusPoints',
		maximumOnChangePropertyName: 'maxStatusPoints',
	},
	bonusPointsFilter: {
		label: 'Empfänger müssen Bonuspunkte haben',
		minimumOnChangePropertyName: 'minBonusPoints',
		maximumOnChangePropertyName: 'maxBonusPoints',
	},
};

export type ValueRangeFilterRef = {
	validate: () => boolean;
	scrollIntoView: () => void;
};

const ValueRangeFilter = React.forwardRef<
	ValueRangeFilterRef,
	{
		active?: boolean;
		defaultMinimum: number;
		defaultMaximum?: number;
		onChange: ({ clusterPropName, clusterPropValue }: ClusterProps) => void;
		showFilter: boolean;
		clusterPropName: ClusterPropName;
		disabled?: boolean;
	}
>(
	(
		{ active, defaultMinimum, defaultMaximum, onChange, showFilter, clusterPropName, disabled },
		ref
	) => {
		const { label, minimumOnChangePropertyName, maximumOnChangePropertyName } = optionLabel[
			clusterPropName
		];

		const handleChange = React.useCallback(
			([min, max]: [number | undefined, number | undefined]) => {
				onChange({
					clusterPropName,
					clusterPropValue: {
						[minimumOnChangePropertyName]: min,
						[maximumOnChangePropertyName]: max,
					},
				});
			},
			[clusterPropName, maximumOnChangePropertyName, minimumOnChangePropertyName, onChange]
		);

		const handleToggleActivity = React.useCallback(() => {
			onChange({
				clusterPropName,
				clusterPropValue: {
					active: !active,
				},
			});
		}, [onChange, clusterPropName, active]);

		return showFilter ? (
			<ActiveWrapper
				label={label}
				active={active}
				onHandleToggleActivity={handleToggleActivity}
				disabled={disabled}
			>
				<IntegerRangeSelectForm
					variant="outlined"
					disabled={disabled || !active}
					flex
					onChange={handleChange}
					defaultFrom={defaultMinimum}
					defaultTo={defaultMaximum}
					ref={ref}
				/>
			</ActiveWrapper>
		) : null;
	}
);

export default React.memo(ValueRangeFilter);
