import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

import { ID } from '@cinuru/utils/types';

import client from '../apollo';
import { ApolloError } from 'apollo-client';

const userDetailsFragment = gql`
	fragment UserDetails on User {
		id
		firstName
		lastName
		street
		houseNumber
		zipCode
		city
		email
		blocked
		blockedText
		invoices {
			id
			priceInCents
			date
			url
		}
		subscriptions {
			id
			subscriptionTier {
				id
				name
				description
				details
				priceInCents
			}
			validFrom
			payedUntil
			blocked
			active
			canceled
			blockedReason
			blockedText
		}
		tickets {
			id
			screening {
				id
				movie {
					id
					title
					poster
				}
				datetime
				cinema {
					name
				}
			}
			boughtAt
			status
			onlineTicketingId
			isFlatrateTicket
			qrCode
			auditoriumName
			seats {
				seatName
				rowName
			}
		}
		privileges {
			adminForCinemas {
				id
				name
			}
		}
	}
`;

export const updateUser = async ({
	userId,
	firstName,
	lastName,
	street,
	houseNumber,
	zipCode,
	city,
	email,
	blocked,
	blockedText,
	adminCinemaIds,
}: {
	userId: ID;
	firstName?: string;
	lastName?: string;
	street?: string;
	houseNumber?: string;
	zipCode?: string;
	city?: string;
	email?: string;
	blocked?: boolean;
	blockedText?: string;
	adminCinemaIds?: ID[];
}): Promise<
	{ success: true; error: undefined; user?: User } | { success: false; error?: string; user?: User }
> => {
	try {
		console.log(
			'calling mutation with vars',
			JSON.stringify({
				userId,
				firstname: firstName,
				lastname: lastName,
				street,
				houseNumber,
				zipCode,
				city,
				email,
				blocked,
				blockedText,
				adminCinemaIds,
			})
		);
		const { errors, data } = await client.mutate({
			mutation: gql`
				mutation UpdateUser(
					$userId: ID!
					$blocked: Boolean
					$blockedText: String
					$firstname: String
					$lastname: String
					$street: String
					$houseNumber: String
					$zipCode: String
					$city: String
					$email: String
					$adminCinemaIds: [ID!]
				) {
					updateUser(
						userId: $userId
						blocked: $blocked
						blockedText: $blockedText
						firstname: $firstname
						lastname: $lastname
						street: $street
						houseNumber: $houseNumber
						zipCode: $zipCode
						city: $city
						email: $email
						adminCinemaIds: $adminCinemaIds
					) {
						...UserDetails
					}
				}
				${userDetailsFragment}
			`,
			variables: {
				userId,
				firstname: firstName,
				lastname: lastName,
				street,
				houseNumber,
				zipCode,
				city,
				email,
				blocked,
				blockedText,
				adminCinemaIds,
			},
			errorPolicy: 'all',
		});
		if (errors) {
			return { success: false, error: errors[0]?.message, user: undefined };
		} else return { success: true, error: undefined, user: data.updateUser };
	} catch (e: any) {
		if (e.networkError) return { success: false, error: 'NETWORK_ERROR', user: undefined };
		else throw e;
	}
};

export const updateUserSubscription = async (
	id: ID,
	blocked: boolean,
	blockedText: string
): Promise<{ success: true; error: undefined } | { success: false; error?: string }> => {
	try {
		const { errors } = await client.mutate({
			mutation: gql`
				mutation UpdateUserSubscription($id: ID!, $blocked: Boolean!, $blockedText: String) {
					updateUserSubscription(id: $id, blocked: $blocked, blockedText: $blockedText) {
						id
					}
				}
			`,
			variables: {
				id,
				blocked,
				blockedText,
			},
			errorPolicy: 'all',
			refetchQueries: ['UserById'],
		});
		if (errors) {
			const error = errors[0]?.message;
			return { success: false, error };
		} else return { success: true, error: undefined };
	} catch (e: any) {
		if (e.networkError) return { success: false, error: 'NETWORK_ERROR' };
		else throw e;
	}
};

export type SearchedUser = {
	id: ID;
	firstName: string;
	lastName: string;
	email: string;
	blocked: boolean;
	privileges: {
		adminForCinemas: {
			id;
		}[];
	};
};

export const useSearchUsers = (
	queryText: string
): { data: SearchedUser[]; error?: ApolloError; loading: boolean } => {
	const { data, error, loading } = useQuery(
		gql`
			query SearchUsers($queryText: String!) {
				searchUsers(queryText: $queryText) {
					id
					firstName
					lastName
					email
					blocked
					privileges {
						adminForCinemas {
							id
						}
					}
				}
			}
		`,
		{
			variables: { queryText },
		}
	);
	return React.useMemo(
		() => ({
			data: data?.searchUsers,
			error,
			loading,
		}),
		[data?.searchUsers, error, loading]
	);
};

export type User = {
	id: ID;
	firstName: string;
	lastName: string;
	email: string;
	street?: string;
	houseNumber?: string;
	zipCode?: string;
	city?: string;
	blocked: boolean;
	blockedText?: string;
	invoices: {
		id: ID;
		priceInCents: number;
		date: string;
		url: string;
	}[];
	subscriptions: {
		id: ID;
		subscriptionTier: {
			id: ID;
			name: string;
			description: string;
			details: string;
			priceInCents: number;
		};
		validFrom: string;
		payedUntil: string;
		blocked: boolean;
		active: boolean;
		canceled: boolean;
		blockedReason: string;
		blockedText: string;
	}[];
	tickets: {
		id: ID;
		screening: {
			id: ID;
			auditoriumName?: string;
			movie: {
				id: ID;
				title: string;
				poster: string;
			};
			datetime: string;
			cinema: {
				name: string;
			};
		};
		boughtAt: string;
		status: string;
		onlineTicketingId: string;
		isFlatrateTicket: boolean;
		qrCode: string;
		auditoriumName?: string;
		seats: {
			seatName?: string;
			rowName?: string;
		}[];
	}[];
	privileges: {
		adminForCinemas: {
			id: ID;
			name: string;
		}[];
	};
};

export const useUserById = (id: ID): { data: User; error?: ApolloError; loading: boolean } => {
	const { data, error, loading } = useQuery(
		gql`
			query UserById($id: ID!) {
				userById(id: $id) {
					...UserDetails
				}
			}
			${userDetailsFragment}
		`,
		{
			variables: { id },
		}
	);
	return {
		data: data?.userById,
		error,
		loading,
	};
};
