import * as React from 'react';
import { ThemeProvider as StyledComponentThemeProvider } from 'styled-components';
import { ThemeProvider as StyledNativeComponentsThemeProvider } from 'styled-native-components';
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';

import muiTheme, { cinuruTheme } from '../utils/theme';

const ThemeProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
	return (
		<StyledNativeComponentsThemeProvider
			theme={cinuruTheme}
			rootBackgroundColor={muiTheme.palette.grey[100]}
		>
			<StyledComponentThemeProvider theme={muiTheme}>
				<MUIThemeProvider theme={muiTheme}>{children}</MUIThemeProvider>
			</StyledComponentThemeProvider>
		</StyledNativeComponentsThemeProvider>
	);
};

export default ThemeProvider;
