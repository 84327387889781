import React from 'react';
import { useQuery } from 'react-apollo';
import { default as gql } from 'graphql-tag';
import type { ID } from '@cinuru/utils/types';
import type { EmailElement } from './campaignEmailContent';
import client from '../apollo';
import { useMovie, Movie } from './movie';
import { useMovieList, MovieList } from './movieList';

export const campaignDataFragment = gql`
	fragment campaignDataFragment on Campaign {
		id
		trigger
		name
		type
		channels
		message
		messageTitle
		messageImage
		link
		status
		cinemas {
			id
			name
			bonusProgram {
				id
			}
		}
		language {
			id
			url
			foreignIdType
			programUrl
			trailerAutoplay
		}
		emailContent
		sendingTime
		sendingDate
		startDate
		endDate
		startTime
		endTime
		timeUnit
		timeUnitFactor
		numberOfIntervals
		intervalEndType
		eventType
		emailSubject
		targetGroupIds
		triggerThreshold
	}
`;

export type NewsItem = {
	title?: string;
	message?: string;
	link?: CampaignLink;
	banner?: string;
	poster?: string;
	linkText?: string;
	teaser?: string;
};

export const useNewsItem = (id?: ID): undefined | null | NewsItem => {
	const { data } = useQuery(
		gql`
			query NewsItem($newsItemId: ID!) {
				newsItem(id: $newsItemId) {
					id
					title
					teaser
					body
					link
					image
					poster
					linkText
				}
			}
		`,
		{ variables: { newsItemId: id || '' }, skip: !id }
	);

	return React.useMemo(
		() =>
			!id
				? null
				: data?.newsItem
				? {
						title: data.newsItem.title,
						teaser: data.newsItem.teaser,
						message: data.newsItem.body,
						link: data.newsItem.link,
						poster: data.newsItem.poster,
						banner: data.newsItem.image,
						linkText: data.newsItem.linkText,
				  }
				: undefined,
		[data?.newsItem, id]
	);
};

export const saveNewsItem = async ({
	messageTitle,
	message,
	teaser,
	movieId,
	id,
	poster,
	link,
	image,
	linkText,
}: {
	messageTitle?: string;
	message?: string;
	teaser?: string;
	movieId?: ID;
	id?: ID;
	poster?: string;
	link?: string;
	image?: string;
	linkText?: string;
}): Promise<
	| { error: string; success: false; newsItemId: undefined }
	| { error: null; success: true; newsItemId: string }
> => {
	try {
		const { errors, data } = await client.mutate({
			mutation: gql`
				mutation UpsertNewsItem(
					$id: ID
					$title: String
					$teaser: String
					$body: String
					$movieId: ID
					$poster: String
					$link: String
					$linkText: String
					$image: String
				) {
					result: upsertNewsItem(
						id: $id
						title: $title
						teaser: $teaser
						body: $body
						movieId: $movieId
						poster: $poster
						link: $link
						linkText: $linkText
						image: $image
					) {
						newsItem {
							id
							title
							teaser
							body
							movie {
								id
							}
							poster
							link
							image
							linkText
						}
					}
				}
			`,
			variables: {
				title: messageTitle,
				body: message,
				teaser,
				linkText,
				movieId,
				id,
				poster,
				link,
				image: image,
			},
		});
		if (errors) {
			const error = errors[0]!.message;
			return { success: false, error, newsItemId: undefined };
		} else {
			const newsItemId = data.result.newsItem.id;
			return { success: true, error: null, newsItemId };
		}
	} catch (e: any) {
		if (e.networkError) return { success: false, error: 'NETWORK_ERROR', newsItemId: undefined };
		else throw e;
	}
};

export type Campaign = {
	// general
	id: ID;
	name: string;
	channels: ('EMAIL' | 'PUSH')[];
	status: 'EDITING' | 'ACTIVE' | 'SENT' | 'FAILED' | 'ARCHIVED';
	trigger?:
		| 'SINGLE_EVENT'
		| 'REGULAR'
		| 'BIRTHDAY'
		| 'VISIT'
		| 'FILM_START'
		| 'BOUGHT_TICKET'
		| 'BOUGHT_CONCESSIONS'
		| 'NEWSLETTER_SUBSCRIBED'
		| 'CREATED_ACCOUNT'
		| 'APP_INSTALL'
		| 'JOIN_BONUSPROGRAM'
		| 'POINTS_REACHED'
		| 'STATUSLEVEL_REACHED'
		| 'NO_VISIT';
	// when
	sendingDate?: string;
	sendingTime?: string;
	startDate?: string;
	endDate?: string;
	startTime?: string;
	endTime?: string;
	numberOfIntervals?: number;
	intervalEndType?: 'NEVER' | 'ON' | 'AFTER';
	eventType?: 'BEFORE' | 'AFTER';
	timeUnit?: 'MINUTE' | 'HOUR' | 'DAY' | 'WEEK' | 'MONTH' | 'YEAR';
	timeUnitFactor?: number;
	// email
	emailSubject?: string;
	emailContent?: {
		elements: EmailElement[];
	};
	// push notifications
	link?: string;
	message?: string;
	messageImage?: string;
	messageTitle?: string;
	// other
	cinemas: { id: ID; name: string; bonusProgram?: { id: ID } }[];
	language: {
		id: ID;
		url: string;
		foreignIdType: string;
		programUrl: string;
		trailerAutoplay: boolean;
	};
	bonusProgramIds: ID[];
	targetGroupIds?: ID[];
	triggerThreshold?: string;
};

export type EditedCampaign = {
	name?: Campaign['name'];
	channels?: Campaign['channels'][];
	status?: Campaign['status'];
	trigger?: Campaign['trigger'];
	sendingDate?: Campaign['sendingDate'];
	sendingTime?: Campaign['sendingTime'];
	startDate?: Campaign['startDate'];
	endDate?: Campaign['endDate'];
	startTime?: Campaign['startTime'];
	endTime?: Campaign['endTime'];
	numberOfIntervals?: Campaign['numberOfIntervals'];
	intervalEndType?: Campaign['intervalEndType'];
	eventType?: Campaign['eventType'];
	timeUnit?: Campaign['timeUnit'];
	timeUnitFactor?: Campaign['timeUnitFactor'];
	emailSubject?: Campaign['emailSubject'];
	link?: Campaign['timeUnitFactor'];
	message?: Campaign['message'];
	messageImage?: Campaign['messageImage'];
	messageTitle?: Campaign['messageTitle'];
	cinemas?: Campaign['cinemas'];
	triggerThreshold?: Campaign['triggerThreshold'];
};

export const saveCampaign = async (
	editedCampaign: EditedCampaign
): Promise<{ error: string; success: false } | { error: null; success: true }> => {
	try {
		const { errors } = await client.mutate({
			mutation: gql`
				${campaignDataFragment}
				mutation EditCampaign(
					$id: ID!
					$name: String!
					$channels: [Channel!]!
					$cinemaIds: [ID!]
					$message: String
					$messageTitle: String
					$messageImage: String
					$link: String
					$sendingTime: Time
					$sendingDate: Date
					$sendOnDayXAfterTrigger: Int
					$sendExactlyMinutesAfterTrigger: Int
					$status: CampaignStatus!
					$trigger: CampaignTrigger!
					$startDate: String
					$endDate: String
					$startTime: String
					$endTime: String
					$timeUnit: CampaignTimeUnit
					$timeUnitFactor: Int
					$numberOfIntervals: Int
					$intervalEndType: CampaignIntervalEndType
					$eventType: CampaignEventType
					$emailSubject: String
					$targetGroupIds: [ID!]
					$triggerThreshold: String
				) {
					editCampaign(
						id: $id
						name: $name
						channels: $channels
						cinemaIds: $cinemaIds
						message: $message
						messageTitle: $messageTitle
						messageImage: $messageImage
						link: $link
						sendingTime: $sendingTime
						sendingDate: $sendingDate
						sendOnDayXAfterTrigger: $sendOnDayXAfterTrigger
						sendExactlyMinutesAfterTrigger: $sendExactlyMinutesAfterTrigger
						status: $status
						trigger: $trigger
						startDate: $startDate
						endDate: $endDate
						startTime: $startTime
						endTime: $endTime
						timeUnit: $timeUnit
						timeUnitFactor: $timeUnitFactor
						numberOfIntervals: $numberOfIntervals
						intervalEndType: $intervalEndType
						eventType: $eventType
						emailSubject: $emailSubject
						targetGroupIds: $targetGroupIds
						triggerThreshold: $triggerThreshold
					) {
						campaign {
							...campaignDataFragment
						}
					}
				}
			`,
			variables: editedCampaign,
		});
		if (errors) {
			const error = errors[0]!.message;
			return { success: false, error };
		} else {
			return { success: true, error: null };
		}
	} catch (e: any) {
		if (e.networkError) return { success: false, error: 'NETWORK_ERROR' };
		else throw e;
	}
};

export const useCampaign = (id: ID): Campaign | undefined => {
	const { data } = useQuery(
		gql`
			${campaignDataFragment}
			query($id: ID!) {
				campaign(id: $id) {
					...campaignDataFragment
				}
			}
		`,
		{ variables: { id } }
	);

	return React.useMemo(() => {
		if (data) delete data.__typename;
		return data
			? {
					...data.campaign,
					bonusProgramIds: data.campaign.cinemas
						.map(({ bonusProgram }) => bonusProgram?.id)
						.filter(Boolean),
			  }
			: undefined;
	}, [data]);
};

export const useCampaigns = (): Campaign[] | undefined => {
	const userRes = useQuery(gql`
		query {
			currentUser {
				id
				privileges {
					adminForCinemas {
						id
						name
					}
				}
			}
		}
	`);
	const cinemaIds = React.useMemo(() => {
		return userRes.data?.currentUser?.privileges?.adminForCinemas?.map((c) => c.id) || [];
	}, [userRes]);
	const { data } = useQuery(
		gql`
			${campaignDataFragment}
			query Campaigns($cinemaIds: [ID!]!, $newCampaigns: Boolean) {
				campaignsByCinemas(cinemaIds: $cinemaIds, newCampaigns: $newCampaigns) {
					campaigns {
						...campaignDataFragment
					}
				}
			}
		`,
		{ variables: { cinemaIds, newCampaigns: true }, fetchPolicy: 'cache-and-network' }
	);
	return React.useMemo(() => data?.campaignsByCinemas?.campaigns, [data]);
};

export const createCampaign = async (
	cinemaIds: ID[]
): Promise<
	| { error: string; success: false; campaignId: undefined }
	| { error: null; success: true; campaignId: string }
> => {
	try {
		const { errors, data } = await client.mutate({
			mutation: gql`
				${campaignDataFragment}
				mutation CreateCampaign(
					$name: String!
					$trigger: CampaignTrigger!
					$cinemaIds: [ID!]!
					$isNewCampaign: Boolean
				) {
					result: createCampaign(
						name: $name
						trigger: $trigger
						cinemaIds: $cinemaIds
						isNewCampaign: $isNewCampaign
					) {
						campaign {
							...campaignDataFragment
						}
					}
				}
			`,
			variables: {
				name: 'Neue erstellte Kampagne',
				cinemaIds,
				isNewCampaign: true,
				trigger: 'SINGLE_EVENT',
			},
		});
		if (errors) {
			const error = errors[0]!.message;
			return { success: false, error, campaignId: undefined };
		} else {
			const campaignId = data.result.campaign.id;
			return { success: true, error: null, campaignId };
		}
	} catch (e: any) {
		if (e.networkError) return { success: false, error: 'NETWORK_ERROR', campaignId: undefined };
		else throw e;
	}
};

export const deleteCampaigns = async (
	campaignIds: ID[]
): Promise<{ error: string; success: false } | { error: undefined; success: true }> => {
	try {
		const { errors } = await client.mutate({
			mutation: gql`
				mutation DeleteCampaigns($ids: [ID!]!) {
					deleteCampaigns(ids: $ids) {
						success
					}
				}
			`,
			variables: {
				ids: campaignIds,
			},
			refetchQueries: ['Campaigns'],
		});
		if (errors) {
			const error = errors[0]!.message;
			return { success: false, error };
		} else {
			return { success: true, error: undefined };
		}
	} catch (e: any) {
		if (e.networkError) return { success: false, error: 'NETWORK_ERROR' };
		else throw e;
	}
};

export const updateCampaignsStatus = async (
	campaignIds: ID[],
	newStatus: Campaign['status']
): Promise<{ error: string; success: false } | { error: undefined; success: true }> => {
	try {
		const { errors } = await client.mutate({
			mutation: gql`
				mutation UpdateCampaignsStatus($ids: [ID!]!, $status: CampaignStatus!) {
					updateCampaignsStatus(ids: $ids, status: $status) {
						campaigns {
							...campaignDataFragment
						}
					}
				}
				${campaignDataFragment}
			`,
			variables: {
				ids: campaignIds,
				status: newStatus,
			},
		});
		if (errors) {
			const error = errors[0]!.message;
			return { success: false, error };
		} else {
			return { success: true, error: undefined };
		}
	} catch (e: any) {
		if (e.networkError) return { success: false, error: 'NETWORK_ERROR' };
		else throw e;
	}
};

export type CampaignLinkType =
	| 'NONE'
	| 'APP_VIEW_MOVIE_DETAIL'
	| 'APP_VIEW_STICKERS'
	| 'APP_VIEW_PROGRAM'
	| 'APP_VIEW_VOUCHERS'
	| 'APP_VIEW_PROFILE'
	| 'APP_VIEW_WATCHLIST'
	| 'APP_VIEW_PERKS'
	| 'DEEPLINK'
	| 'WEB_VIEW'
	| 'CUSTOM_APP_VIEW'
	| 'APP_VIEW_BONUSPROGRAM';

export type CampaignLink = `${CampaignLinkType | undefined}:${string | undefined}`;

export const getCampaignLinkTypeAndValue = (
	link?: CampaignLink
): [CampaignLinkType | undefined, string | undefined] => {
	const [linkType, ...rest] = link ? link.split(':') : [];
	const linkValue = rest.join(':') || undefined;
	return [linkType, linkValue] as [CampaignLinkType | undefined, string | undefined];
};
