import React from 'react';

export const removeTypeNames = (objectWithTypeNames: { [key: string]: unknown }) => {
	const cleaned = JSON.parse(
		JSON.stringify(objectWithTypeNames, (name, val) => {
			if (name === '__typename') {
				delete val[name];
			} else {
				return val;
			}
		})
	);

	return cleaned;
};

export const useInitializeStateWithDefault = <T extends any>(
	defaultValue: T,
	finishedInitializing?: boolean
): [T | undefined, React.Dispatch<React.SetStateAction<T | undefined>>] => {
	const [state, setState] = React.useState<T | undefined>(undefined);

	React.useEffect(() => {
		if (finishedInitializing ? finishedInitializing : defaultValue !== undefined) {
			setState(defaultValue);
		}
	}, [finishedInitializing, defaultValue]);

	const result = React.useMemo(() => [state, setState], [state]);
	return result as [T | undefined, React.Dispatch<React.SetStateAction<T | undefined>>];
};

export const getEuroTextAmount = (amount: number) =>
	`${((amount ?? 0) / 100).toLocaleString('de-DE', {
		style: 'currency',
		currency: 'EUR',
	})}`;

export const useLabelAndValue = ({
	items,
	labelKey,
	valueKey,
}: {
	items?: { [key: string]: string | unknown }[] | null;
	labelKey: string;
	valueKey: string;
}): { label: string; value: string }[] | undefined => {
	return React.useMemo(
		() =>
			items?.map(
				(item) =>
					({
						label: typeof item[labelKey] === 'string' ? item[labelKey] : '?unknown',
						value: typeof item[valueKey] === 'string' ? item[valueKey] : '?unknown',
					} as { label: string; value: string })
			),
		[items, labelKey, valueKey]
	);
};

export const useStringArray = (itemOrItems?: string[] | string): string[] => {
	const arrayed = React.useMemo(
		() => (!itemOrItems ? [] : Array.isArray(itemOrItems) ? itemOrItems : [itemOrItems]),
		[itemOrItems]
	);
	return arrayed;
};
