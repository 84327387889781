import React from 'react';
import { useTheme } from 'styled-components';
import { debounce } from 'lodash';
import theme from './theme';

export const stickyHeaderBarHeightRem = 10;

export const remToPx = (rem: number): number =>
	rem * (1 / Number(theme.typography.pxToRem(1).replace('rem', '')));

const scrollMarginTopPx = 16;
export const scrollIntoView = (ref: React.RefObject<HTMLDivElement>): void => {
	ref.current?.scrollIntoView();
	window.scrollBy(0, -1 * (remToPx(stickyHeaderBarHeightRem) + scrollMarginTopPx));
};

export const useDivDimensions = (
	ref: React.RefObject<HTMLDivElement>,
	deps: unknown[] = []
): {
	bottom: number;
	height: number;
	left: number;
	right: number;
	top: number;
	width: number;
} => {
	const [rect, setRect] = React.useState({
		bottom: 0,
		height: 0,
		left: 0,
		right: 0,
		top: 0,
		width: 0,
	});

	const handleMeasure = React.useCallback(() => {
		if (ref?.current) {
			setRect(ref.current.getBoundingClientRect());
		}
	}, [ref]);

	const debouncedHandleMeasure = React.useMemo(() => debounce(handleMeasure, 300), [handleMeasure]);

	React.useEffect(() => {
		window.addEventListener('scroll', debouncedHandleMeasure);
		window.addEventListener('resize', debouncedHandleMeasure);
		handleMeasure();
		return () => {
			window.removeEventListener('scroll', debouncedHandleMeasure);
			window.removeEventListener('resize', debouncedHandleMeasure);
		};
	}, [debouncedHandleMeasure, handleMeasure, ref]);

	React.useEffect(() => {
		handleMeasure();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [...deps, handleMeasure]);

	return rect;
};

export default useDivDimensions;

const getWindowDimensions = () => {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height,
	};
};

export const useWindowDimensions = (): { width: number; height: number } => {
	const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());

	React.useEffect(() => {
		const handleResize = () => {
			setWindowDimensions(getWindowDimensions());
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return windowDimensions;
};

export const useFitsIntoDivWidth = (
	ref: React.RefObject<HTMLDivElement>,
	itemWidthRem: number
): number => {
	const theme = useTheme();
	const pxPerRem = 1 / Number(theme.typography.pxToRem(1).replace('rem', ''));
	const { width: availableWidth } = useDivDimensions(ref);
	const nItemsThatFitInWidth = Math.floor(availableWidth / (itemWidthRem * pxPerRem));
	return nItemsThatFitInWidth;
};

export const useGetPixelFromRem = (rem: number): number => {
	const theme = useTheme();
	const pxPerRem = 1 / Number(theme.typography.pxToRem(1).replace('rem', ''));
	return pxPerRem * rem;
};

export const usePxPerRemFactor = (): number => {
	const theme = useTheme();
	const pxPerRem = 1 / Number(theme.typography.pxToRem(1).replace('rem', ''));
	return pxPerRem;
};

export const useHoverInfo = (): {
	ref: React.RefObject<HTMLElement>;
	isHovered: boolean;
	position: { layerX: number; layerY: number; clientX: number; clientY: number };
} => {
	const ref = React.useRef<HTMLElement>(null);
	const [isHovered, setIsHovered] = React.useState(false);

	const handleMouseOver = () => {
		setIsHovered(true);
	};
	const handleMouseOut = () => {
		setIsHovered(false);
	};

	React.useEffect(() => {
		const node = ref?.current;
		if (node) {
			node.addEventListener('mouseover', handleMouseOver);
			node.addEventListener('mouseout', handleMouseOut);
			return () => {
				node.removeEventListener('mouseover', handleMouseOver);
				node.removeEventListener('mouseout', handleMouseOut);
			};
		}
	}, []);

	const [position, setPosition] = React.useState<{
		layerX: number;
		layerY: number;
		clientX: number;
		clientY: number;
	}>({ layerX: 0, layerY: 0, clientX: 0, clientY: 0 });

	const handleMouseMove = (event) => {
		const { layerX, layerY, clientX, clientY } = event;
		setPosition({ layerX: layerX, layerY, clientX, clientY });
	};

	React.useEffect(() => {
		if (isHovered) {
			const node = ref?.current;
			if (node) {
				node.addEventListener('mousemove', handleMouseMove);
				return () => node.removeEventListener('mousemove', handleMouseMove);
			}
		}
	}, [isHovered]);

	return { ref, isHovered, position };
};
