import * as React from 'react';
import { Route, Redirect } from 'react-router';
import styled from 'styled-components';
import { SnackbarProvider, SnackbarOrigin } from 'notistack';
import CssBaseline from '@mui/material/CssBaseline';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import deLocale from 'date-fns/locale/de';

import {
	LoadingIndicator,
	Heading,
	PortalProvider as PortalProviderCinuruComponents,
} from '@cinuru/components';

import Login from './screens/Login';
import { logout, useUserPrivileges } from './utils/user';
import ThemeProvider from './components/ThemeProvider';
import PortalProvider from './components/PortalProvider';
import NavMenu from './components/NavMenu';
import { getNavTreeForPrivileges, hasPrivilegesForPath, routes } from './routes';

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: stretch;
	width: 100%;
	min-height: 100vh;
	overflow: hidden;
`;

const ContentWrapper = styled.div`
	flex: 1;
	display: flex;
	flex-direction: row;
	justify-content: center;
	height: 100vh;
`;

const RouteWrapper = styled.div`
	flex: 1;
	height: 100%;
	background-color: ${(p) => p.theme.palette.grey[100]};
	@media print {
		margin-left: 1rem;
	}
	overflow: auto;
`;

const Card = styled.div`
	background-color: ${(p) => p.theme.palette.grey[100]};
	padding: 3rem 4rem;
	border-radius: 1rem;
	elevation: 2;
`;

const snackBarProviderProps = {
	maxSnack: 3,
	anchorOrigin: {
		horizontal: 'center',
		vertical: 'bottom',
	} as SnackbarOrigin,
};

const Navigator = () => {
	const privileges = useUserPrivileges();
	return privileges === undefined ? (
		<LoadingIndicator />
	) : privileges ? (
		<React.Fragment>
			<NavMenu links={getNavTreeForPrivileges(privileges)} onLogout={logout} />

			<ContentWrapper>
				<RouteWrapper>
					{routes.map((route) => {
						const { path, exact, redirect, Component } = route;
						return (
							<Route
								key={path}
								exact={Boolean(exact || redirect)}
								path={path}
								// eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
								render={(props) =>
									redirect ? (
										<Redirect to={redirect} />
									) : hasPrivilegesForPath(route, privileges) ? (
										<Component {...props} privileges={privileges} />
									) : (
										<Card>
											<Heading size="xl" align="center">
												{'Sie haben keine Berechtigung\n diese Seite zu öffnen'}
											</Heading>
										</Card>
									)
								}
							/>
						);
					})}
					{/* <Footer /> */}
				</RouteWrapper>
			</ContentWrapper>
		</React.Fragment>
	) : (
		<Login />
	);
};

const App = (): JSX.Element => {
	return (
		<>
			<CssBaseline />
			<ThemeProvider>
				<SnackbarProvider {...snackBarProviderProps}>
					<PortalProviderCinuruComponents>
						<PortalProvider>
							<LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
								<Wrapper>
									<Navigator />
								</Wrapper>
							</LocalizationProvider>
						</PortalProvider>
					</PortalProviderCinuruComponents>
				</SnackbarProvider>
			</ThemeProvider>
		</>
	);
};

export default App;
