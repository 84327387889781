import { Brand } from 'utils/theme';

type ApiEndPoint =
	| 'http://localhost:3000'
	| 'https://api.cinuru.com'
	| 'https://devapi.cinuru.com/dev';
type FileServerEndPoint = 'http://localhost:3006' | 'https://static.cinuru.com';
type Feature =
	| 'NEW_CAMPAIGNING'
	| 'NEW_STATISTICS'
	| 'NEW_EDIT_CINEMA'
	| 'TARGETGROUP_EDIT'
	| 'USER_EDIT'
	| 'CINFINITY_STATS'
	| 'PAYOUT_OVERVIEW'
	| 'BONUSPROGRAM';

export const API_ENDPOINT: ApiEndPoint =
	(process.env.API_ENDPOINT as ApiEndPoint) || 'https://api.cinuru.com';
export const FILE_SERVER_ENDPOINT: FileServerEndPoint =
	(process.env.FILE_SERVER_ENDPOINT as FileServerEndPoint) || 'https://static.cinuru.com';
export const FEATURES: Feature[] = ((process.env.FEATURES &&
	(JSON.parse(process.env.FEATURES) as unknown)) as Feature[]) || ['NEW_EDIT_CINEMA'];

export const brand: Brand = (process.env.BRAND as Brand) || 'CINURU';

console.log('FEATURES: ', FEATURES);
console.log('API_ENDPOINT', API_ENDPOINT);
