import * as React from 'react';
import { Grid, Box } from '@mui/material';

import ActiveWrapper from './ActiveWrapper';
import IntegerTextField, { IntegerTextFieldRef } from '../../../components/IntegerTextField';
import { ClusterPropName, ClusterProps } from '../TargetGroupCluster';

export type DistanceFilterRef = {
	validate: () => boolean;
};

const DistanceFilter = React.forwardRef<
	DistanceFilterRef,
	{
		defaultDistanceInKm: number;
		defaultZipCode: number;
		active?: boolean;
		onChange: ({ clusterPropName, clusterPropValue }: ClusterProps) => void;
		showFilter: boolean;
		clusterPropName: ClusterPropName;
		disabled?: boolean;
	}
>(
	(
		{
			defaultDistanceInKm,
			defaultZipCode,
			active,
			onChange,
			showFilter,
			clusterPropName,
			disabled,
		},
		ref
	): JSX.Element | null => {
		const handleChangeZip = React.useCallback(
			(newValue?: number) => {
				onChange({
					clusterPropName,
					clusterPropValue: {
						zipCode: newValue,
					},
				});
			},
			[onChange, clusterPropName]
		);
		const handleChangeDistance = React.useCallback(
			(newValue?: number) => {
				onChange({
					clusterPropName,
					clusterPropValue: {
						distanceInKm: newValue,
					},
				});
			},
			[onChange, clusterPropName]
		);

		const handleToggleActivity = React.useCallback(() => {
			onChange({
				clusterPropName,
				clusterPropValue: {
					active: !active,
				},
			});
		}, [onChange, clusterPropName, active]);

		const zipCodeRef = React.useRef<IntegerTextFieldRef>(null);
		const kmRef = React.useRef<IntegerTextFieldRef>(null);

		const handleValidate = React.useCallback(() => {
			const errors = !active ? [] : [zipCodeRef, kmRef].map((r) => r.current?.validate());
			const invalid = errors.some(Boolean);
			return invalid;
		}, [active]);

		React.useImperativeHandle(
			ref,
			() => ({
				validate: handleValidate,
			}),
			[handleValidate]
		);

		const isDisabled = disabled || !active;

		return showFilter ? (
			<ActiveWrapper
				label="Empfänger müssen in folgendem Umkreis zum Kino wohnhaft sein:"
				active={active}
				onHandleToggleActivity={handleToggleActivity}
				disabled={disabled}
			>
				<>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<IntegerTextField
								label="PLZ"
								defaultValue={defaultZipCode}
								onChange={handleChangeZip}
								variant="outlined"
								disabled={isDisabled}
								min={0}
								max={99999}
								ref={zipCodeRef}
							></IntegerTextField>
						</Grid>
						<Grid item xs={12} sm={6}>
							<IntegerTextField
								label="km"
								defaultValue={defaultDistanceInKm}
								onChange={handleChangeDistance}
								variant="outlined"
								disabled={isDisabled}
								min={0}
								ref={kmRef}
							></IntegerTextField>
						</Grid>
					</Grid>
				</>
			</ActiveWrapper>
		) : null;
	}
);

export default React.memo(DistanceFilter);
