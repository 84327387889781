import * as React from 'react';
import styledWeb from 'styled-components';
import { Accordion, AccordionDetails, AccordionSummary, Typography, Box } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';

const StyledAccordionSummary = styledWeb(AccordionSummary)<{
	expanded: 'true' | 'false';
	color?: string;
}>((p) => ({
	borderBottomColor: p.theme.palette.grey[200],
	borderBottomStyle: 'solid',
	borderBottomWidth: p.expanded === 'true' ? 1 : 0,
}));

export type ExpandableRef = {
	expand: () => void;
	collapse: () => void;
};

const Expandable = React.forwardRef<
	ExpandableRef,
	{
		label: string;
		children?: JSX.Element | JSX.Element[];
		renderContent?: (disabled?: boolean) => JSX.Element;
		margin?: string;
		padding?: string;
		defaultExpanded?: boolean;
		width?: string;
		disabled?: boolean;
	}
>(
	(
		{ label, children, renderContent, margin, padding, defaultExpanded = false, width, disabled },
		ref
	): JSX.Element => {
		const [expandedState, setExpandedState] = React.useState<boolean>(defaultExpanded);
		const handleToggleExpanded = React.useCallback(() => {
			setExpandedState((b) => !b);
		}, []);

		React.useImperativeHandle(ref, () => ({
			expand: () => setExpandedState(true),
			collapse: () => setExpandedState(false),
		}));

		return (
			<Box m={margin} width={width}>
				<Accordion expanded={expandedState} onChange={handleToggleExpanded}>
					<StyledAccordionSummary
						expanded={expandedState.toString() as 'true' | 'false'}
						expandIcon={<KeyboardArrowDown />}
					>
						<Typography>{label}</Typography>
					</StyledAccordionSummary>
					{children ? (
						<Box component={AccordionDetails} padding={padding}>
							{children}
						</Box>
					) : renderContent ? (
						<Box component={AccordionDetails} padding={padding}>
							{renderContent(disabled)}
						</Box>
					) : null}
				</Accordion>
			</Box>
		);
	}
);

export default Expandable;
