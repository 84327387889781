import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';
import type { ID } from '@cinuru/utils/types';

import Dialog from '../../components/Dialog';
import StickyHeaderWrapper from '../../components/StickyHeaderWrapper';
import { useCinemaIds } from '../../utils/cinema';
import {
	createCampaign,
	deleteCampaigns,
	updateCampaignsStatus,
	useCampaigns,
} from '../../utils/campaign';
import type { Campaign } from '../../utils/campaign';
import TabTables from '../../components/TabTables';
import { Actions } from '../../components/Table';
import { IconName as ButtonIconName } from '../../components/Button';
import { IconName as IconButtonIconName } from '../../components/IconButton';

export type ActionType = 'DEACTIVATE' | 'DELETE' | 'ARCHIVE';

const cols = [
	{
		id: 1,
		label: 'Kampagne',
	},
	{
		id: 2,
		label: 'Typ',
	},
	{
		id: 3,
		label: 'Zeitraum',
	},
	{
		id: 4,
		label: 'Status',
	},
	{
		id: 5,
		label: 'Details',
	},
];

const itemStatusDictionary = {
	EDITING: 'In Bearbeitung',
	ACTIVE: 'Aktiv',
	SENT: 'Beendet',
	FAILED: 'Gescheitert',
	ARCHIVED: 'Archiviert',
};

const formatDateString = (dateString?: string): string => {
	return !dateString ? '' : format(new Date(dateString), 'dd.MM.yyyy');
};

const formatTimeString = (timeString?: string): string => {
	return !timeString ? '' : timeString.slice(0, 5);
};

const getCampaignTriggerLabel = (itemTrigger: Campaign['trigger']) => {
	return itemTrigger === 'SINGLE_EVENT'
		? 'Einmalig'
		: itemTrigger === 'REGULAR'
		? 'Wiederkehrend'
		: 'Eventbasiert';
};

const getCampaignTimeRange = (item: Campaign): string => {
	const sendingDate = formatDateString(item.sendingDate);
	const sendingTime = formatTimeString(item.sendingTime);
	const startDate = formatDateString(item.startDate);
	const endDate = formatDateString(item.endDate);
	switch (item.trigger) {
		case 'SINGLE_EVENT':
			return sendingDate && sendingTime ? `${sendingDate} ${sendingTime}` : '';
		case 'REGULAR': {
			const from = startDate || '';
			const to =
				item.intervalEndType === 'NEVER'
					? 'endet nie'
					: item.intervalEndType === 'ON'
					? endDate
					: item.intervalEndType === 'AFTER'
					? `endet nach ${item.numberOfIntervals} Intervallen`
					: '';
			return `${from} ${to ? `- ${to}` : ''}`;
		}
		default:
			return `${startDate} - ${endDate}`;
	}
};
const tabFilters = [
	{ label: 'Alle', filterFunction: () => true },
	{ label: 'Aktiv', filterFunction: (rowItem) => rowItem.rawData.status === 'ACTIVE' },
	{ label: 'In Bearbeitung', filterFunction: (rowItem) => rowItem.rawData.status === 'EDITING' },
	{ label: 'Beendet', filterFunction: (rowItem) => rowItem.rawData.status === 'SENT' },
	{ label: 'Archviert', filterFunction: (rowItem) => rowItem.rawData.status === 'ARCHIVED' },
] as { label: string; filterFunction: (rowItem) => boolean }[];

const actions: Actions<ActionType> = {
	allActions: [
		{
			label: 'Löschen',
			type: 'DELETE',
		},
		{
			label: 'Archivieren',
			type: 'ARCHIVE',
		},
		{
			label: 'Deaktivieren',
			type: 'DEACTIVATE',
		},
	],
	getPossibleActionTypes: (selectedRowIds, rowItems) => {
		const getActions = (itemStatus?: Campaign['status']): ActionType[] => {
			switch (itemStatus) {
				case 'EDITING':
					return ['DELETE', 'ARCHIVE'];
				case 'ACTIVE':
					return ['DEACTIVATE'];
				case 'SENT':
					return [];
				case 'FAILED':
					return ['DELETE', 'ARCHIVE'];
				case 'ARCHIVED':
					return ['DELETE'];
				default:
					return [];
			}
		};

		const possibleItemActions = selectedRowIds?.map((rId) => {
			return getActions(rowItems?.find((rowItem) => rowItem.id === rId)?.rawData.status);
		});

		if (possibleItemActions?.length) {
			const result = possibleItemActions.reduce((acc: ActionType[], next: ActionType[]) => {
				const overlap: ActionType[] = [];
				acc.forEach((status) =>
					next.includes(status) ? overlap.push(status as ActionType) : null
				);
				return overlap;
			});
			return result;
		} else {
			return [];
		}
	},
};

const CampaignsNew = (): JSX.Element | null => {
	const history = useHistory();

	const campaigns = useCampaigns();
	const cinemaIds = useCinemaIds();

	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = React.useState(false);

	const handleDeleteCampaigns = React.useCallback(
		async (ids) => {
			setLoading(true);
			const { success } = await deleteCampaigns(ids);
			setLoading(false);
			if (success) {
				enqueueSnackbar('Gelöscht', { variant: 'success' });
			} else {
				enqueueSnackbar('Fehler', { variant: 'error' });
			}
		},
		[enqueueSnackbar]
	);

	const handleUpdateCampaignStatus = React.useCallback(
		async (ids: ID[], newStatus: Campaign['status']) => {
			setLoading(true);
			const { success } = await updateCampaignsStatus(ids, newStatus);
			setLoading(false);
			if (success) {
				enqueueSnackbar(
					newStatus === 'EDITING'
						? 'Deaktiviert'
						: newStatus === 'ARCHIVED'
						? 'Archiviert'
						: 'Erfolg',
					{ variant: 'success' }
				);
			} else {
				enqueueSnackbar('Fehler', { variant: 'error' });
			}
		},
		[enqueueSnackbar]
	);

	const handleAction = React.useCallback(
		async (actionType: ActionType, ids: ID[]) => {
			if (actionType === 'DELETE') {
				Dialog.render({
					title: 'Wirklich löschen',
					description: `Willst du diese ${
						ids.length === 1 ? 'Kampagne' : `${ids.length} Kampagnen`
					} wirklich löschen?`,
					buttons: [
						{ id: '1', label: 'Ja', onClick: () => handleDeleteCampaigns(ids) },
						{ id: '2', label: 'Abbrechen' },
					],
				});
			} else if (actionType === 'DEACTIVATE') {
				Dialog.render({
					title: 'Wirklich deaktivieren',
					description: `Willst du diese ${
						ids.length === 1 ? 'Kampagne' : `${ids.length} Kampagnen`
					} wirklich deaktivieren?`,
					buttons: [
						{ id: '1', label: 'Ja', onClick: () => handleUpdateCampaignStatus(ids, 'EDITING') },
						{ id: '2', label: 'Abbrechen' },
					],
				});
			} else if (actionType === 'ARCHIVE') {
				handleUpdateCampaignStatus(ids, 'ARCHIVED');
			}
		},
		[handleUpdateCampaignStatus, handleDeleteCampaigns]
	);

	const handleCreateCampaign = React.useCallback(async () => {
		const { success, error, campaignId } = await createCampaign(cinemaIds!);
		if (success) {
			enqueueSnackbar('Kampagne erstellt', { variant: 'success' });
			history.push(`/marketing/campaigns/${campaignId}/edit`);
		} else {
			enqueueSnackbar(error, { variant: 'error' });
		}
	}, [cinemaIds, enqueueSnackbar, history]);

	const rows = React.useMemo(
		() =>
			campaigns?.map((campaign) => {
				const { id, name, channels, trigger, status } = campaign;
				return {
					id,
					data: [
						{ text: name, chips: channels },
						{ text: getCampaignTriggerLabel(trigger) },
						{ text: getCampaignTimeRange(campaign) },
						{ text: itemStatusDictionary[status] },
						{
							buttonLabel: 'Bearbeiten',
							onPress: () => history.push(`/marketing/campaigns/${id}/edit`),
						},
					],
					rawData: campaign,
				};
			}),
		[campaigns, history]
	);

	const buttons = React.useMemo(
		() => [
			{
				label: 'Kampagne erstellen',
				onClick: handleCreateCampaign,
				startIconName: 'Add' as ButtonIconName,
				loading,
				loadingText: 'Aktivieren...',
				collapsedIconName: 'Add' as IconButtonIconName,
			},
		],
		[handleCreateCampaign, loading]
	);

	const isInitialLoading = !campaigns || !cinemaIds || !rows;
	const isLoading = isInitialLoading || loading;

	return (
		<StickyHeaderWrapper
			maxContentWidth="130rem"
			label="Kampagnen"
			buttons={buttons}
			isLoading={isLoading}
		>
			{isInitialLoading ? null : (
				<TabTables
					columns={cols}
					rows={rows}
					tabFilters={tabFilters}
					onHandleAction={handleAction}
					label="Kampagne"
					actions={actions}
				/>
			)}
		</StickyHeaderWrapper>
	);
};

export default CampaignsNew;
