import React from 'react';
import { Language } from '../utils/language';
import { CampaignLinkType } from '../utils/campaign';

const cineplexOnlyLinkTypes: CampaignLinkType[] = [
	'APP_VIEW_PERKS',
	'APP_VIEW_STICKERS',
	'APP_VIEW_VOUCHERS',
];
const basicLinkTypes: CampaignLinkType[] = [
	'NONE',
	'APP_VIEW_MOVIE_DETAIL',
	'APP_VIEW_PROGRAM',
	'APP_VIEW_WATCHLIST',
	'APP_VIEW_PROFILE',
	'APP_VIEW_BONUSPROGRAM',
	'WEB_VIEW',
	'DEEPLINK',
];

export const useAvailableLinkTypes = (language: Language) => {
	return React.useMemo(
		() =>
			language === 'de-cineplex' ? [...cineplexOnlyLinkTypes, ...basicLinkTypes] : basicLinkTypes,
		[language]
	);
};

export const linkTypesThatRequireBonusprogramId: CampaignLinkType[] = [
	'APP_VIEW_BONUSPROGRAM',
	'APP_VIEW_STICKERS',
	'APP_VIEW_PERKS',
	'APP_VIEW_VOUCHERS',
];
