import React from 'react';
import styled from 'styled-components';
import { Typography, Box } from '@mui/material';
import type { TypographyProps } from '@mui/material/Typography';

const StyledTypography = styled(Typography).attrs<{ $color?: string; $disabled?: boolean }>(
	(p) => ({
		color: p.$color ? p.$color : p.$disabled ? p.theme.customColors.disabledTextGrey : '',
	})
)``;
interface TxtProps extends TypographyProps {
	label?: string;
	m?: string;
	p?: string;
	color?: string;
	disabled?: boolean;
}

const Txt = ({ label, m, p, color, disabled, children, ...rest }: TxtProps): JSX.Element => {
	return (
		<Box m={m} p={p}>
			<StyledTypography $color={color} $disabled={disabled} {...rest}>
				{label || children}
			</StyledTypography>
		</Box>
	);
};

export default Txt;
