import React from 'react';
import { useHistory, useLocation } from 'react-router';

export const useDebouncedSearchText = (initialValue: string, delay = 500) => {
	const [queryText, setQueryText] = React.useState(initialValue);
	const [debouncedQueryText, setDebouncedQueryText] = React.useState(initialValue);

	const { search } = useLocation();
	const history = useHistory();

	React.useEffect(() => {
		const timeoutId = setTimeout(() => {
			setDebouncedQueryText(queryText);
			if (!queryText) {
				history.replace({
					search: '',
				});
				return;
			}
			history.replace({
				search: `?search=${queryText}`,
			});
		}, delay);
		return () => clearTimeout(timeoutId);
	}, [delay, history, queryText]);

	React.useEffect(() => {
		const query = new URLSearchParams(search).get('search');
		if (query) {
			setQueryText(query);
		}
	}, [search, setQueryText]);

	return {
		queryText,
		debouncedQueryText,
		setQueryText,
	};
};
