import React from 'react';
import styled from 'styled-components';
import { Box, Typography } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField';
import { useSnackbar } from 'notistack';

import { ID } from '@cinuru/utils/types';

import useTextFieldContoller from '../../utils/useTextFieldController';
import EditorFieldWrapper from '../../components/EditorFieldWrapper';
import NotificationPreviewer from '../../components/NotificationPreviewer';
import ImageSelectButton, { ImageSelectButtonRef } from '../../components/ImageSelectButton';
import Tooltip from '../../components/Tooltip';
import { FALLBACK_BANNER } from '../../utils/constants';
import { CampaignLink, getCampaignLinkTypeAndValue } from '../../utils/campaign';
import { sendTestNotification, Language } from '../../utils/notification';

const sendTestNotificationErrorDict = {
	TOKEN_NOT_REGISTERED: 'Device token ungültig',
	INVALID_IMAGE: 'Bild ungültig',
	UNEXPECTED_ERROR: 'Unerwarteter Fehler',
	NETWORK_ERROR: 'Netzwerkfehler',
	IVNALID_PAYLOAD: 'Ungültiger Nachrichteninhalt',
};

const Row = styled(Box)`
	display: flex;
	flex-direction: row;
	width: 100%;
`;

const Column = styled(Box)`
	display: flex;
	flex-direction: column;
	align-self: stretch;
`;

export type PushMessageEditorRef = {
	validate: () => boolean;
};

const PushMessageEditor = React.forwardRef<
	PushMessageEditorRef,
	{
		defaultMessageTitle?: string;
		defaultMessage?: string;
		defaultMessageImage?: string;
		onChange: (key: string, value?: string | number | null) => void;
		variables?: { name: string; label: string; inputLength: number }[];
		disabled?: boolean;
		variant?: TextFieldProps['variant'];
		link?: CampaignLink;
		language: Language;
		campaignCinemaIds: ID[];
		onValidatePushNotification: () => boolean;
	}
>(
	(
		{
			defaultMessageTitle,
			defaultMessage,
			defaultMessageImage,
			onChange,
			variables,
			disabled,
			variant,
			link,
			language,
			campaignCinemaIds,
			onValidatePushNotification,
		},
		ref
	): JSX.Element => {
		const { enqueueSnackbar } = useSnackbar();

		const {
			textInputProps: messageTitleProps,
			validate: validateMessageTitle,
			newStateValue: messageTitle,
		} = useTextFieldContoller({
			defaultValue: defaultMessageTitle,
			inputLabel: 'Push-Titel',
			stateKey: 'messageTitle',
			limit: 35,
			validationFunction: (value) => (!value ? 'Bitte ausfüllen' : undefined),
			stateValueFallback: '',
			onChange: (newStatValue) => {
				onChange('messageTitle', newStatValue);
			},
		});

		const {
			textInputProps: messageProps,
			validate: validateMessage,
			newStateValue: message,
		} = useTextFieldContoller({
			defaultValue: defaultMessage,
			inputLabel: 'Push-Body',
			stateKey: 'message',
			limit: 120,
			validationFunction: (value) => (!value ? 'Bitte ausfüllen' : undefined),
			stateValueFallback: '',
			onChange: (newStatValue) => {
				onChange('message', newStatValue);
			},
		});

		const [image, setImage] = React.useState(defaultMessageImage || FALLBACK_BANNER);

		const handleChangeImage = React.useCallback(
			(newImage) => {
				setImage(newImage);
				onChange('messageImage', newImage);
			},
			[onChange]
		);

		const imageSelectButtonRef = React.useRef<ImageSelectButtonRef>(null);

		const handleValidate = React.useCallback(() => {
			const hasValidationErrors = [
				validateMessage!(),
				validateMessageTitle!(),
				imageSelectButtonRef.current?.validate(),
			].some(Boolean);

			if (hasValidationErrors) {
				return true;
			} else {
				return false;
			}
		}, [validateMessage, validateMessageTitle]);

		React.useImperativeHandle(
			ref,
			() => ({
				validate: handleValidate,
			}),
			[handleValidate]
		);

		React.useEffect(() => {
			if (image === FALLBACK_BANNER) {
				onChange('messageImage', image);
			}
		}, [image, onChange]);

		// test notification
		const [linkType, linkValue] = getCampaignLinkTypeAndValue(link);
		const [loading, setLoading] = React.useState(false);
		const handleSendTestNotification = React.useCallback(async () => {
			const invalid = onValidatePushNotification();
			if (invalid) {
				enqueueSnackbar('Es fehlen Einträge.', { variant: 'error' });
				return;
			}
			setLoading(true);
			const { success, error } = await sendTestNotification({
				title: messageTitle!,
				body: message!,
				link: `${linkType}:${linkValue}`,
				imageUrl: image!,
				inAppNotification: false,
				pushNotificationChannel: 'CINEMA_NEWS',
				language,
				cinemaIds: campaignCinemaIds,
			});
			if (success) {
				enqueueSnackbar('Gesendet!', { variant: 'success' });
			} else {
				enqueueSnackbar('Fehler beim Senden: ' + sendTestNotificationErrorDict[error], {
					variant: 'error',
				});
			}
			setLoading(false);
		}, [
			campaignCinemaIds,
			enqueueSnackbar,
			image,
			language,
			linkType,
			linkValue,
			message,
			messageTitle,
			onValidatePushNotification,
		]);

		return (
			<Row>
				<Column width="50%">
					<Row alignItems="center" m="0 0 2rem 0">
						<Typography variant="h6">Push-Nachricht</Typography>
						<Tooltip text="In diesem Abschnitt wird definiert, wie die Push-Nachricht, die der Nutzer auf sein Smartphone gesendet bekommt, aussieht. Falls der Nutzer Push-Nachrichten für die App deaktiviert hat, werden diese nur während der Nutzung der App angezeigt." />
					</Row>

					<EditorFieldWrapper
						{...messageTitleProps}
						onChange={messageTitleProps.onChange as (value: string) => void}
						markdown={false}
						numberOfLines={1}
						variant={variant}
						disabled={disabled}
					/>
					<EditorFieldWrapper
						{...messageProps}
						onChange={messageProps.onChange as (value: string) => void}
						margin="2rem 0 0"
						markdown={false}
						numberOfLines={2}
						variant={variant}
						disabled={disabled}
					/>

					<ImageSelectButton
						defaultImageUrl={image}
						onChange={handleChangeImage}
						placeHolderImageUrl={null}
						ref={imageSelectButtonRef}
						disabled={disabled}
						m="1rem 0 0 0"
					/>
				</Column>
				<Column width="50%">
					<NotificationPreviewer
						messageTitle={messageTitleProps.value}
						message={messageProps.value}
						messageImage={image}
						showPlaceHolderText={true}
						onSendTestNotification={handleSendTestNotification}
						sendTestNotificationLoading={loading}
					/>
				</Column>
			</Row>
		);
	}
);

export default PushMessageEditor;
