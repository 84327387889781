import React from 'react';
import styled from 'styled-native-components';
import styledWeb from 'styled-components';
import { NavLink } from 'react-router-dom';
import CinfinityLogo from '../images/cinfinitylogo.svg';

import { Icon, CinuruName, Paragraph, Label } from '@cinuru/components';
import { transparentize } from '../utils/color';
import { brand } from '../consts';

const Wrapper = styledWeb.div`
	background: ${(p) => p.theme.palette.primary.main};
	height: 100vh;
	align-items: center;
	min-width: 22rem;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	overflow: auto;
	box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.4);
			z-index: 4;
	@media print {
		display: none;
	}
	&::-webkit-scrollbar { 
		display: none; 
	}
	@-moz-document url-prefix() {
		overflow-y: auto;
	}
`;

const LogoWrapper = styled.View`
	flex-direction: row;
	align-items: center;
	padding: 0 2.5rem;
	margin: 3rem 0;
`;

const SectionWrapper = styled.View`
	flex-direction: column;
	margin: 2rem 0;
`;

const SectionTitleWrapper = styled.View`
	padding: 0 2.5rem;
	flex-direction: row;
	align-items: center;
	height: 3rem;
	opacity: 0.85;
`;

const Link = styledWeb(NavLink).attrs((p) => ({
	activeStyle: {
		background: transparentize(0.85, p.theme.palette.common.white),
	},
}))`
	text-decoration: none;
	padding: 0.5rem 2.5rem;
	width: 100%;
	color: ${(p) => p.theme.palette.common.white};
	font-weight: 600;
	&:hover,
	&:active,
	&:focus {
		color: ${(p) => p.theme.palette.common.white};
		background-color: ${(p) => transparentize(0.85, p.theme.palette.common.white)};
	}
`;

const Spacer = styled.View`
	flex: 1;
`;

const LogoutButton = styled.TouchableOpacity`
	padding: 1.5rem 0;
`;

const Logo = () => {
	switch (brand) {
		case 'CINFINITY-WEB':
			return <img src={CinfinityLogo} />;
		default:
			return (
				<>
					<Icon size="4.5rem" name="logo" color="$accentText0" margin="0rem -0.25rem" />
					<CinuruName height="2.5rem" color="$accentText0" />
				</>
			);
	}
};

const NavMenu = ({ links, onLogout }) => (
	<Wrapper>
		<LogoWrapper>
			<Logo />
		</LogoWrapper>
		{links.map((section) => (
			<SectionWrapper key={section.label}>
				<SectionTitleWrapper>
					<Icon
						name={section.iconName}
						size="2rem"
						margin="0 0.5rem 0 -0.25rem"
						color="$accentText0"
					/>
					<Paragraph size="s" bold color="$accentText0">
						{section.label.toUpperCase()}
					</Paragraph>
				</SectionTitleWrapper>
				{section.items.map(({ path, label }) => (
					<Link key={path} to={path}>
						<Label size="s" light color="$accentText0">
							{label}
						</Label>
					</Link>
				))}
			</SectionWrapper>
		))}
		<Spacer />
		<LogoutButton onPress={onLogout}>
			<SectionTitleWrapper>
				<Icon name="logOut" size="2rem" margin="0rem 0.5rem 0rem -0.25rem" color="$accentText0" />
				<Paragraph size="s" bold color="$accentText0">
					AUSLOGGEN
				</Paragraph>
			</SectionTitleWrapper>
		</LogoutButton>
	</Wrapper>
);

export default NavMenu;
