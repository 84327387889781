import React from 'react';
import { ID } from '@cinuru/utils/types';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';

export type SubscriptionTier = {
	id: ID;
	name: string;
	description: string;
	details: string;
	price: number;
	paypalBillingPlanId: string;
};

export const useSubscriptionTiers = (): { data: SubscriptionTier[]; error; loading } => {
	const { data, error, loading } = useQuery(
		gql`
			query SubscriptionTiers {
				subscriptionTiers {
					id
					name
					description
					details
					price
					paypalBillingPlanId
				}
			}
		`
	);
	return React.useMemo(
		() => ({ data: data?.subscriptionTiers, error, loading }),
		[data?.subscriptionTiers, error, loading]
	);
};
