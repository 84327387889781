import * as React from 'react';
import styled from 'styled-components';
import { Checkbox, Box } from '@mui/material';

import PseudoOutlinedTextFieldWrapper from '../../../components/PseudoOutlinedTextFieldWrapper';

const Row = styled(Box)`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`;

const ActiveWrapper = ({
	active,
	children,
	label,
	m = '0 0 4rem 0',
	p = '4rem 2rem 2rem',
	onHandleToggleActivity,
	justifyContent,
	disabled,
}: {
	active?: boolean;
	children;
	label: string;
	m?: string;
	p?: string;
	onHandleToggleActivity: () => void;
	justifyContent?: string;
	disabled?: boolean;
}): JSX.Element | null => {
	return (
		<Row m={m} fontSize="2rem">
			<Checkbox
				color="primary"
				onChange={onHandleToggleActivity}
				checked={active}
				disabled={disabled}
			/>
			<PseudoOutlinedTextFieldWrapper
				justifyContent={justifyContent}
				label={label}
				disabled={!active}
				isHighlighted={disabled ? false : active}
				p={p}
			>
				{children}
			</PseudoOutlinedTextFieldWrapper>
		</Row>
	);
};

export default React.memo(ActiveWrapper);
