/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import styledWeb from 'styled-components';
import { Box, Typography, IconButton } from '@mui/material';
import { useTheme as useMuiTheme } from '@mui/material/styles';
import { KeyboardArrowDown, KeyboardArrowUp, Image as ImageIcon } from '@mui/icons-material';
import Image from '../components/Image';
import 'react-device-frameset/lib/css/marvel-devices.min.css';

import DeviceFrame from '../screens/CampaigningNew/DeviceFrame';

const TxtLimit = styledWeb.div<{ numberOfLines: number }>`
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: ${(p) => p.numberOfLines};
	-webkit-box-orient: vertical;
`;

const NotificationPreviewer = ({
	zoom,
	margin = '2rem 0rem 1rem',
	appImage,
	messageTitle,
	message,
	messageImage,
	appName = 'Appname',
	minutesElapsed = 1,
	showPlaceHolderText,
	placeHolderMessageTitle = 'Push-Notification Title',
	placeHolderMessage = 'Lorem Ipsum is simply dummy text of the printing and  typesetting industry. Lorem Ipsum has been the industry’s standard dummy  text ever since the 1500s, when an unknown printer took a galley of  type and scrambled it to make a type specimen book',
	defaultOs = 'android',
	onSendTestNotification,
	sendTestNotificationLoading,
}: {
	zoom?: number;
	margin?: string;
	appImage?: string;
	messageTitle?: string;
	message?: string;
	messageImage?: string;
	appName?: string;
	minutesElapsed?: number;
	showPlaceHolderText?: boolean;
	placeHolderMessageTitle?: string;
	placeHolderMessage?: string;
	defaultOs?: 'ios' | 'android';
	onSendTestNotification: () => void;
	sendTestNotificationLoading?: boolean;
}) => {
	const muiTheme = useMuiTheme();

	const [collapsed, setCollapsed] = React.useState(true);
	const handleToggle = React.useCallback(() => {
		setCollapsed((b) => !b);
	}, []);

	const calculateHeightFactor = React.useCallback(
		(os) => {
			return os === 'android' ? (collapsed ? 0.25 : 0.4) : 0.25;
		},
		[collapsed]
	);

	const renderContent = React.useCallback(
		({ os }) =>
			os === 'ios' ? (
				<Box m="3.5rem 1rem 1rem" display="flex" flexDirection="column">
					<Box
						borderRadius="2rem"
						margin="1rem 0rem"
						maxWidth="100%"
						display="flex"
						flexDirection="column"
						alignItems="center"
						padding="1.5rem"
						boxShadow={3}
						bgcolor={muiTheme.palette.grey[50]}
					>
						<Box width="100%" display="flex" alignItems="center" flexDirection="row">
							<Box m="1rem 1.5rem 1rem 0">
								<ImageIcon />
								{/* <Image
									m="1rem 1.5rem 1rem 0"
									borderRadius="1rem"
									width="40px"
									height="40px"
									src={messageImage}
									objectFit="cover"
								/> */}
							</Box>
							<Box width="100%" flexDirection="column">
								<Box display="flex" flexDirection="row" justifyContent="space-between">
									<Typography fontWeight="bold" fontSize="14px">
										{!messageTitle && showPlaceHolderText ? placeHolderMessageTitle : messageTitle}
									</Typography>
									<Typography
										fontSize="14px"
										// eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
										sx={{ color: muiTheme.palette.grey[600] }}
									>{`Vor ${minutesElapsed} Min.`}</Typography>
								</Box>
								<Box width="100%" flexDirection="row" display="flex">
									<Box flex="1">
										<TxtLimit numberOfLines={4}>
											<Typography fontSize="14px">
												{!message && showPlaceHolderText ? placeHolderMessage : message}
											</Typography>
										</TxtLimit>
									</Box>
									<Box>
										<Image
											m="0.5rem 0 0 0.75rem"
											borderRadius="1rem"
											width="30px"
											height="30px"
											src={messageImage}
											objectFit="cover"
										/>
									</Box>
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>
			) : (
				<Box m="1rem" display="flex" flexDirection="column">
					<Box
						borderRadius="0.5rem"
						margin="1rem 0rem"
						maxWidth="100%"
						display="flex"
						flexDirection="column"
						alignItems="center"
						padding="1.5rem"
						boxShadow={3}
						bgcolor={muiTheme.palette.grey[50]}
					>
						<Box
							width="100%"
							height="5rem"
							display="flex"
							justifyContent="space-between"
							alignItems="center"
						>
							<Box display="flex" flexDirection="row" alignItems="center" flex="3">
								{appImage ? (
									<Image m="0 1rem 0 0" width="30px" height="30px" src={appImage} />
								) : (
									<Box display="flex" m="0 0.25rem 0 0" alignItems="center" justifyContent="center">
										<ImageIcon />
									</Box>
								)}
								<Typography fontSize="14px">{`${appName} • ${minutesElapsed}m`}</Typography>
							</Box>
							<IconButton onClick={handleToggle} size="small">
								{collapsed ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
							</IconButton>
						</Box>
						<Box width="100%" display="flex" alignItems="center">
							<Box width="100%">
								<Typography fontSize="14px">
									{!messageTitle && showPlaceHolderText ? placeHolderMessageTitle : messageTitle}
								</Typography>
								<TxtLimit numberOfLines={1}>
									<Typography fontSize="14px" color={muiTheme.palette.grey[600]}>
										{!message && showPlaceHolderText ? placeHolderMessage : message}
									</Typography>
								</TxtLimit>
							</Box>
							{collapsed ? (
								<Box>
									<Image
										m="0 0 0 1rem"
										width="45px"
										height="45px"
										src={messageImage}
										objectFit="cover"
									/>
								</Box>
							) : null}
						</Box>
						{collapsed ? null : (
							<Image
								m="0 0 0 1rem"
								width="100%"
								height="180px"
								src={messageImage}
								objectFit="cover"
							/>
						)}
					</Box>
				</Box>
			),
		[
			messageImage,
			messageTitle,
			showPlaceHolderText,
			placeHolderMessageTitle,
			muiTheme.palette.grey,
			minutesElapsed,
			message,
			placeHolderMessage,
			appImage,
			appName,
			handleToggle,
			collapsed,
		]
	);

	return (
		<Box m={margin}>
			<DeviceFrame
				margin="4rem 0 0 2rem"
				defaultOs={defaultOs}
				renderContent={renderContent}
				calculateHeightFactor={calculateHeightFactor}
				onSendTestNotification={onSendTestNotification}
				sendTestNotificationLoading={sendTestNotificationLoading}
			/>
		</Box>
	);
};

export default NotificationPreviewer;
