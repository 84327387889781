import * as React from 'react';
import { ID } from '@cinuru/utils/types';
import { TextFieldProps } from '@mui/material/TextField';

import SearchSelectField, { SelectFieldItem } from './SearchSelectField';
import TextField from './TextField';
import { useAdminCinemas } from '../utils/cinema';

export type CinemaSelectFieldNewRef = {
	validate: () => boolean;
};

const CinemaSelectFieldNew2 = React.forwardRef<
	CinemaSelectFieldNewRef,
	{
		label: string;
		onChange?: (movies: { id: ID; name: string }[]) => void;
		multi?: boolean;
		variant?: TextFieldProps['variant'];
		defaultIds?: ID[] | null;
		defaultMovieIds?: ID[];
		m?: string;
		disabled?: boolean;
		width?: string;
	}
>(
	(
		{ label, onChange, multi, variant = 'outlined', defaultIds, m, disabled, width },
		ref
	): JSX.Element => {
		const adminCinemas = useAdminCinemas();

		const handleChange = React.useCallback(
			(items: SelectFieldItem[]) => {
				const transformed = items.map((item) => ({ id: item.value as ID, name: item.label }));
				onChange?.(transformed);
			},
			[onChange]
		);

		const transformed = React.useMemo(
			() => adminCinemas?.map((cinema) => ({ label: cinema.name, value: cinema.id as string })),
			[adminCinemas]
		);

		const defaultItems = React.useMemo(() => {
			return adminCinemas
				?.filter(({ id }) => defaultIds?.includes(id))
				.map(({ id, name }) => ({ label: name, value: id as string }));
		}, [adminCinemas, defaultIds]);

		return !adminCinemas ? (
			<TextField label={label} variant={variant} m={m} isLoading />
		) : (
			<SearchSelectField
				allItems={transformed}
				label={label}
				onChange={handleChange}
				multi={multi}
				variant={variant}
				defaultItems={defaultItems}
				m={m}
				ref={ref}
				disabled={disabled}
				width={width}
			/>
		);
	}
);

export default CinemaSelectFieldNew2;
