import React from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';
import { Brand } from '@cinuru/utils/theme';
import { ImageProps } from '@cinuru/emails/src/components/DashboardEmailComponents/Image';

import {
	stripValueFromDimensionObject,
	transformElement,
} from '../utils/emailConfigurationHelpers';
import ApiRenderedComponent from './ApiRenderedComponent';
import { ImagePicker } from '../ImagePicker';
import { ColorController, CheckBoxController } from '../utils/allFormControlComponents';
import { ImageData } from '../../../utils/campaignEmailContent';
import SizeController from './FormControls/SizeController';
import TextController from './FormControls/TextController';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

const ImagePreview = ({ id, element, brand }: { id: string; element: ImageData; brand: Brand }) => {
	const componentProps: ImageProps = React.useMemo(
		() => stripValueFromDimensionObject(element) as ImageProps,
		[element]
	);
	return (
		<ApiRenderedComponent
			id={id}
			brand={brand}
			componentName="Image"
			componentProps={componentProps}
		/>
	);
};

export const ImageEditor = React.memo(
	({
		element,
		updateElement,
		brand,
		spacerHeight,
	}: {
		element: ImageData;
		updateElement: (imageData) => void;
		brand: Brand;
		spacerHeight?: string;
	}) => {
		const handleChangeController = React.useCallback(
			(path: string, newValue: any) => {
				const pathArray = path.split('/');
				const newElement = transformElement(element, pathArray, newValue);
				updateElement(newElement as ImageData);
			},
			[element, updateElement]
		);

		return (
			<Wrapper>
				<Wrapper className="wrapper">
					<ImagePicker
						imageUrl={element.imageUrl}
						path="imageUrl"
						onChange={handleChangeController}
					/>
					<Box height={spacerHeight} />
					<SizeController
						type="WIDTH"
						value={element.width}
						onChange={handleChangeController}
						path="width"
					/>
					<Box height={spacerHeight} />
					<SizeController
						type="BORDER_RADIUS"
						value={element.borderRadius}
						onChange={handleChangeController}
						path="borderRadius"
					/>
					<Box height={spacerHeight} />
					<CheckBoxController
						type="SHADOW"
						value={element.shadow}
						onChange={handleChangeController}
						path="shadow"
					/>
					<Box height={spacerHeight} />
					<CheckBoxController
						type="FULL_WIDTH"
						value={element.fullWidth}
						onChange={handleChangeController}
						path="fullWidth"
					/>
					<Box height={spacerHeight} />
					<TextController
						type="ALT"
						value={element.altText}
						onChange={handleChangeController}
						path="altText"
						toolTip="Wird innerhalb der Mail nicht angzeigt, hilft Endgeräten jedoch, den Inhalt der Bildes barrierefrei wiederzugeben (z.B. Sprachausgabe für Nutzer mit Sehschwäche)."
					/>
					<Box height={spacerHeight} />
					<ColorController
						value={element.backgroundColor}
						brand={brand}
						onChange={handleChangeController}
						path="backgroundColor"
						label="Hintergrundfarbe"
						fallBackColor="background0"
					/>
				</Wrapper>
			</Wrapper>
		);
	}
);

export const ImageDefaultParams: ImageData = {
	type: 'IMAGE',
	imageUrl: 'https://static.cinuru.com/public/dashboard/placeholder.jpg',
	width: '300px',
	index: 0,
	shadow: true,
	borderRadiusWrapper: { top: true, bottom: true },
};

export default React.memo(ImagePreview);
