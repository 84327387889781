import React from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';
import { Brand } from '@cinuru/utils/theme';
import { QRCodeProps } from '@cinuru/emails/src/components/DashboardEmailComponents/QRCode';

import ApiRenderedComponent from './ApiRenderedComponent';
import { ColorController } from '../utils/allFormControlComponents';

import {
	stripValueFromDimensionObject,
	transformElement,
} from '../utils/emailConfigurationHelpers';
import { QRCodeData } from '../../../utils/campaignEmailContent';
import SelectController from './FormControls/SelectController';

import SizeController from './FormControls/SizeController';
import CheckBoxController from './FormControls/CheckBoxController';
import TextController from './FormControls/TextController';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

const QRCodePreview = ({
	id,
	element,
	brand,
}: {
	id: string;
	element: QRCodeData;
	brand: Brand;
}) => {
	const componentProps: QRCodeProps = React.useMemo(() => stripValueFromDimensionObject(element), [
		element,
	]);

	return (
		<ApiRenderedComponent
			id={id}
			brand={brand}
			componentName="QRCode"
			componentProps={componentProps}
		/>
	);
};

export const QRCodeEditor = React.memo(
	({
		element,
		updateElement,
		brand,
		spacerHeight,
	}: {
		element: QRCodeData;
		updateElement: (qrCodeData) => void;
		brand: Brand;
		spacerHeight?: string;
	}) => {
		const handleChangeController = React.useCallback(
			(path: string, newValue: any) => {
				const pathArray = path.split('/');
				const newElement = transformElement(element, pathArray, newValue);
				updateElement(newElement as QRCodeData);
			},
			[element, updateElement]
		);

		return (
			<Wrapper>
				<Wrapper className="wrapper">
					<TextController
						type="LINK"
						value={element.src}
						onChange={handleChangeController}
						path="src"
					/>
					<Box height={spacerHeight} />
					<SizeController
						type="WIDTH"
						value={element.qrCodeSize?.toString()}
						onChange={handleChangeController}
						path="qrCodeSize"
					/>
					<Box height={spacerHeight} />
					<CheckBoxController
						type="SHADOW"
						value={element.shadow}
						onChange={handleChangeController}
						path="shadow"
					/>
					<Box height={spacerHeight} />
					<SelectController
						type="FONT_SIZE"
						value={element.size}
						onChange={handleChangeController}
						path="size"
					/>
					<Box height={spacerHeight} />
					<CheckBoxController
						type="BOLDNESS"
						value={element.bold}
						onChange={handleChangeController}
						path="bold"
					/>
					<Box height={spacerHeight} />
					<ColorController
						value={element.color}
						brand={brand}
						onChange={handleChangeController}
						path="color"
						label="Schriftfarbe"
						fallBackColor="neutral0"
					/>
					<Box height={spacerHeight} />
					<ColorController
						value={element.backgroundColor}
						brand={brand}
						onChange={handleChangeController}
						path="backgroundColor"
						label="Hintergrundfarbe"
						fallBackColor="background0"
					/>
				</Wrapper>
			</Wrapper>
		);
	}
);

export const QRCodeDefaultParams: QRCodeData = {
	type: 'QRCODE',
	src: '12345678',
	index: 0,
	qrCodeSize: 300,
	size: 'xl',
	borderRadiusWrapper: { top: true, bottom: true },
	shadow: true,
};

export default React.memo(QRCodePreview);
