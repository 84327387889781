import * as React from 'react';

import FreeIntervalSelectForm from '../../components/FreeIntervalSelectForm';
import type { FreeIntervalSelectFormRef } from '../../components/FreeIntervalSelectForm';
import DatetimeSelectForm from '../../components/DatetimeSelectForm';
import type { DatetimeSelectFormRef } from '../../components/DatetimeSelectForm';
import DatetimeRangeSelectForm from '../../components/DatetimeRangeSelectForm';
import type { DatetimeRangeSelectFormRef } from '../../components/DateTimeRangeSelectForm';
import TimePeriodEventSelectForm from '../../components/TimePeriodEventSelectForm';
import type { TimePeriodEventSelectFormRef } from '../../components/TimePeriodEventSelectForm';

import type { TimeUnit } from '../../components/TimeUnitSelectField';
import type { EventType } from '../../components/TimePeriodEventSelectForm';
import type { EventType as IntervalEventType } from '../../components/FreeIntervalSelectForm';
import { Trigger } from './CampaignTriggerSelector';

const useCreateHandleUpdateFunction = (
	key: string,
	handleUpdateFunction: (key: string, value?: string | number) => void
) => {
	return React.useCallback(
		(newValue?: string | number) => {
			handleUpdateFunction(key, newValue);
		},
		[handleUpdateFunction, key]
	);
};

export type CampaignWhenSelectorRef = {
	validate: () => boolean;
};

const CampaignWhenSelector = React.forwardRef<
	CampaignWhenSelectorRef,
	{
		trigger: Trigger;
		triggerLabel?: string;
		onChange: (campaignKey: string, value?: string | number | null) => void;
		defaultDate?: string;
		defaultTime?: string;
		defaultStartDate?: string;
		defaultEndDate?: string;
		defaultStartTime?: string;
		defaultEndTime?: string;
		defaultEventType?: EventType;
		defaultTimeUnit?: TimeUnit;
		defaultTimeUnitFactor?: number;
		defaultNumberOfIntervals?: number;
		defaultIntervalEndType?: IntervalEventType;
		disabled?: boolean;
		canSendBeforeTrigger?: boolean;
	}
>(
	(
		{
			trigger,
			triggerLabel,
			onChange,
			defaultDate,
			defaultTime,
			defaultStartDate,
			defaultEndDate,
			defaultStartTime,
			defaultEndTime,
			defaultEventType,
			defaultTimeUnit,
			defaultTimeUnitFactor,
			defaultNumberOfIntervals,
			defaultIntervalEndType,
			disabled,
			canSendBeforeTrigger,
		},
		ref
	): JSX.Element | null => {
		const recentValuesRef = React.useRef({
			sendingDate: defaultDate,
			sendingTime: defaultTime,
			startDate: defaultStartDate,
			endDate: defaultEndDate,
			startTime: defaultStartTime,
			endTime: defaultEndTime,
			numberOfIntervals: defaultNumberOfIntervals,
			intervalEndType: defaultIntervalEndType,
			eventType: defaultEventType,
			timeUnit: defaultTimeUnit,
			timeUnitFactor: defaultTimeUnitFactor,
		});

		const handleUpdate = React.useCallback(
			(key: string, value?: string | number) => {
				recentValuesRef.current = { ...recentValuesRef.current, [key]: value };
				onChange?.(key, value);
			},
			[onChange]
		);

		const handleChangeDate = useCreateHandleUpdateFunction('sendingDate', handleUpdate);
		const handleChangeTime = useCreateHandleUpdateFunction('sendingTime', handleUpdate);
		const handleChangeStartDate = useCreateHandleUpdateFunction('startDate', handleUpdate);
		const handleChangeEndDate = useCreateHandleUpdateFunction('endDate', handleUpdate);
		const handleChangeStartTime = useCreateHandleUpdateFunction('startTime', handleUpdate);
		const handleChangeEndTime = useCreateHandleUpdateFunction('endTime', handleUpdate);
		const handleChangeNumberOfIntervals = useCreateHandleUpdateFunction(
			'numberOfIntervals',
			handleUpdate
		);
		const handleChangeIntervalEndType = useCreateHandleUpdateFunction(
			'intervalEndType',
			handleUpdate
		);
		const handleChangeEventType = useCreateHandleUpdateFunction('eventType', handleUpdate);
		const handleChangeTimeUnit = useCreateHandleUpdateFunction('timeUnit', handleUpdate);
		const handleChangeTimeUnitFactor = useCreateHandleUpdateFunction(
			'timeUnitFactor',
			handleUpdate
		);

		const dateTimeSelectFormRef = React.useRef<DatetimeSelectFormRef>(null);
		const freeIntervalSelectFormRef = React.useRef<FreeIntervalSelectFormRef>(null);
		const dateTimeRangeSelectFormRef = React.useRef<DatetimeRangeSelectFormRef>(null);
		const timePeriodEventSelectFormRef = React.useRef<TimePeriodEventSelectFormRef>(null);

		const handleValidate = React.useCallback(() => {
			const errors = [
				dateTimeSelectFormRef,
				freeIntervalSelectFormRef,
				dateTimeRangeSelectFormRef,
				timePeriodEventSelectFormRef,
			].map((r) => r.current?.validate());
			const invalid = errors.some(Boolean);
			return invalid;
		}, []);

		React.useImperativeHandle(
			ref,
			() => ({
				validate: handleValidate,
			}),
			[handleValidate]
		);

		return trigger === 'SINGLE_EVENT' ? (
			<DatetimeSelectForm
				label="Sendezeitpunkt"
				variant="outlined"
				defaultDate={recentValuesRef.current.sendingDate}
				onChangeDate={handleChangeDate}
				defaultTime={recentValuesRef.current.sendingTime}
				onChangeTime={handleChangeTime}
				ref={dateTimeSelectFormRef}
				disabled={disabled}
			/>
		) : trigger === 'REGULAR' ? (
			<FreeIntervalSelectForm
				label="Wiederholung"
				variant="outlined"
				defaultStartDate={recentValuesRef.current.startDate}
				onChangeStartDate={handleChangeStartDate}
				defaultEndDate={recentValuesRef.current.endDate}
				onChangeEndDate={handleChangeEndDate}
				defaultStartTime={recentValuesRef.current.startTime}
				onChangeStartTime={handleChangeStartTime}
				defaultIntervalTimeUnit={recentValuesRef.current.timeUnit}
				onChangeIntervalTimeUnit={handleChangeTimeUnit}
				defaultIntervalFactor={recentValuesRef.current.timeUnitFactor}
				onChangeIntervalFactor={handleChangeTimeUnitFactor}
				defaultNumberOfIntervals={recentValuesRef.current.numberOfIntervals}
				onChangeNumberOfIntervals={handleChangeNumberOfIntervals}
				defaultIntervalEndType={recentValuesRef.current.intervalEndType}
				onChangeIntervalEndType={handleChangeIntervalEndType}
				ref={freeIntervalSelectFormRef}
				disabled={disabled}
			/>
		) : (
			<>
				{trigger === 'NO_VISIT' ? null : (
					<TimePeriodEventSelectForm
						label="Sendezeitpunkt"
						eventLabel={triggerLabel}
						variant="outlined"
						defaultEventType={recentValuesRef.current.eventType}
						onChangeEventType={handleChangeEventType}
						defaultTimeUnit={recentValuesRef.current.timeUnit}
						onChangeTimeUnit={handleChangeTimeUnit}
						defaultTimeUnitFactor={recentValuesRef.current.timeUnitFactor}
						onChangeTimeUnitFactor={handleChangeTimeUnitFactor}
						margin="2rem 0 0"
						ref={timePeriodEventSelectFormRef}
						disabled={disabled}
						canSendBeforeTrigger={canSendBeforeTrigger}
					/>
				)}
				<DatetimeRangeSelectForm
					label="Zeitraum in dem die Kampagne läuft"
					variant="outlined"
					defaultStartDate={recentValuesRef.current.startDate}
					onChangeStartDate={handleChangeStartDate}
					defaultEndDate={recentValuesRef.current.endDate}
					onChangeEndDate={handleChangeEndDate}
					defaultStartTime={recentValuesRef.current.startTime}
					onChangeStartTime={handleChangeStartTime}
					defaultEndTime={recentValuesRef.current.endTime}
					onChangeEndTime={handleChangeEndTime}
					ref={dateTimeRangeSelectFormRef}
					disabled={disabled}
				/>
			</>
		);
	}
);

export default CampaignWhenSelector;
