import * as React from 'react';

import ActiveWrapper from './ActiveWrapper';
import { ClusterPropName, ClusterProps } from '../TargetGroupCluster';
import TimePeriodSelectForm, { TimeUnit } from '../../../components/TimePeriodSelectForm';

const enabledTimeUnits: ('WEEK' | 'MONTH' | 'YEAR')[] = ['WEEK', 'MONTH', 'YEAR'];

export type TimePeriodFilteRef = {
	validate: () => boolean;
	scrollIntoView: () => void;
};

const TimePeriodFilter = React.forwardRef<
	TimePeriodFilteRef,
	{
		label: string;
		active?: boolean;
		defaultAmount?: number;
		defaultTimePeriod: TimeUnit;
		onChange: ({ clusterPropName, clusterPropValue }: ClusterProps) => void;
		showFilter: boolean;
		clusterPropName: ClusterPropName;
		disabled?: boolean;
	}
>(
	(
		{
			label,
			active,
			defaultAmount,
			defaultTimePeriod,
			onChange,
			showFilter,
			clusterPropName,
			disabled,
		},
		ref
	): JSX.Element | null => {
		const handleChangeTimeUnitFactor = React.useCallback(
			(timeUnitFactor?: number) => {
				onChange({
					clusterPropName,
					clusterPropValue: {
						amount: timeUnitFactor,
					},
				});
			},
			[clusterPropName, onChange]
		);

		const handleChangeTimeUnit = React.useCallback(
			(timeUnit?: TimeUnit) => {
				onChange({
					clusterPropName,
					clusterPropValue: {
						timePeriod: timeUnit,
					},
				});
			},
			[clusterPropName, onChange]
		);

		const handleToggleActivity = React.useCallback(() => {
			onChange({
				clusterPropName,
				clusterPropValue: {
					active: !active,
				},
			});
		}, [onChange, clusterPropName, active]);

		return showFilter ? (
			<ActiveWrapper
				label={label}
				active={active}
				onHandleToggleActivity={handleToggleActivity}
				disabled={disabled}
			>
				<TimePeriodSelectForm
					width="100%"
					disabled={disabled || !active}
					defaultTimeUnit={defaultTimePeriod}
					defaultTimeUnitFactor={defaultAmount}
					onChangeTimeUnit={handleChangeTimeUnit}
					onChangeTimeUnitFactor={handleChangeTimeUnitFactor}
					enabledTimeUnits={enabledTimeUnits}
					ref={ref}
				/>
			</ActiveWrapper>
		) : null;
	}
);
export default React.memo(TimePeriodFilter);
